/* eslint-disable */
export const PAYMENT_LANGUAGE_DATA = {
  "paywayLanguageData": {
    "1800": {
      "nameOnList": "Płatność z mBank",
      "buttonTitle": "Akceptuję i rozpoczynam płatność"
    },
    "1801": {
      "nameOnList": "Płatność z VeloBank",
      "buttonTitle": "Akceptuję i rozpoczynam płatność"
    },
    "9": {
      "nameOnList": "Przelew z innego banku",
      "buttonTitle": "Wygeneruj dane do przelewu"
    },
    "1802": {
      "nameOnList": "Płatność z Alior",
      "buttonTitle": "Akceptuję i rozpoczynam płatność"
    },
    "1803": {
      "nameOnList": "Płatność z PKO BP",
      "buttonTitle": "Akceptuję i rozpoczynam płatność"
    },
    "1804": {
      "nameOnList": "Płatność z BNP Paribas",
      "buttonTitle": "Akceptuję i rozpoczynam płatność"
    },
    "1805": {
      "nameOnList": "Płatność z PeKao S.A.",
      "buttonTitle": "Akceptuję i rozpoczynam płatność"
    },
    "1806": {
      "nameOnList": "Płatność z Santander",
      "buttonTitle": "Akceptuję i rozpoczynam płatność"
    },
    "1807": {
      "nameOnList": "Płatność z Millenium",
      "buttonTitle": "Akceptuję i rozpoczynam płatność"
    },
    "1808": {
      "nameOnList": "Płatność z ING",
      "buttonTitle": "Akceptuję i rozpoczynam płatność"
    },
    "1809": {
      "nameOnList": "Płatność z CA",
      "buttonTitle": "Akceptuję i rozpoczynam płatność"
    },
    "1810": {
      "nameOnList": "Płatność z Inteligo",
      "buttonTitle": "Akceptuję i rozpoczynam płatność"
    },
    "1811": {
      "nameOnList": "Płatność z Pocztowy",
      "buttonTitle": "Akceptuję i rozpoczynam płatność"
    },
    "21": {
      "nameOnList": "Przelew Volkswagen Bank",
      "buttonTitle": "Wygeneruj dane do przelewu"
    },
    "1813": {
      "nameOnList": "Płać z BOŚ Bank",
      "buttonTitle": "Akceptuję i rozpoczynam płatność"
    },
    "1814": {
      "nameOnList": "Płać z Nest Bank",
      "buttonTitle": "Akceptuję i rozpoczynam płatność"
    },
    "11800": {
      "nameOnList": "mBank PSD2 - testy automatyczne"
    },
    "11801": {
      "nameOnList": "Płatność z GetIn PSD2 - testy au"
    },
    "11802": {
      "nameOnList": "Płatność z Alior PSD2 - testy au"
    },
    "11803": {
      "nameOnList": "PKOBP PSD2 - testy automatyczne"
    },
    "11804": {
      "nameOnList": "BNP Paribas PSD2 - testy automat"
    },
    "1500": {
      "nameOnList": "PBC płatność testowa",
      "buttonTitle": "Płacę",
      "promotionLanguageData": {
        "header": "Płatność kartą"
      }
    },
    "11805": {
      "nameOnList": "PEKAOSA PSD2 - testy automatyczn"
    },
    "11806": {
      "nameOnList": "Santander PSD2 - testy automatyc"
    },
    "11807": {
      "nameOnList": "Millennium  PSD2 - testy automat"
    },
    "1503": {
      "nameOnList": "PBC płatność automatyczna",
      "buttonTitle": "Zleć płatność"
    },
    "11808": {
      "nameOnList": "ING PSD2 - testy automatyczne"
    },
    "1505": {
      "nameOnList": "MasterPass",
      "buttonTitle": "Płacę"
    },
    "11809": {
      "nameOnList": "Credit Agricole PSD2 - testy aut"
    },
    "11810": {
      "nameOnList": "Inteligo PSD2 - testy automatycz"
    },
    "35": {
      "nameOnList": "Spółdzielcza Grupa Bankowa",
      "buttonTitle": "Wygeneruj dane do przelewu"
    },
    "11811": {
      "nameOnList": "Pocztowy PSD2 - testy automatycz"
    },
    "5091": {
      "nameOnList": "BLIK",
      "buttonTitle": "Płacę"
    },
    "1060": {
      "nameOnList": "Banki Spółdzielcze",
      "buttonTitle": "Płacę"
    },
    "11813": {
      "nameOnList": "BOŚ PSD2 - testy automatyczne"
    },
    "11814": {
      "nameOnList": "Nest Bank PSD2 - testy automatyc"
    },
    "1511": {
      "nameOnList": "Visa",
      "buttonTitle": "Płacę"
    },
    "1512": {
      "nameOnList": "Google Pay",
      "buttonTitle": "Płacę"
    },
    "1513": {
      "nameOnList": "Apple Pay",
      "buttonTitle": "Płacę"
    },
    "106": {
      "nameOnList": "PG płatność testowa",
      "buttonTitle": "Płacę"
    }
  }
}
export const PAYMENT_START = {
  "status": "SUCCESS",
  "data": {
    "paywayGroups": [
      {
        "active": true,
        "type": "PROMO",
        "order": 1,
        "payways": [
          {
            "id": 1500,
            "type": "CARD",
            "nameOnList": "PBC płatność testowa",
            "order": 3,
            "iconUrl": "https://testimages.autopay.eu/pomoc/grafika/1500.png",
            "recurring": false,
            "active": true,
            "buttonTitle": "Płacę",
            "requiredParams": [],
            "redirectToCards": false
          }
        ],
        "promotionLanguageData": {
          "header": "Płatność kartą"
        },
        "promoType": "MASTERCARD"
      },
      {
        "active": true,
        "type": "VISAMOBILE",
        "order": 2,
        "payways": [
          {
            "id": 1523,
            "type": "VISAMOBILE",
            "nameOnList": "Visa Mobile",
            "iconUrl": "https://testimages.autopay.eu/pomoc/grafika/1523.png",
            "recurring": false,
            "active": true,
            "buttonTitle": "Zapłać przez",
            "requiredParams": [],
            "redirectToCards": false
          }
        ]
      },
      {
        "active": true,
        "type": "PBL",
        "order": 3,
        "payways": [
          {
            "id": 106,
            "type": "PBL",
            "nameOnList": "PG płatność testowa",
            "order": 9,
            "iconUrl": "https://testimages.autopay.eu/pomoc/grafika/106.gif",
            "recurring": false,
            "active": true,
            "buttonTitle": "Płacę",
            "requiredParams": [],
            "redirectToCards": false
          }
        ]
      },
      {
        "active": true,
        "type": "WALLET",
        "order": 4,
        "payways": [
          {
            "id": 1513,
            "type": "WALLET",
            "nameOnList": "Apple Pay",
            "order": 4,
            "iconUrl": "https://testimages.autopay.eu/pomoc/grafika/1513.png",
            "recurring": false,
            "active": true,
            "buttonTitle": "Płacę",
            "requiredParams": [],
            "redirectToCards": false
          },
          {
            "id": 1512,
            "type": "WALLET",
            "nameOnList": "Google Pay",
            "order": 5,
            "iconUrl": "https://testimages.autopay.eu/pomoc/grafika/1512.png",
            "recurring": false,
            "active": true,
            "buttonTitle": "Płacę",
            "requiredParams": [],
            "redirectToCards": false
          }
        ]
      },
      {
        "active": true,
        "type": "FR",
        "order": 5,
        "payways": [
          {
            "id": 35,
            "type": "FR",
            "nameOnList": "Spółdzielcza Grupa Bankowa",
            "order": 92,
            "iconUrl": "https://testimages.autopay.eu/pomoc/grafika/35.gif",
            "recurring": false,
            "active": true,
            "buttonTitle": "Wygeneruj dane do przelewu",
            "requiredParams": [],
            "redirectToCards": false
          },
          {
            "id": 9,
            "type": "FR",
            "nameOnList": "Przelew z innego banku",
            "order": 93,
            "iconUrl": "https://testimages.autopay.eu/pomoc/grafika/9.gif",
            "recurring": false,
            "active": true,
            "buttonTitle": "Wygeneruj dane do przelewu",
            "requiredParams": [],
            "redirectToCards": false
          },
          {
            "id": 21,
            "type": "FR",
            "nameOnList": "Przelew Volkswagen Bank",
            "order": 80,
            "iconUrl": "https://testimages.autopay.eu/pomoc/grafika/21.gif",
            "recurring": false,
            "active": true,
            "buttonTitle": "Wygeneruj dane do przelewu",
            "requiredParams": [],
            "redirectToCards": false
          }
        ]
      },
      {
        "active": true,
        "type": "BNPL_BLIK",
        "order": 6,
        "payways": [
          {
            "id": 523,
            "name": "BLIK Płacę Później",
            "type": "PBL",
            "nameOnList": "BLIK Płacę Później",
            "order": 8,
            "iconUrl": "https://images.autopay.eu/pomoc/grafika/523.gif",
            "recurring": false,
            "active": true,
            "languageData": [],
            "redirectToCards": false
          }
        ]
      },
    ],
    "transRemoteID": "AXR198SMDJ",
    "payAmount": 1.00,
    "payAmountCurrency": "PLN",
    "customerFee": 0.00,
    "transactionValidityTime": "2025-01-19T10:57:28",
    "additionalData": {
      "orderId": "20250116105728",
      "customerEmail": "mac.webcoder@gmail.com"
    },
    "acceptorID": 902789,
    "serviceID": 904190,
    "serviceType": "ECOMMERCE",
    "shortName": "CardsIT Fenige",
    "receiverNameForFront": "CardsIT Fenige",
    "serviceUrl": "https://devnull.bm.pl",
    "returnUrl": "https://devnull.bm.pl?ServiceID=904190&OrderID=20250116105728&Hash=0542fb0ea5840c88f23cbc174cbda29fbdf8c45165b9f8603d591c3daf24d99a",
    "regulations": [],
    "clientSource": "payways",
    "languageData": {
      "languagesAvailable": [
        "PL",
        "EN",
        "DE"
      ],
      "languageChosen": "PL"
    },
    "visibilityOptions": {
      "showFrontEmail": true,
      "showFrontFees": false,
      "showReturnToPaywaysButton": false,
      "showReturnToShopButton": true,
      "showFrontBasket": false,
      "expandFrontTransactionDetails": false
    },
    "sessionRemainingTime": 3599,
    "marketingAgreements": [
      {
        "language": "pl",
        "code": "zgoda_partnerzy",
        "content": "Wyrażam zgodę na udostępnienie wyników profilowania opartego na moich danych osobowych zgromadzonych w trakcie wykonywania transakcji płatniczych, zaufanym partnerom Autopay S.A. w celu prezentacji przygotowanych dla mnie reklam ich produktów i usług, wyświetlanych w trakcie kolejnych zakupów oraz płatności, a także ewentualnych zniżek na zakup poszczególnych produktów. Decyzje o rodzaju reklam podejmowane będą w sposób zautomatyzowany, na podstawie moich wcześniejszych zakupów.",
        "name": "Wyrażam zgodę na udostępnienie wyników profilowania opartego na moich danych osobowych zgromadzonych w trakcie wykonywania transakcji płatniczych, zaufanym partnerom Autopay S.A.",
        "category": "MARKETING",
        "required": false
      },
      {
        "language": "pl",
        "code": "AP_1",
        "content": "Wyrażam zgodę na otrzymywanie spersonalizowanych reklam dotyczących produktów i usług Autopay S.A. oraz jej zaufanych partnerów, za pośrednictwem e-maila i powiadomień w aplikacji oraz wiadomości SMS.",
        "name": "Wyrażam zgodę na otrzymywanie spersonalizowanych reklam dotyczących produktów i usług Autopay S.A. oraz jej zaufanych partnerów.",
        "category": "MARKETING",
        "required": false
      },
      {
        "language": "pl",
        "code": "AP_2",
        "content": "Wyrażam zgodę na profilowanie moich danych pochodzących z transakcji płatniczych wykonanych za pośrednictwem bramki Autopay S.A. oraz podejmowanie na tej podstawie zautomatyzowanych decyzji. Profilowanie to polegać będzie na ocenie zrobionych zakupów - ich rodzaju, wartości, sprzedawcy i sposobu płatności, a także innych czynników uwzględnianych w trakcie płatności. Decyzje te będą dotyczyły indywidualnie dobieranych reklam produktów i usług Autopay S.A. wyświetlanych w trakcie dokonywania kolejnych zakupów oraz płatności.",
        "name": "Wyrażam zgodę na profilowanie moich danych pochodzących z transakcji płatniczych wykonanych za pośrednictwem bramki Autopay S.A.",
        "category": "MARKETING",
        "required": false
      }
    ],
    "click2PayData": {
      "srcInitiatorId": "f2389007-b4c6-4cef-bbf4-c47ef5f729c7",
      "srciDpaId": "eef3c43a-5dd0-4bdf-bbd8-2b8c4fb63fdd",
      "srciTransactionId": "2fe91d0a-1883-3166-b789-3bbded3dc282",
      "mastercard": {
        "srcInitiatorId": "f2389007-b4c6-4cef-bbf4-c47ef5f729c7",
        "srciDpaId": "eef3c43a-5dd0-4bdf-bbd8-2b8c4fb63fdd"
      },
      "visa": {
        "srcInitiatorId": "IG4E1PSBYJITUJXSPBDO21s7SZi2EMmlqPN8HxxjTuMHHP5LM",
        "srciDpaId": "eef3c43a-5dd0-4bdf-bbd8-2b8c4fb63fdd"
      }
    }
  }
}
export const BANERS = {
  "data": [
    {
      "imgSrc": "https://cdn.bsbox.pl/files/bm-adserver/Mjc7MDA_/baner1x1.jpg",
      "creationId": 105,
      "placementId": "100000",
      "targetUrl": null,
      "targetDevice": "desktop",
      "extraContext": "PHNjcmlwdCBkYXRhLWFkZnNjcmlwdD0iYWR4LmFkZm9ybS5uZXQvYWR4Lz9taWQ9MTk2Mzg0MSI+PC9zY3JpcHQ+DQo8c2NyaXB0IHNyYz0iLy9zMS5hZGZvcm0ubmV0L2Jhbm5lcnMvc2NyaXB0cy9hZHguanMiIGFzeW5jIGRlZmVyPjwvc2NyaXB0Pg==",
      "extraHeadContext": "PHNjcmlwdCBkYXRhLWFkZnNjcmlwdD0iYWR4LmFkZm9ybS5uZXQvYWR4Lz9taWQ9MTk2Mzg0MSI+PC9zY3JpcHQ+DQo8c2NyaXB0IHNyYz0iLy9zMS5hZGZvcm0ubmV0L2Jhbm5lcnMvc2NyaXB0cy9hZHguanMiIGFzeW5jIGRlZmVyPjwvc2NyaXB0Pg==",
      "title": null,
      "alt": null
    },
    {
      "imgSrc": "https://cdn.bsbox.pl/files/bm-adserver/MWQ7MDA_/300x200_sprzedaz_80_zdrofit_.jpg",
      "creationId": 116,
      "placementId": "100002",
      "targetUrl": "https://ads.autopay.pl/ads/redirect/?creation_id=116&service_id=104511",
      "targetDevice": "mobile",
      "extraContext": null,
      "extraHeadContext": null,
      "title": null,
      "alt": "Zdrofit"
    }
  ]
}
export const PAYMENT_START_429 = {
  "status": "FAILURE",
  "errorCode": "RATE_LIMIT_EXCEEDED",
  "errorMessage": "Tu jakiś opis..."
}
export const PAYMENT_CONTINUE_429 = {
  "status": "FAILURE",
  "errorCode": "RATE_LIMIT_EXCEEDED",
  "errorMessage": "Tu jakiś opis..."
}
export const PAYMENT_START_RECURRING = {
  "status": "SUCCESS",
  "startResponseData": {
    "containsPisPayway": true,
    "chosenPayway": {
      "id": 1898,
      "name": "PG płatność testowa PIS (AUTO)",
      "type": "PBL",
      "nameOnList": "PG płatność testowa PIS",
      "iconUrl": "https://platnosci-accept.bm.pl/pomoc/grafika/1898.png",
      "recurring": false,
      "active": true,
      "typeForPsd2": "PIS",
      "languageData": [],
      "redirectToCards": false
    },
    "transRemoteID": "AYSF5WINGF",
    "startAmount": 1.00,
    "startAmountCurrency": "PLN",
    "payAmount": 2.00,
    "payAmountCurrency": "PLN",
    "customerFee": 1.00,
    "transactionValidityTime": "2023-09-30T11:08:00",
    "additionalData": {
      "orderId": "20230927110800",
      "customerEmail": "devnull@bm.pl"
    },
    "acceptorID": 902558,
    "serviceID": 903838,
    "serviceType": "PUBLIC",
    "shortName": "Test podmiany kanałów",
    "receiverNameForFront": "Test podmiany kanałów",
    "serviceUrl": "https://devnull.bm.pl",
    "returnUrl": "https://devnull.bm.pl",
    "regulations": [
      {
        "regulationId": 13411,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=903838&MessageID=e0e25f0564b032381d2408b20761b957&Type=DEFAULT&Language=PL&Hash=1c66486b7958ea2cd4bc8ef45663b39a347954ac1d198b2689e523ee96df73f8",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>\r\n                Zapoznałem się i akceptuję <a id=\"regulations_pdf\" target=\"_blank\" href=https://testpay.autopay.eu/webapi/regulation?ServiceID=903838&MessageID=e0e25f0564b032381d2408b20761b957&Type=DEFAULT&Language=PL&Hash=1c66486b7958ea2cd4bc8ef45663b39a347954ac1d198b2689e523ee96df73f8>Regulamin świadczenia usług płatniczych</a> oraz <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>\r\n                Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.\r\n            </li></ul>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 13412,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=903838&MessageID=73cb83aa0afcdfa09502ae2d156b23fc&Type=DEFAULT&Language=EN&Hash=2329965d1131bb7398cac0b994d97eba4af53a1d5051b1a8349ec696052b4200",
        "type": "DEFAULT",
        "language": "EN",
        "inputLabel": "<ul><li>\r\n                I have read and acknowledged <a id=\"regulations_pdf\" target=\"_blank\" href=https://testpay.autopay.eu/webapi/regulation?ServiceID=903838&MessageID=73cb83aa0afcdfa09502ae2d156b23fc&Type=DEFAULT&Language=EN&Hash=2329965d1131bb7398cac0b994d97eba4af53a1d5051b1a8349ec696052b4200>the Terms and Conditions of Payment Services</a> and <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/privacy_policy.pdf\" target=\"_blank\">the Privacy Policy</a></li><li>\r\n                I wish the service to be performed immediately and I am aware that in the event of the withdrawal from the contract I will not obtain the reimbursement of the costs incurred for the services which will have been performed upon my request by the time of the withdrawal.\r\n            </li></ul>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 797,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=903838&MessageID=a8915f3d34ec67d3aa8bb6131d17106f&Type=DEFAULT&Language=PL&GatewayID=1898&Hash=bc3ff1698cc39617eaa318e125044f53bf61b901db682bb0d8a073b768ef86f9",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://testpay.autopay.eu/webapi/regulation?ServiceID=903838&MessageID=a8915f3d34ec67d3aa8bb6131d17106f&Type=DEFAULT&Language=PL&GatewayID=1898&Hash=bc3ff1698cc39617eaa318e125044f53bf61b901db682bb0d8a073b768ef86f9>regulamin</a> i <a class=\"privacy-policy\" href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności Autopay S.A.</a>, zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Autopay S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz żądanie natychmiastowej realizacji usługi płatniczej.</li><li>\n<span class=\"___more ___hidden\">Chcę, aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "gatewayId": 1898,
        "checkedAndHidden": true
      },
      {
        "regulationId": 581,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=903838&MessageID=21f5dbcc051385cb2beaa81f8d80559b&Type=DEFAULT&Language=EN&GatewayID=1898&Hash=2df9ffde3a1bec28cedb748e3ae2981c267afa0436046cfc6a9928dc48a1935d",
        "type": "DEFAULT",
        "language": "EN",
        "inputLabel": "<ul><li>If you wish to make a payment, accept <a id=\"regulations_pdf\" target=\"_blank\" href=https://testpay.autopay.eu/webapi/regulation?ServiceID=903838&MessageID=21f5dbcc051385cb2beaa81f8d80559b&Type=DEFAULT&Language=EN&GatewayID=1898&Hash=2df9ffde3a1bec28cedb748e3ae2981c267afa0436046cfc6a9928dc48a1935d>the terms and conditions</a> and <a class=\"privacy-policy\" href=\"https://autopay.eu/storage/app/media/Autopay_pl/Dokumenty/privacy_policy.pdf\" target=\"_blank\">privacy policy of Autopay S.A.</a>, consent to the initiation of a transaction at your bank through Autopay S.A. and the delivery of the details of this transaction to the recipient, and request for immediate execution of the payment service .</li><li> \n<span class=\"___more ___hidden\">I wish the service to be provided immediately and I acknowledge that I will not be reimbursed for any costs incurred in the event of withdrawal from the contract.</span></li></ul>",
        "gatewayId": 1898,
        "checkedAndHidden": true
      }
    ],
    "languageData": {
      "languagesAvailable": [
        "PL",
        "EN"
      ],
      "languageChosen": "PL"
    },
    "visibilityOptions": {
      "showFrontCountry": false,
      "showFrontEmail": true,
      "showFrontFees": true,
      "showFrontPartnerMarketing": "OPTIONAL",
      "showFrontRegulations": false,
      "showFrontTradeMarketing": "OPTIONAL",
      "showReturnToPaywaysButton": false,
      "showReturnToShopButton": true
    },
    "sessionRemainingTime": 263
  }
}
export const PAYMENT_START_VISA_MOBILE = {
  "data": {
    "clientSource": "visaMobileData",
    "visaMobileData": {
      "orderId": "20231004112831",
      "serviceId": 102837,
      "hash": "4a7f1004410db83e59a4a88ab11480e721878d9b7782a185130d3e4d9df46b7f"
    },
    "additionalData": {
      "customerEmail": "devnull@bm.pl"
    },
    "customerFee": "0.00",
    "languageData": {
      "languagesAvailable": [
        "TR",
        "EN",
        "DE",
        "IT",
        "ES",
        "PL",
        "SK",
        "HU",
        "UK",
        "FR",
        "CS",
        "RO"
      ],
      "languageChosen": "PL"
    },
    "payAmount": 1.00,
    "payAmountCurrrency": "PLN",
    "returnUrl": "https://devnull.bm.pl?ServiceID=102837&OrderID=20231010201253&Hash=a9f0bda5ff754546901865a2f27f183b8b44440a46aae9871ef418b338e71873",
    "serviceUrl": "http://devnull.bm.pl",
    "serviceID": 102837,
    "serviceType": "ECOMMERCE",
    "sessionRemainingTime": 267,
    "shortname": "Test nowych frontów",
    "transRemoteID": "A1MN7XVE1D",
    "transactionValidityTime": "2023-10-13T20:12:53",
    "visibilityOptions": {
      "showFrontFees": true,
      "showReturnToShopButton": true,
      "showFrontBasket": false,
      "showFrontCountry": false,
      "showFrontEmail": true,
      "showFrontPartnerMarketing": "OPTIONAL",
      "showFrontRegulations": false,
      "showFrontTradeMarketing": "OPTIONAL",
      "showReturnToPaywaysButton": false
    }
  }
}
export const PAYMENT_START_VISA_MOBILE_PROMO = {
  "status": "SUCCESS",
  "data": {
    "paywayGroups": [
      {
        "type": "BLIK",
        "order": 1,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "BLIK"
          },
          {
            "languageValue": "EN",
            "name": "BLIK"
          },
          {
            "languageValue": "DE",
            "name": "BLIK"
          },
          {
            "languageValue": "CS",
            "name": "BLIK"
          }
        ]
      },
      {
        "type": "Płatność automatyczna",
        "order": 2,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płatność automatyczna"
          },
          {
            "languageValue": "EN",
            "name": "Automatic payment"
          },
          {
            "languageValue": "DE",
            "name": "Automatische Zahlung"
          },
          {
            "languageValue": "CS",
            "name": "Automatická platba"
          }
        ]
      },
      {
        "type": "Karta płatnicza",
        "order": 3,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Karta płatnicza"
          },
          {
            "languageValue": "EN",
            "name": "Payment card"
          },
          {
            "languageValue": "DE",
            "name": "Zahlungskarte"
          },
          {
            "languageValue": "CS",
            "name": "Platební karta"
          }
        ]
      },
      {
        "type": "PBL",
        "order": 4,
        "languageData": [
          {
            "languageValue": "CS",
            "name": "Platba z banky"
          },
          {
            "languageValue": "PL",
            "name": "Płatność z banku"
          },
          {
            "languageValue": "DE",
            "name": "Zahlung von der Bank"
          },
          {
            "languageValue": "EN",
            "name": "Payment from the bank"
          }
        ],
        "footerShopModel": "MERCHANT"
      },
      {
        "type": "Szybki Przelew",
        "order": 5,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Dane do przelewu"
          },
          {
            "languageValue": "EN",
            "name": "Transfer data"
          },
          {
            "languageValue": "DE",
            "name": "Eigenständige Übertragung"
          },
          {
            "languageValue": "CS",
            "name": "Přenos dat"
          }
        ]
      },
      {
        "type": "Raty online",
        "order": 6,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Raty online"
          },
          {
            "languageValue": "EN",
            "name": "Installments online"
          },
          {
            "languageValue": "DE",
            "name": "Online-Ratenzahlung"
          },
          {
            "languageValue": "CS",
            "name": "On-line splátky"
          }
        ]
      },
      {
        "type": "Portfele elektroniczne",
        "order": 7,
        "languageData": [
          {
            "languageValue": "CS",
            "name": "Elektronické peněženky"
          },
          {
            "languageValue": "DE",
            "name": "Übertragen von Daten"
          },
          {
            "languageValue": "EN",
            "name": "Electronic wallets"
          },
          {
            "languageValue": "PL",
            "name": "Portfele elektroniczne"
          }
        ]
      },
      {
        "type": "Kup teraz, zapłać później",
        "order": 8,
        "languageData": [
          {
            "languageValue": "CS",
            "name": "Kup nyní, zaplať později"
          },
          {
            "languageValue": "DE",
            "name": "Jetzt kaufen, später bezahlen"
          },
          {
            "languageValue": "EN",
            "name": "Buy now, pay later"
          },
          {
            "languageValue": "PL",
            "name": "Kup teraz, zapłać później"
          }
        ]
      }
    ],
    "promotedPayways": [
      {
        "id": 106,
        "name": "PG płatność testowa",
        "type": "PBL",
        "nameOnList": "PG płatność testowa",
        "order": 1,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1500.gif",
        "recurring": false,
        "active": true,
        "promotion": {
          "mode": "HIGHLIGHTED",
          "showFirstPopup": false,
          "highlightedCss": "border: 1px solid #ff0088;",
          "htmls": [
            {
              "language": "EN",
              "headerHtml": "headerEn",
              "tooltipHtml": "tooltipEn",
              "header": "headerEn",
              "tooltip": "tooltipEn"
            },
            {
              "language": "CS",
              "headerHtml": "Propagovaná platba",
              "tooltipHtml": "Dobrá propagace",
              "header": "Propagovaná platbaP",
              "tooltip": "Dobrá propagace"
            },
            {
              "language": "PL",
              "headerHtml": "Promowana płatność",
              "tooltipHtml": "Dobra promka",
              "header": "Promowana płatność",
              "tooltip": "Dobra promka"
            }
          ],
          "type": "PROMOTION"
        },
        "languageData": [
          {
            "languageValue": "SK",
            "name": "PG płatność testowa"
          },
          {
            "languageValue": "UK",
            "name": "PG płatność testowa"
          },
          {
            "languageValue": "TR",
            "name": "PG płatność testowa"
          },
          {
            "languageValue": "CS",
            "name": "PG płatność testowa"
          },
          {
            "languageValue": "FR",
            "name": "PG płatność testowa"
          },
          {
            "languageValue": "ES",
            "name": "PG płatność testowa"
          },
          {
            "languageValue": "HU",
            "name": "PG płatność testowa"
          },
          {
            "languageValue": "RO",
            "name": "PG płatność testowa"
          },
          {
            "languageValue": "EN",
            "name": "PG płatność testowa"
          },
          {
            "languageValue": "IT",
            "name": "PG płatność testowa"
          },
          {
            "languageValue": "DE",
            "name": "PG płatność testowa"
          },
          {
            "languageValue": "PL",
            "name": "PG płatność testowa"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1523,
        "name": "Visa Mobile",
        "type": "Portfele elektroniczne",
        "nameOnList": "Visa Mobile",
        "order": 1,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1523.png",
        "recurring": false,
        "active": true,
        "promotion": {
          "showFirstPopup": false,
          "htmls": [
            {
              "language": "RO",
              "headerHtml": "Visa Mobile",
              "tooltipHtml": "Plata Visa Mobile",
              "header": "Visa Mobile"
            },
            {
              "language": "HU",
              "headerHtml": "Visa Mobile",
              "tooltipHtml": "Visa Mobil fizetés",
              "header": "Visa Mobile"
            },
            {
              "language": "SL",
              "headerHtml": "Visa Mobile",
              "tooltipHtml": "Plačilo Visa Mobile",
              "header": "Visa Mobile"
            },
            {
              "language": "FR",
              "headerHtml": "Visa Mobile",
              "tooltipHtml": "Paiement mobile Visa",
              "header": "Visa Mobile"
            },
            {
              "language": "SK",
              "headerHtml": "Visa Mobile",
              "tooltipHtml": "Platba mobilom Visa",
              "header": "Visa Mobile"
            },
            {
              "language": "PL",
              "headerHtml": "Visa Mobile",
              "tooltipHtml": "Płatność aplikacją Visa Mobile",
              "header": "Visa Mobile"
            },
            {
              "language": "CS",
              "headerHtml": "Visa Mobile",
              "tooltipHtml": "Mobilní platba Visa",
              "header": "Visa Mobile"
            },
            {
              "language": "EN",
              "headerHtml": "Visa Mobile",
              "tooltipHtml": "Visa Mobile payment",
              "header": "Visa Mobile"
            },
            {
              "language": "ES",
              "headerHtml": "Visa Mobile",
              "tooltipHtml": "Visa Pago móvil",
              "header": "Visa Mobile"
            },
            {
              "language": "DE",
              "headerHtml": "Visa Mobile",
              "tooltipHtml": "Visa Mobile-Zahlung",
              "header": "Visa Mobile"
            },
            {
              "language": "HR",
              "headerHtml": "Visa Mobile",
              "tooltipHtml": "Visa Mobile plaćanje",
              "header": "Visa Mobile"
            },
            {
              "language": "UK",
              "headerHtml": "Visa Mobile",
              "tooltipHtml": "Платіж Visa Mobile",
              "header": "Visa Mobile"
            },
            {
              "language": "IT",
              "headerHtml": "Visa Mobile",
              "tooltipHtml": "Pagamento tramite cellulare Visa",
              "header": "Visa Mobile"
            }
          ],
          "type": "VISAMOBILE"
        },
        "languageData": [],
        "redirectToCards": false
      }
    ],
    "lastPayway": {
      "id": 105,
      "name": "PBL płatność testowa",
      "type": "PBL",
      "nameOnList": "PBL płatność testowa",
      "order": 3,
      "recurring": false,
      "active": true,
      "languageData": [
        {
          "languageValue": "SK",
          "name": "PBL płatność testowa"
        },
        {
          "languageValue": "UK",
          "name": "PBL płatność testowa"
        },
        {
          "languageValue": "TR",
          "name": "PBL płatność testowa"
        },
        {
          "languageValue": "CS",
          "name": "PBL płatność testowa"
        },
        {
          "languageValue": "FR",
          "name": "PBL płatność testowa"
        },
        {
          "languageValue": "ES",
          "name": "PBL płatność testowa"
        },
        {
          "languageValue": "HU",
          "name": "PBL płatność testowa"
        },
        {
          "languageValue": "RO",
          "name": "PBL płatność testowa"
        },
        {
          "languageValue": "EN",
          "name": "PBL płatność testowa"
        },
        {
          "languageValue": "IT",
          "name": "PBL płatność testowa"
        },
        {
          "languageValue": "DE",
          "name": "PBL płatność testowa"
        },
        {
          "languageValue": "PL",
          "name": "PBL płatność testowa"
        }
      ],
      "redirectToCards": false
    },
    "payways": [
      {
        "id": 105,
        "name": "PBL płatność testowa",
        "type": "PBL",
        "nameOnList": "PBL płatność testowa",
        "order": 3,
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "SK",
            "name": "PBL płatność testowa"
          },
          {
            "languageValue": "UK",
            "name": "PBL płatność testowa"
          },
          {
            "languageValue": "TR",
            "name": "PBL płatność testowa"
          },
          {
            "languageValue": "CS",
            "name": "PBL płatność testowa"
          },
          {
            "languageValue": "FR",
            "name": "PBL płatność testowa"
          },
          {
            "languageValue": "ES",
            "name": "PBL płatność testowa"
          },
          {
            "languageValue": "HU",
            "name": "PBL płatność testowa"
          },
          {
            "languageValue": "RO",
            "name": "PBL płatność testowa"
          },
          {
            "languageValue": "EN",
            "name": "PBL płatność testowa"
          },
          {
            "languageValue": "IT",
            "name": "PBL płatność testowa"
          },
          {
            "languageValue": "DE",
            "name": "PBL płatność testowa"
          },
          {
            "languageValue": "PL",
            "name": "PBL płatność testowa"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1500,
        "name": "PBC płatność testowa",
        "type": "Karta płatnicza",
        "nameOnList": "PBC płatność testowa",
        "order": 3,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1500.gif",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "SK",
            "name": "PBC płatność testowa"
          },
          {
            "languageValue": "UK",
            "name": "PBC płatność testowa"
          },
          {
            "languageValue": "TR",
            "name": "PBC płatność testowa"
          },
          {
            "languageValue": "CS",
            "name": "PBC płatność testowa"
          },
          {
            "languageValue": "FR",
            "name": "PBC płatność testowa"
          },
          {
            "languageValue": "ES",
            "name": "PBC płatność testowa"
          },
          {
            "languageValue": "HU",
            "name": "PBC płatność testowa"
          },
          {
            "languageValue": "RO",
            "name": "PBC płatność testowa"
          },
          {
            "languageValue": "EN",
            "name": "PBC płatność testowa"
          },
          {
            "languageValue": "IT",
            "name": "PBC płatność testowa"
          },
          {
            "languageValue": "DE",
            "name": "PBC płatność testowa"
          },
          {
            "languageValue": "PL",
            "name": "PBC płatność testowa"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1513,
        "name": "Apple Pay",
        "type": "Portfele elektroniczne",
        "nameOnList": "Apple Pay",
        "order": 4,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1513.png",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "SK",
            "name": "Apple Pay"
          },
          {
            "languageValue": "UK",
            "name": "Apple Pay"
          },
          {
            "languageValue": "TR",
            "name": "Apple Pay"
          },
          {
            "languageValue": "CS",
            "name": "Apple Pay"
          },
          {
            "languageValue": "FR",
            "name": "Apple Pay"
          },
          {
            "languageValue": "ES",
            "name": "Apple Pay"
          },
          {
            "languageValue": "HU",
            "name": "Apple Pay"
          },
          {
            "languageValue": "RO",
            "name": "Apple Pay"
          },
          {
            "languageValue": "EN",
            "name": "Apple Pay"
          },
          {
            "languageValue": "IT",
            "name": "Apple Pay"
          },
          {
            "languageValue": "DE",
            "name": "Apple Pay"
          },
          {
            "languageValue": "PL",
            "name": "Apple Pay"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1512,
        "name": "Google Pay",
        "type": "Portfele elektroniczne",
        "nameOnList": "Google Pay",
        "order": 6,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1512.png",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "SK",
            "name": "Google Pay"
          },
          {
            "languageValue": "UK",
            "name": "Google Pay"
          },
          {
            "languageValue": "TR",
            "name": "Google Pay"
          },
          {
            "languageValue": "CS",
            "name": "Google Pay"
          },
          {
            "languageValue": "FR",
            "name": "Google Pay"
          },
          {
            "languageValue": "ES",
            "name": "Google Pay"
          },
          {
            "languageValue": "HU",
            "name": "Google Pay"
          },
          {
            "languageValue": "RO",
            "name": "Google Pay"
          },
          {
            "languageValue": "EN",
            "name": "Google Pay"
          },
          {
            "languageValue": "IT",
            "name": "Google Pay"
          },
          {
            "languageValue": "DE",
            "name": "Google Pay"
          },
          {
            "languageValue": "PL",
            "name": "Google Pay"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 21,
        "name": "transfer Volkswagen Bank Polska S.A.",
        "type": "Szybki Przelew",
        "nameOnList": "Przelew Volkswagen Bank",
        "order": 80,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/21.gif",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "SK",
            "name": "Przelew Volkswagen Bank"
          },
          {
            "languageValue": "UK",
            "name": "Przelew Volkswagen Bank"
          },
          {
            "languageValue": "TR",
            "name": "Przelew Volkswagen Bank"
          },
          {
            "languageValue": "CS",
            "name": "Převod Volkswagen Bank"
          },
          {
            "languageValue": "FR",
            "name": "Virement Volkswagen Bank"
          },
          {
            "languageValue": "ES",
            "name": "Transferencia Volkswagen Bank"
          },
          {
            "languageValue": "HU",
            "name": "Przelew Volkswagen Bank"
          },
          {
            "languageValue": "RO",
            "name": "Przelew Volkswagen Bank"
          },
          {
            "languageValue": "EN",
            "name": "Transfer Volkswagen Bank"
          },
          {
            "languageValue": "IT",
            "name": "Bonifico Volkswagen Bank"
          },
          {
            "languageValue": "DE",
            "name": "Überweisung Volkswagen Bank"
          },
          {
            "languageValue": "PL",
            "name": "Przelew Volkswagen Bank"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 35,
        "name": "transfer Spółdzielcza Grupa Bankowa",
        "type": "Szybki Przelew",
        "nameOnList": "Spółdzielcza Grupa Bankowa",
        "order": 92,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/35.gif",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "SK",
            "name": "Spółdzielcza Grupa Bankowa"
          },
          {
            "languageValue": "UK",
            "name": "Spółdzielcza Grupa Bankowa"
          },
          {
            "languageValue": "TR",
            "name": "Spółdzielcza Grupa Bankowa"
          },
          {
            "languageValue": "CS",
            "name": "Spółdzielcza Grupa Bankowa"
          },
          {
            "languageValue": "FR",
            "name": "Spółdzielcza Grupa Bankowa"
          },
          {
            "languageValue": "ES",
            "name": "Spółdzielcza Grupa Bankowa"
          },
          {
            "languageValue": "HU",
            "name": "Spółdzielcza Grupa Bankowa"
          },
          {
            "languageValue": "RO",
            "name": "Spółdzielcza Grupa Bankowa"
          },
          {
            "languageValue": "EN",
            "name": "Spółdzielcza Grupa Bankowa"
          },
          {
            "languageValue": "IT",
            "name": "Spółdzielcza Grupa Bankowa"
          },
          {
            "languageValue": "DE",
            "name": "Spółdzielcza Grupa Bankowa"
          },
          {
            "languageValue": "PL",
            "name": "Spółdzielcza Grupa Bankowa"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 9,
        "name": "transfer",
        "type": "Szybki Przelew",
        "nameOnList": "Przelew z innego banku",
        "order": 93,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/9.gif",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "SK",
            "name": "Przelew z innego banku"
          },
          {
            "languageValue": "UK",
            "name": "Przelew z innego banku"
          },
          {
            "languageValue": "TR",
            "name": "Przelew z innego banku"
          },
          {
            "languageValue": "CS",
            "name": "Przelew z innego banku"
          },
          {
            "languageValue": "FR",
            "name": "Przelew z innego banku"
          },
          {
            "languageValue": "ES",
            "name": "Przelew z innego banku"
          },
          {
            "languageValue": "HU",
            "name": "Przelew z innego banku"
          },
          {
            "languageValue": "RO",
            "name": "Przelew z innego banku"
          },
          {
            "languageValue": "EN",
            "name": "Przelew z innego banku"
          },
          {
            "languageValue": "IT",
            "name": "Przelew z innego banku"
          },
          {
            "languageValue": "DE",
            "name": "Przelew z innego banku"
          },
          {
            "languageValue": "PL",
            "name": "Przelew z innego banku"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1897,
        "name": "PG płatność testowa PIS (STABLE)",
        "type": "PBL",
        "nameOnList": "PG płatność testowa PIS",
        "iconUrl": "https://testimages.autopay.eu/pomoc/grafika/1897.png",
        "recurring": false,
        "active": true,
        "typeForPsd2": "PIS",
        "languageData": [],
        "redirectToCards": false
      }
    ],
    "containsPisPayway": true,
    "transRemoteID": "AUG3KR4U82",
    "startAmount": 1.00,
    "startAmountCurrency": "PLN",
    "payAmount": 1.00,
    "payAmountCurrency": "PLN",
    "customerFee": 0.00,
    "transactionValidityTime": "2023-09-03T09:40:26",
    "additionalData": {
      "orderId": "20230831094026",
      "customerEmail": "devnull@bm.pl"
    },
    "acceptorID": 101940,
    "serviceID": 102837,
    "serviceType": "ECOMMERCE",
    "shortName": "Test nowych frontów",
    "receiverNameForFront": "Test nowych frontów",
    "serviceUrl": "http://devnull.bm.pl",
    "returnUrl": "https://devnull.bm.pl?ServiceID=102837&OrderID=20230831094026&Hash=54aed866e546e161b3b6f1f9acf2ffa3617b680fae85d010b7de0c77a52d557f",
    "regulations": [
      {
        "regulationId": 10355,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Blue Media S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 10355,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa",
        "type": "DEFAULT",
        "language": "SK",
        "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Blue Media S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 10355,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa",
        "type": "DEFAULT",
        "language": "UK",
        "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Blue Media S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 10355,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa",
        "type": "DEFAULT",
        "language": "TR",
        "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Blue Media S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 10355,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa",
        "type": "DEFAULT",
        "language": "CS",
        "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Blue Media S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 10355,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa",
        "type": "DEFAULT",
        "language": "FR",
        "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Blue Media S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 10355,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa",
        "type": "DEFAULT",
        "language": "ES",
        "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Blue Media S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 10355,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa",
        "type": "DEFAULT",
        "language": "HU",
        "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Blue Media S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 10355,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa",
        "type": "DEFAULT",
        "language": "RO",
        "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Blue Media S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 10355,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa",
        "type": "DEFAULT",
        "language": "EN",
        "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Blue Media S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 10355,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa",
        "type": "DEFAULT",
        "language": "IT",
        "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Blue Media S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 10355,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa",
        "type": "DEFAULT",
        "language": "DE",
        "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://testpay.autopay.eu/webapi/regulation?ServiceID=102837&MessageID=43d63230a16b8f5669685397e5c9eb9f&Type=DEFAULT&Language=PL&Hash=717426d2cd70b6b02bdb2608d7e89d815e3db2121cc32fc7d83a01b60c4190aa\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Blue Media S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 830,
        "type": "DEFAULT",
        "language": "SK",
        "inputLabel": "Kliknutím na tlačidlo \"Zaplatiť\", súhlasíte so začatím transakcie vo Vašej banke a poskytnutím detailov príjemcovi finančných prostriedkov prostredníctvom Autopay S.A. – spoločnosti kontrolovanej poľským Úradom pre finančný trh (pozri kontaktné údaje KNF) a zapísanej v registri poskytovateľov platobných služieb pod číslom IP17/2013. Spoločnosť Autopay S.A. sídli v Sopote (81-718) ul. Powstańców Warszawy 6. Kontakt: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 833,
        "type": "DEFAULT",
        "language": "UK",
        "inputLabel": "Натискаючи на кнопку \"Почати платіж\", Ви надаєте згоду на те, щоб розпочати транзакцію у Вашому банку і передати її детальні відомості отримувачу коштів через Autopay S.A. - акціонерне товариство, контроль за діяльністю якого здійснює Комісія фінансового нагляду (див. контактні дані КФН), і яке зареєстроване в реєстрі платіжних установ під номером IP17/2013. Товариство Autopay S.A. має наступну юридичну адресу: м.Сопот (81-718), вул. Повстаньцув Варшави 6. Контакт: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 827,
        "type": "DEFAULT",
        "language": "CS",
        "inputLabel": "Kliknutím na \"Zahájit platbu\" vyjadřujete souhlas se zahájením transakce u Vaší banky a poskytnutím její podrobností příjemci prostředků subjektem Autopay S.A. - společností pod dozorem Komise pro finanční dozor (zobrazit kontaktní údaje Komise pro finanční dozor) zapsanou v registru polských platebních institucí pod číslem IP17/2013. Společnost Autopay S.A. má sídlo v Sopotech (81-718) v ulici Powstańców Warszawy 6. Kontakt: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 826,
        "type": "DEFAULT",
        "language": "FR",
        "inputLabel": "En cliquant sur « Commencez le paiement », vous acceptez que la transaction soit initiée auprès de votre banque et que ses détails soient transmis au destinataire des fonds par Autopay S.A. - une société supervisée par la Commission de contrôle financier [KNF] (voir les coordonnées de KNF) et inscrite au registre des établissements de paiement nationaux sous le numéro IP17/2013. La société Autopay Spółka Akcyjna a son siège à Sopot, ul. Powstańców Warszawy 6, 81-718. Contact : pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 829,
        "type": "DEFAULT",
        "language": "ES",
        "inputLabel": "Al hacer clic en \"Iniciar pago\", aceptas el inicio de la operación por tu banco y el envío del detalle de la misma al destinatario por Autopay S.A., sociedad supervisada por la Comisión de Supervisión Financiera (ver datos de contacto de la Comisión) e inscrita en el registro de entidades de pago nacionales con el no. IP17/2013. El domicilio de Autopay S.A. es: Sopot (81-718), ul. Powstańców Warszawy 6. Contacto: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 832,
        "type": "DEFAULT",
        "language": "HU",
        "inputLabel": "A „Fizetés indítása” gombra kattintva beleegyezel, hogy bankodban elindítsad a tranzakciót, és a Autopay S.A. átadja annak részleteit a pénzösszeg címzettjének. - a Pénzügyi Felügyelet által felügyelt társaság (lásd a Pénzügyi Felügyelet elérhetőségeit), és az IP17/2013 számon bejegyzett a hazai pénzforgalmi intézmények nyilvántartásába. Autopay S.A. Társaság székhelye: Sopot (81-718) ul. Powstańców Warszawy 6. Kapcsolat: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 831,
        "type": "DEFAULT",
        "language": "RO",
        "inputLabel": "Făcând clic pe „Începe plata”, ești de acord să începi tranzacția în banca Ta și să furnizezi detaliile acesteia destinatarului fondurilor prin intermediul Autopay S.A. - companie supravegheată de Autoritatea de  upraveghere Financiară din Polonia (vezi datele de contact KNF) și înregistrată în registrul instituțiilor naționale de plată sub numărul IP17/2013. Autopay S.A. are sediul social în Sopot (81-718), ul. Powstańców Warszawy 6. Contact: Pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 824,
        "type": "DEFAULT",
        "language": "EN",
        "inputLabel": "By clicking \"Start payment\", you agree that your bank will initiate the transaction and provide its details to the recipient of the funds by Autopay S.A. - a company supervised by the Polish Financial Supervision Authority (see PFSA contact details) and entered in the register of national payment institutions under No. IP17/2013. Autopay S.A. is headquartered in Sopot (81-718) at ul. Powstańców Warszawy 6. Contact: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 828,
        "type": "DEFAULT",
        "language": "IT",
        "inputLabel": "Cliccando sul pulsante “Avvia il pagamento” accetti di avviare la transazione con la tua banca e di fornire i dettagli al destinatario dei fondi da parte di Autopay S.A. -società sorvegliata dalla Commissione per la vigilanza finanziaria (cfr. i recapiti della Commissione) e iscritta al registro degli istituti di pagamento nazionali al numero IP17/2013. Società Autopay S.A. con sede a Sopot (81-718) in ul. Powstańców Warszawy 6. Contatti: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 825,
        "type": "DEFAULT",
        "language": "DE",
        "inputLabel": "Indem Sie auf „Zahlungsvorgang starten“ klicken, erklären Sie sich damit einverstanden, dass die Transaktion bei Ihrer Bank eingeleitet wird und die Daten von Autopay S.A. (eine von der Finanzaufsichtsbehörde beaufsichtigte Gesellschaft (siehe Kontaktdaten Finanzaufsichtsbehörde), die im Register der nationalen Zahlungsinstitute unter der Nummer IP17/2013 eingetragen ist) an den Empfänger des Geldbetrags übermittelt werden. Der Hauptsitz von Autopay S.A. befindet sich in Sopot (81-718), ul. Powstańców Warszawy 6. Kontakt: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 823,
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "Wybierając przycisk „rozpocznij płatność”, zgadzasz się na rozpoczęcie transakcji w Twoim banku i dostarczenie jej szczegółów do odbiorcy środków. Dostawcą usługi jest Autopay S.A. z siedzibą w Sopocie (ul. Powstańców Warszawy 6, 81-718 Sopot, e-mail: pomoc@autopay.pl), nadzorowana przez Komisję Nadzoru Finansowego (<a href=\"https://www.knf.gov.pl/o_nas/urzad_komisji/dane_teleadresowe\" target=\"_blank\">https://www.knf.gov.pl/o_nas/urzad_komisji/dane_teleadresowe</a>) i wpisana do rejestru krajowych instytucji płatniczych pod numerem IP17/2013.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      }
    ],
    "clientSource": "payways",
    "languageData": {
      "languagesAvailable": [
        "SK",
        "UK",
        "TR",
        "CS",
        "FR",
        "ES",
        "HU",
        "RO",
        "EN",
        "IT",
        "DE",
        "PL"
      ],
      "languageChosen": "PL"
    },
    "visibilityOptions": {
      "showFrontCountry": false,
      "showFrontEmail": true,
      "showFrontFees": true,
      "showFrontPartnerMarketing": "OPTIONAL",
      "showFrontRegulations": true,
      "showFrontTradeMarketing": "OPTIONAL",
      "showReturnToPaywaysButton": false,
      "showReturnToShopButton": true,
      "showFrontBasket": false
    },
    "sessionRemainingTime": 201,
    "isCardRecurringRegulationToShow": true
  }
}
export const PAYMENT_START_PIS_MILLENIUM = {
  "status": "SUCCESS", "data": {
    "paywayGroups": [{
      "type": "BLIK",
      "order": 1,
      "languageData": [{"languageValue": "PL", "name": "BLIK"}, {
        "languageValue": "EN",
        "name": "BLIK"
      }, {"languageValue": "DE", "name": "BLIK"}, {"languageValue": "CS", "name": "BLIK"}, {
        "languageValue": "IT",
        "name": "BLIK"
      }, {"languageValue": "FR", "name": "BLIK"}, {"languageValue": "ES", "name": "BLIK"}, {
        "languageValue": "RO",
        "name": "BLIK"
      }, {"languageValue": "SK", "name": "BLIK"}, {"languageValue": "HU", "name": "BLIK"}, {
        "languageValue": "UK",
        "name": "BLIK"
      }, {"languageValue": "EL", "name": "BLIK"}, {"languageValue": "HR", "name": "BLIK"}, {
        "languageValue": "SL",
        "name": "BLIK "
      }, {"languageValue": "TR", "name": "BLIK"}]
    }, {
      "type": "Płatność automatyczna",
      "order": 2,
      "languageData": [{"languageValue": "PL", "name": "Płatność automatyczna"}, {
        "languageValue": "EN",
        "name": "Automatic payment"
      }, {"languageValue": "DE", "name": "Automatische Zahlung"}, {
        "languageValue": "CS",
        "name": "Automatická platba"
      }, {"languageValue": "IT", "name": "Pagamento automatico"}, {
        "languageValue": "FR",
        "name": "Paiement automatique"
      }, {"languageValue": "ES", "name": "Pago automático"}, {
        "languageValue": "RO",
        "name": "Plata automată"
      }, {"languageValue": "SK", "name": "Automatická platba"}, {
        "languageValue": "HU",
        "name": "Automatikus fizetés"
      }, {"languageValue": "UK", "name": "Автоматичний платіж"}, {
        "languageValue": "EL",
        "name": "Αυτόματη πληρωμή"
      }, {"languageValue": "HR", "name": "Automatsko plaćanje"}, {
        "languageValue": "SL",
        "name": "Sanidejno plačilo "
      }, {"languageValue": "TR", "name": "Otomatik ödeme"}]
    }, {
      "type": "Karta płatnicza",
      "order": 3,
      "languageData": [{"languageValue": "PL", "name": "Karta płatnicza"}, {
        "languageValue": "EN",
        "name": "Payment card"
      }, {"languageValue": "DE", "name": "Zahlungskarte"}, {
        "languageValue": "CS",
        "name": "Platební karta"
      }, {"languageValue": "IT", "name": "Carta di pagamento"}, {
        "languageValue": "FR",
        "name": "Carte de paiement"
      }, {"languageValue": "ES", "name": "Tarjeta de pago"}, {
        "languageValue": "RO",
        "name": "Card de credit"
      }, {"languageValue": "SK", "name": "Platobná karta"}, {
        "languageValue": "HU",
        "name": "Hitelkártya"
      }, {"languageValue": "UK", "name": "Платіжна картка"}, {
        "languageValue": "EL",
        "name": "Πιστωτική κάρτα"
      }, {"languageValue": "HR", "name": "Kartica za plaćanje"}, {
        "languageValue": "SL",
        "name": "Plačilna kartica "
      }, {"languageValue": "TR", "name": "Ödeme kartı"}]
    }, {
      "type": "PBL",
      "order": 4,
      "languageData": [{"languageValue": "PL", "name": "Płatność z banku"}, {
        "languageValue": "EN",
        "name": "Payment from the bank"
      }, {"languageValue": "DE", "name": "Zahlung von der Bank"}, {
        "languageValue": "CS",
        "name": "Platba z banky"
      }, {"languageValue": "IT", "name": "Pagamento dalla banca"}, {
        "languageValue": "FR",
        "name": "Paiement depuis la banque."
      }, {"languageValue": "ES", "name": "Pago de un banco"}, {
        "languageValue": "RO",
        "name": "Plata de la bancă"
      }, {"languageValue": "SK", "name": "Platba z banky"}, {
        "languageValue": "HU",
        "name": "Fizetés a banktól"
      }, {"languageValue": "UK", "name": "Платіж з банку"}, {
        "languageValue": "EL",
        "name": "Πληρωμή από την τράπεζα"
      }, {"languageValue": "EL", "name": "Πληρωμή από την τράπεζα"}, {
        "languageValue": "HR",
        "name": "Plaćanje putem banke"
      }, {"languageValue": "HR", "name": "Plaćanje putem banke"}, {
        "languageValue": "SL",
        "name": "Plačilo iz banke "
      }, {"languageValue": "SL", "name": "Plačilo iz banke "}, {
        "languageValue": "TR",
        "name": "Banka ödemesi"
      }, {"languageValue": "TR", "name": "Banka ödemesi"}],
      "footerShopModel": "MERCHANT"
    }, {
      "type": "Szybki Przelew",
      "order": 5,
      "languageData": [{"languageValue": "PL", "name": "Samodzielny Przelew"}, {
        "languageValue": "EN",
        "name": "Standalone transfer"
      }, {"languageValue": "DE", "name": "Eigenständige Übertragung"}, {
        "languageValue": "CS",
        "name": "Samostatný převod"
      }, {"languageValue": "IT", "name": "Trasferimento autonomo"}, {
        "languageValue": "FR",
        "name": "Transfert autonome"
      }, {"languageValue": "ES", "name": "Transferencia autónoma"}, {
        "languageValue": "RO",
        "name": "Transfer autonom"
      }, {"languageValue": "SK", "name": "Samostatný prevod"}, {
        "languageValue": "HU",
        "name": "Gyors Utalás"
      }, {"languageValue": "UK", "name": "Незалежна передача - Nezalezhna peredacha"}, {
        "languageValue": "EL",
        "name": "Ανεξάρτητη Μεταγραφή"
      }, {"languageValue": "HR", "name": "Neovisni prijenos"}, {
        "languageValue": "SL",
        "name": "Neodvisno nakazilo "
      }, {"languageValue": "TR", "name": "Bağımsız transfer"}]
    }, {
      "type": "Raty online",
      "order": 6,
      "languageData": [{"languageValue": "PL", "name": "Raty online"}, {
        "languageValue": "EN",
        "name": "Installments online"
      }, {"languageValue": "DE", "name": "Online-Ratenzahlung"}, {
        "languageValue": "CS",
        "name": "On-line splátky"
      }, {"languageValue": "IT", "name": "Rate online"}, {
        "languageValue": "FR",
        "name": "Tranches en ligne"
      }, {"languageValue": "ES", "name": "Cuotas en línea"}, {
        "languageValue": "RO",
        "name": "Rate online"
      }, {"languageValue": "SK", "name": "Splátky online"}, {
        "languageValue": "HU",
        "name": "Online részletfizetések"
      }, {"languageValue": "UK", "name": "Онлайн-розстрочка"}, {
        "languageValue": "EL",
        "name": "Ηλεκτρονικές δόσεις"
      }, {"languageValue": "HR", "name": "Online rate"}, {
        "languageValue": "SL",
        "name": "Spletni obroki "
      }, {"languageValue": "TR", "name": "Çevrimiçi taksit"}]
    }],
    "promotedPayways": [],
    "lastPayway": {
      "id": 1807,
      "name": "Millenium PSD2 PIS",
      "type": "PBL",
      "nameOnList": "Płatność z Millenium",
      "order": 25,
      "iconUrl": "https://images.autopay.eu/pomoc/grafika/1807.png",
      "recurring": false,
      "active": true,
      "typeForPsd2": "PIS",
      "languageData": [{"languageValue": "PL", "name": "Płatność z Millenium"}],
      "redirectToCards": false
    },
    "payways": [{
      "id": 1807,
      "name": "Millenium PSD2 PIS",
      "type": "PBL",
      "nameOnList": "Płatność z Millenium",
      "order": 25,
      "iconUrl": "https://images.autopay.eu/pomoc/grafika/1807.png",
      "recurring": false,
      "active": true,
      "typeForPsd2": "PIS",
      "languageData": [{"languageValue": "PL", "name": "Płatność z Millenium"}],
      "redirectToCards": false
    }],
    "containsPisPayway": true,
    "transRemoteID": "A5AQC9BTM3",
    "startAmount": 1.00,
    "startAmountCurrency": "PLN",
    "payAmount": 1.00,
    "payAmountCurrency": "PLN",
    "customerFee": 0.00,
    "transactionValidityTime": "2023-03-05T13:50:26",
    "additionalData": {"orderId": "20230302135026", "customerEmail": "devnull@bm.pl"},
    "acceptorID": 100905,
    "serviceID": 101332,
    "serviceType": "VERIFICATION_NOT_BANK",
    "shortName": "F-Trust Spółka Akcyjna (WER)",
    "receiverNameForFront": "F-Trust Spółka Akcyjna",
    "serviceUrl": "https://logowanie.f-trust.pl/",
    "returnUrl": "https://logowanie.f-trust.pl/PrzelewWeryfikacyjny/Powrot?ServiceID=101332&OrderID=20230302135026&Hash=4e20226cb3d89233fcaf37fec4d8aa6f7f5c49898e1402af68211ccca54f5517",
    "regulations": [{
      "regulationId": 1,
      "type": "DEFAULT",
      "language": "PL",
      "inputLabel": "Wybierając przycisk „rozpocznij płatność”, zgadzasz się na rozpoczęcie transakcji w Twoim banku i dostarczenie jej szczegółów do odbiorcy środków. Dostawcą usługi jest Autopay S.A. z siedzibą w Sopocie (ul. Powstańców Warszawy 6, 81-718 Sopot, e-mail: pomoc@bm.pl), nadzorowana przez Komisję Nadzoru Finansowego (<a href=\"https://www.knf.gov.pl/o_nas/urzad_komisji/dane_teleadresowe\" target=\"_blank\">https://www.knf.gov.pl/o_nas/urzad_komisji/dane_teleadresowe</a>) i wpisana do rejestru krajowych instytucji płatniczych pod numerem IP17/2013.",
      "gatewayId": 1807,
      "checkedAndHidden": true
    }],
    "clientSource": "psd2Data",
    "languageData": {"languagesAvailable": ["PL"], "languageChosen": "PL"},
    "visibilityOptions": {
      "showFrontCountry": false,
      "showFrontEmail": false,
      "showFrontFees": false,
      "showFrontPartnerMarketing": "OPTIONAL",
      "showFrontRegulations": false,
      "showFrontTradeMarketing": "OPTIONAL",
      "showReturnToPaywaysButton": false,
      "showReturnToShopButton": true,
      "showFrontBasket": false
    },
    "sessionRemainingTime": 267,
    "chosenPaywayId": 1807,
    "isCardRecurringRegulationToShow": true
  }
}
export const PAYMENT_START_OTP = {
  'status': 'SUCCESS',
  'data': {
    'paywayGroups': [{
      'type': 'PBC',
      'order': 1,
      'languageData': [{'languageValue': 'PL', 'name': 'Karty'}, {
        'languageValue': 'EN',
        'name': 'Cards'
      }, {'languageValue': 'DE', 'name': 'Karten'}, {'languageValue': 'CS', 'name': 'Karty'}]
    }, {
      'type': 'PG_PBL',
      'order': 2,
      'languageData': [{'languageValue': 'PL', 'name': 'Przelewy'}, {
        'languageValue': 'EN',
        'name': 'Transfers'
      }, {'languageValue': 'DE', 'name': 'Überweisungen'}, {'languageValue': 'CS', 'name': 'Převody'}]
    }],
    'promotedPayways': [],
    'lastPayway': {
      'id': 509,
      'name': 'BLIK',
      'type': 'BLIK',
      'nameOnList': 'BLIK',
      'order': 8,
      'iconUrl': 'https://images.autopay.eu/pomoc/grafika/509.png',
      'recurring': false,
      'active': true,
      'languageData': [{'languageValue': 'CS', 'name': 'BLIK'}, {
        'languageValue': 'SK',
        'name': 'BLIK'
      }, {'languageValue': 'FR', 'name': 'BLIK'}, {'languageValue': 'DE', 'name': 'BLIK'}, {
        'languageValue': 'IT',
        'name': 'BLIK'
      }, {'languageValue': 'EN', 'name': 'BLIK'}, {'languageValue': 'RO', 'name': 'BLIK'}, {
        'languageValue': 'SL',
        'name': 'BLIK'
      }, {'languageValue': 'EL', 'name': 'BLIK'}, {'languageValue': 'PL', 'name': 'BLIK'}, {
        'languageValue': 'HU',
        'name': 'BLIK'
      }, {'languageValue': 'UK', 'name': 'BLIK'}, {'languageValue': 'ES', 'name': 'BLIK'}, {
        'languageValue': 'HR',
        'name': 'BLIK'
      }, {'languageValue': 'TR', 'name': 'BLIK'}],
      'redirectToCards': false
    },
    'payways': [
      {
        "id": 700,
        "name": "Kup teraz zapłać później",
        "type": "Kup teraz, zapłać później",
        "nameOnList": "Kup teraz, zapłać później",
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/700.png",
        "recurring": false,
        "active": true,
        "languageData": [{"languageValue": "PL", "name": "Kup teraz, zapłać później"}, {
          "languageValue": "DE",
          "name": "Jetzt kaufen, später bezahlen"
        }, {"languageValue": "IT", "name": "Acquista ora, paga dopo"}, {
          "languageValue": "TR",
          "name": "Kup teraz, zapłać później"
        }, {"languageValue": "HU", "name": "Kup teraz, zapłać później"}, {
          "languageValue": "UK",
          "name": "Kup teraz, zapłać później"
        }, {"languageValue": "FR", "name": "Acheter maintenant, payer plus tard"}, {
          "languageValue": "EN",
          "name": "Buy now, pay later"
        }, {"languageValue": "CS", "name": "Koupit hned, zaplatit později"}, {
          "languageValue": "RO",
          "name": "Cumpărați acum, plătiți mai târziu"
        }, {"languageValue": "ES", "name": "Compra ahora, paga después"}, {
          "languageValue": "SK",
          "name": "Kúpiť hneď, zaplatiť neskôr"
        }],
        "redirectToCards": false
      },
      {
        "id": 701,
        "name": "OTP PayTree",
        "type": "Kup teraz, zapłać później",
        "nameOnList": "Kup teraz, zapłać później",
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/701.png",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "UK",
            "name": "Zapłać później z Payka"
          },
          {
            "languageValue": "IT",
            "name": "Zapłać później z Payka"
          },
          {
            "languageValue": "SK",
            "name": "Zapłać później z Payka"
          },
          {
            "languageValue": "ES",
            "name": "Zapłać później z Payka"
          },
          {
            "languageValue": "HU",
            "name": "Zapłać później z Payka"
          },
          {
            "languageValue": "TR",
            "name": "Zapłać później z Payka"
          },
          {
            "languageValue": "PL",
            "name": "Zapłać później z Payka"
          },
          {
            "languageValue": "EN",
            "name": "Zapłać później z Payka"
          },
          {
            "languageValue": "RO",
            "name": "Zapłać później z Payka"
          },
          {
            "languageValue": "CS",
            "name": "Zapłać później z Payka"
          },
          {
            "languageValue": "DE",
            "name": "Zapłać później z Payka"
          },
          {
            "languageValue": "FR",
            "name": "Zapłać później z Payka"
          }
        ],
        "redirectToCards": false
      },
      {
        'id': 1513,
        'name': 'Apple Pay',
        'type': 'Karta płatnicza',
        'nameOnList': 'Apple Pay',
        'order': 4,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/1513.png',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Apple Pay'}, {
          'languageValue': 'SK',
          'name': 'Apple Pay'
        }, {'languageValue': 'FR', 'name': 'Apple Pay'}, {
          'languageValue': 'DE',
          'name': 'Apple Pay'
        }, {'languageValue': 'IT', 'name': 'Apple Pay'}, {
          'languageValue': 'EN',
          'name': 'Apple Pay'
        }, {'languageValue': 'RO', 'name': 'Apple Pay'}, {
          'languageValue': 'SL',
          'name': 'Apple Pay'
        }, {'languageValue': 'EL', 'name': 'Apple Pay'}, {
          'languageValue': 'PL',
          'name': 'Apple Pay'
        }, {'languageValue': 'HU', 'name': 'Apple Pay'}, {
          'languageValue': 'UK',
          'name': 'Apple Pay'
        }, {'languageValue': 'ES', 'name': 'Apple Pay'}, {
          'languageValue': 'HR',
          'name': 'Apple Pay'
        }, {'languageValue': 'TR', 'name': 'Apple Pay'}],
        'redirectToCards': false
      },
      {
        'id': 1512,
        'name': 'Google Pay',
        'type': 'Karta płatnicza',
        'nameOnList': 'Google Pay',
        'order': 5,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/1512.png',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Google Pay'}, {
          'languageValue': 'SK',
          'name': 'Google Pay'
        }, {'languageValue': 'FR', 'name': 'Google Pay'}, {
          'languageValue': 'DE',
          'name': 'Google Pay'
        }, {'languageValue': 'IT', 'name': 'Google Pay'}, {
          'languageValue': 'EN',
          'name': 'Google Pay'
        }, {'languageValue': 'RO', 'name': 'Google Pay'}, {
          'languageValue': 'SL',
          'name': 'Google Pay'
        }, {'languageValue': 'EL', 'name': 'Google Pay'}, {
          'languageValue': 'PL',
          'name': 'Google Pay'
        }, {'languageValue': 'HU', 'name': 'Google Pay'}, {
          'languageValue': 'UK',
          'name': 'Google Pay'
        }, {'languageValue': 'ES', 'name': 'Google Pay'}, {
          'languageValue': 'HR',
          'name': 'Google Pay'
        }, {'languageValue': 'TR', 'name': 'Google Pay'}],
        'redirectToCards': false
      },
      {
        'id': 1511,
        'name': 'Visa',
        'type': 'Karta płatnicza',
        'nameOnList': 'Visa',
        'order': 6,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/1511.png',
        'recurring': false,
        'active': true,
        'additionalData': {'api_key': 'A92M10WGYXL33W8YRN2E21fGMPqgYFqeU0t1kMgY45yX-BGw0', 'acceptor_id': '100004'},
        'languageData': [{'languageValue': 'CS', 'name': 'Visa'}, {
          'languageValue': 'SK',
          'name': 'Visa'
        }, {'languageValue': 'FR', 'name': 'Visa'}, {'languageValue': 'DE', 'name': 'Visa'}, {
          'languageValue': 'IT',
          'name': 'Visa'
        }, {'languageValue': 'EN', 'name': 'Visa'}, {'languageValue': 'RO', 'name': 'Visa'}, {
          'languageValue': 'SL',
          'name': 'Visa'
        }, {'languageValue': 'EL', 'name': 'Visa'}, {'languageValue': 'PL', 'name': 'Visa'}, {
          'languageValue': 'HU',
          'name': 'Visa'
        }, {'languageValue': 'UK', 'name': 'Visa'}, {'languageValue': 'ES', 'name': 'Visa'}, {
          'languageValue': 'HR',
          'name': 'Visa'
        }, {'languageValue': 'TR', 'name': 'Visa'}],
        'redirectToCards': false
      },
      {
        'id': 509,
        'name': 'BLIK',
        'type': 'BLIK',
        'nameOnList': 'BLIK',
        'order': 8,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/509.png',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'BLIK'}, {
          'languageValue': 'SK',
          'name': 'BLIK'
        }, {'languageValue': 'FR', 'name': 'BLIK'}, {'languageValue': 'DE', 'name': 'BLIK'}, {
          'languageValue': 'IT',
          'name': 'BLIK'
        }, {'languageValue': 'EN', 'name': 'BLIK'}, {'languageValue': 'RO', 'name': 'BLIK'}, {
          'languageValue': 'SL',
          'name': 'BLIK'
        }, {'languageValue': 'EL', 'name': 'BLIK'}, {'languageValue': 'PL', 'name': 'BLIK'}, {
          'languageValue': 'HU',
          'name': 'BLIK'
        }, {'languageValue': 'UK', 'name': 'BLIK'}, {'languageValue': 'ES', 'name': 'BLIK'}, {
          'languageValue': 'HR',
          'name': 'BLIK'
        }, {'languageValue': 'TR', 'name': 'BLIK'}],
        'redirectToCards': false
      },
      {
        'id': 68,
        'name': 'ING PBL',
        'type': 'PBL',
        'nameOnList': 'Płać z ING',
        'order': 9,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/68.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Plaťte z ING'}, {
          'languageValue': 'SK',
          'name': 'Plaťte prostredníctvom ING'
        }, {'languageValue': 'FR', 'name': 'Payez depuis ING'}, {
          'languageValue': 'DE',
          'name': 'Mit ING bezahlen'
        }, {'languageValue': 'IT', 'name': 'Paga con ING'}, {
          'languageValue': 'EN',
          'name': 'Pay with ING'
        }, {'languageValue': 'RO', 'name': 'Plătiți cu ING'}, {
          'languageValue': 'SL',
          'name': 'Plačajte z ING'
        }, {'languageValue': 'EL', 'name': 'Πληρώστε με την ING'}, {
          'languageValue': 'PL',
          'name': 'Płać z ING'
        }, {'languageValue': 'HU', 'name': 'Fizessen ING-ből'}, {
          'languageValue': 'UK',
          'name': 'Сплачую з ING'
        }, {'languageValue': 'ES', 'name': 'Paga con ING'}, {
          'languageValue': 'HR',
          'name': 'Platite putem ING'
        }, {'languageValue': 'TR', 'name': 'ING ile öde'}],
        'redirectToCards': false
      },
      {
        'id': 3,
        'name': 'mtransfer',
        'type': 'PBL',
        'nameOnList': 'mTransfer',
        'order': 12,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/3.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'mTransfer'}, {
          'languageValue': 'SK',
          'name': 'mTransfer'
        }, {'languageValue': 'FR', 'name': 'mTransfer'}, {
          'languageValue': 'DE',
          'name': 'mTransfer'
        }, {'languageValue': 'IT', 'name': 'mTransfer'}, {
          'languageValue': 'EN',
          'name': 'mTransfer'
        }, {'languageValue': 'RO', 'name': 'mTransfer'}, {
          'languageValue': 'SL',
          'name': 'mTransfer'
        }, {'languageValue': 'EL', 'name': 'mTransfer'}, {
          'languageValue': 'PL',
          'name': 'mTransfer'
        }, {'languageValue': 'HU', 'name': 'mTransfer'}, {
          'languageValue': 'UK',
          'name': 'mTransfer'
        }, {'languageValue': 'ES', 'name': 'mTransfer'}, {
          'languageValue': 'HR',
          'name': 'mTransfer'
        }, {'languageValue': 'TR', 'name': 'mTransfer'}],
        'redirectToCards': false
      },
      {
        'id': 1063,
        'name': 'IPKO PBL',
        'type': 'PBL',
        'nameOnList': 'Płacę z IPKO',
        'order': 15,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/1063.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Platím s IPKO'}, {
          'languageValue': 'SK',
          'name': 'Platím cez IPKO'
        }, {'languageValue': 'FR', 'name': 'Je paye depuis IPKO'}, {
          'languageValue': 'DE',
          'name': 'Ich bezahle mit IPKO'
        }, {'languageValue': 'IT', 'name': 'Pago con IPKO'}, {
          'languageValue': 'EN',
          'name': 'I pay with IPKO'
        }, {'languageValue': 'RO', 'name': 'Plătesc cu IPKO'}, {
          'languageValue': 'SL',
          'name': 'Plačam z IPKO'
        }, {'languageValue': 'EL', 'name': 'Πληρώνω με IPKO'}, {
          'languageValue': 'PL',
          'name': 'Płacę z IPKO'
        }, {'languageValue': 'HU', 'name': 'Fizetek IPKO-ból'}, {
          'languageValue': 'UK',
          'name': 'Сплачую з IPKO'
        }, {'languageValue': 'ES', 'name': 'Pago con IPKO'}, {
          'languageValue': 'HR',
          'name': 'Plaćam putem IPKO'
        }, {'languageValue': 'TR', 'name': 'IPKO ile ödüyorum'}],
        'redirectToCards': false
      },
      {
        'id': 27,
        'name': 'Santander online',
        'type': 'PBL',
        'nameOnList': 'Santander online',
        'order': 18,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/27.png',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Santander online'}, {
          'languageValue': 'SK',
          'name': 'Santander online'
        }, {'languageValue': 'FR', 'name': 'Santander online'}, {
          'languageValue': 'DE',
          'name': 'Santander online'
        }, {'languageValue': 'IT', 'name': 'Santander online'}, {
          'languageValue': 'EN',
          'name': 'Santander online'
        }, {'languageValue': 'RO', 'name': 'Santander online'}, {
          'languageValue': 'SL',
          'name': 'Santander online'
        }, {'languageValue': 'EL', 'name': 'Santander σε απευθείας σύνδεση'}, {
          'languageValue': 'PL',
          'name': 'Santander online'
        }, {'languageValue': 'HU', 'name': 'Santander online'}, {
          'languageValue': 'UK',
          'name': 'Santander online'
        }, {'languageValue': 'ES', 'name': 'Santander online'}, {
          'languageValue': 'HR',
          'name': 'Santander online'
        }, {'languageValue': 'TR', 'name': 'Santander online'}],
        'redirectToCards': false
      },
      {
        'id': 52,
        'name': 'Pekao24 PBL',
        'type': 'PBL',
        'nameOnList': 'Pekao24 PBL',
        'order': 21,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/52.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Pekao24 PBL'}, {
          'languageValue': 'SK',
          'name': 'Pekao24 PBL'
        }, {'languageValue': 'FR', 'name': 'Pekao24 PBL'}, {
          'languageValue': 'DE',
          'name': 'Pekao24 PBL'
        }, {'languageValue': 'IT', 'name': 'Pekao24 PBL'}, {
          'languageValue': 'EN',
          'name': 'Pekao24 PBL'
        }, {'languageValue': 'RO', 'name': 'Pekao24 PBL'}, {
          'languageValue': 'SL',
          'name': 'Pekao24 PBL'
        }, {'languageValue': 'EL', 'name': 'Pekao24 PBL'}, {
          'languageValue': 'PL',
          'name': 'Pekao24 PBL'
        }, {'languageValue': 'HU', 'name': 'Pekao24 PBL'}, {
          'languageValue': 'UK',
          'name': 'Pekao24 PBL'
        }, {'languageValue': 'ES', 'name': 'Pekao24 PBL'}, {
          'languageValue': 'HR',
          'name': 'Pekao24 PBL'
        }, {'languageValue': 'TR', 'name': 'Pekao24 PBL'}],
        'redirectToCards': false
      },
      {
        'id': 85,
        'name': 'Millennium PBL',
        'type': 'PBL',
        'nameOnList': 'Millennium Bank PBL',
        'order': 24,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/85.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Millennium Bank PBL'}, {
          'languageValue': 'SK',
          'name': 'Millennium Bank PBL'
        }, {'languageValue': 'FR', 'name': 'Millennium Bank PBL'}, {
          'languageValue': 'DE',
          'name': 'Millennium Bank PBL'
        }, {'languageValue': 'IT', 'name': 'Millennium Bank PBL'}, {
          'languageValue': 'EN',
          'name': 'Millennium Bank PBL'
        }, {'languageValue': 'RO', 'name': 'Millennium Bank PBL'}, {
          'languageValue': 'SL',
          'name': 'Millennium Bank PBL'
        }, {'languageValue': 'EL', 'name': 'Millennium Bank PBL'}, {
          'languageValue': 'PL',
          'name': 'Millennium Bank PBL'
        }, {'languageValue': 'HU', 'name': 'Millennium Bank PBL'}, {
          'languageValue': 'UK',
          'name': 'Millennium Bank PBL'
        }, {'languageValue': 'ES', 'name': 'Millennium Bank PBL'}, {
          'languageValue': 'HR',
          'name': 'Millennium Bank PBL'
        }, {'languageValue': 'TR', 'name': 'Millennium Bank PBL'}],
        'redirectToCards': false
      },
      {
        'id': 95,
        'name': 'Alior Bank PBL',
        'type': 'PBL',
        'nameOnList': 'Płacę z Alior Bankiem',
        'order': 27,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/95.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Platím s Alior Bank'}, {
          'languageValue': 'SK',
          'name': 'Platím cez Alior Bank'
        }, {'languageValue': 'FR', 'name': 'Je paye depuis Alior Bank'}, {
          'languageValue': 'DE',
          'name': 'Ich bezahle mit Alior Bank'
        }, {'languageValue': 'IT', 'name': 'Pago con Alior Bank'}, {
          'languageValue': 'EN',
          'name': 'I pay with Alior Bank'
        }, {'languageValue': 'RO', 'name': 'Plătesc cu Alior Bank'}, {
          'languageValue': 'SL',
          'name': 'Plačam z Alior Bankiem'
        }, {'languageValue': 'EL', 'name': 'Πληρώνω με την Alior Bank'}, {
          'languageValue': 'PL',
          'name': 'Płacę z Alior Bankiem'
        }, {'languageValue': 'HU', 'name': 'Fizetk Alior Bank-ból'}, {
          'languageValue': 'UK',
          'name': 'Сплачую з Alior Bank'
        }, {'languageValue': 'ES', 'name': 'Pago con Alior Bank'}, {
          'languageValue': 'HR',
          'name': 'Plaćam putem Alior Bank'
        }, {'languageValue': 'TR', 'name': 'Alior Bank ile ödüyorum'}],
        'redirectToCards': false
      },
      {
        'id': 59,
        'name': 'Credit Agricole PBL',
        'type': 'PBL',
        'nameOnList': 'CA przelew online',
        'order': 30,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/59.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'CA przelew online'}, {
          'languageValue': 'SK',
          'name': 'Online prevod CA'
        }, {'languageValue': 'FR', 'name': 'CA przelew online'}, {
          'languageValue': 'DE',
          'name': 'CA przelew online'
        }, {'languageValue': 'IT', 'name': 'CA przelew online'}, {
          'languageValue': 'EN',
          'name': 'CA przelew online'
        }, {'languageValue': 'RO', 'name': 'Transfer online CA.'}, {
          'languageValue': 'SL',
          'name': 'CA spletno nakazilo'
        }, {'languageValue': 'EL', 'name': 'CA online μεταφορά'}, {
          'languageValue': 'PL',
          'name': 'CA przelew online'
        }, {'languageValue': 'HU', 'name': 'CA átutalás online'}, {
          'languageValue': 'UK',
          'name': 'CA онлайн-платіж'
        }, {'languageValue': 'ES', 'name': 'CA przelew online'}, {
          'languageValue': 'HR',
          'name': 'CA Prijenos online'
        }, {'languageValue': 'TR', 'name': 'CA çevrimiçi transfer'}],
        'redirectToCards': false
      },
      {
        'id': 1064,
        'name': 'IGO PBL',
        'type': 'PBL',
        'nameOnList': 'Płacę z Inteligo',
        'order': 34,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/1064.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Platím s Inteligo'}, {
          'languageValue': 'SK',
          'name': 'Platím cez Inteligo'
        }, {'languageValue': 'FR', 'name': 'Je paye depuis Inteligo'}, {
          'languageValue': 'DE',
          'name': 'Ich bezahle mit Inteligo'
        }, {'languageValue': 'IT', 'name': 'Pago con Inteligo'}, {
          'languageValue': 'EN',
          'name': 'I pay with Inteligo'
        }, {'languageValue': 'RO', 'name': 'Plătesc cu Inteligo'}, {
          'languageValue': 'SL',
          'name': 'Plačam z Inteligo'
        }, {'languageValue': 'EL', 'name': 'Πληρώνω με την Inteligo'}, {
          'languageValue': 'PL',
          'name': 'Płacę z Inteligo'
        }, {'languageValue': 'HU', 'name': 'Fizetek Inteligo-ból'}, {
          'languageValue': 'UK',
          'name': 'Сплачую з Inteligo'
        }, {'languageValue': 'ES', 'name': 'Pago con Inteligo'}, {
          'languageValue': 'HR',
          'name': 'Plaćam putem Inteligo'
        }, {'languageValue': 'TR', 'name': 'Inteligo ile ödüyorum'}],
        'redirectToCards': false
      },
      {
        'id': 1035,
        'name': 'BNP Paribas',
        'type': 'PBL',
        'nameOnList': 'BNP Paribas - płacę z Pl@net',
        'order': 36,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/1035.png',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'BNP Paribas'}, {
          'languageValue': 'SK',
          'name': 'BNP Paribas - platím cez Pl@net'
        }, {'languageValue': 'FR', 'name': 'BNP Paribas'}, {
          'languageValue': 'DE',
          'name': 'BNP Paribas'
        }, {'languageValue': 'IT', 'name': 'BNP Paribas'}, {
          'languageValue': 'EN',
          'name': 'BNP Paribas'
        }, {'languageValue': 'RO', 'name': 'BNP Paribas - Plătesc cu Pl @ net'}, {
          'languageValue': 'SL',
          'name': 'BNP Paribas - Plačam z Pl@net'
        }, {'languageValue': 'EL', 'name': 'BNP Paribas - Πληρώνω με Pl @ net'}, {
          'languageValue': 'PL',
          'name': 'BNP Paribas - płacę z Pl@net'
        }, {'languageValue': 'HU', 'name': 'BNP Paribas - fizetek Pl@net-ből'}, {
          'languageValue': 'UK',
          'name': 'BNP Paribas – сплачую з Pl@net'
        }, {'languageValue': 'ES', 'name': 'BNP Paribas'}, {
          'languageValue': 'HR',
          'name': 'BNP Paribas - Plaćam putem Pl@net'
        }, {'languageValue': 'TR', 'name': 'BNP Paribas - Pl@net ile ödüyorum'}],
        'redirectToCards': false
      },
      {
        'id': 513,
        'name': 'Getin Bank',
        'type': 'PBL',
        'nameOnList': 'Getin Bank',
        'order': 38,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/513.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Getin Bank'}, {
          'languageValue': 'SK',
          'name': 'Getin Bank'
        }, {'languageValue': 'FR', 'name': 'Getin Bank'}, {
          'languageValue': 'DE',
          'name': 'Getin Bank'
        }, {'languageValue': 'IT', 'name': 'Getin Bank'}, {
          'languageValue': 'EN',
          'name': 'Getin Bank'
        }, {'languageValue': 'RO', 'name': 'Getin Bank'}, {
          'languageValue': 'SL',
          'name': 'Getin Bank'
        }, {'languageValue': 'EL', 'name': 'Getin Bank'}, {
          'languageValue': 'PL',
          'name': 'Getin Bank'
        }, {'languageValue': 'HU', 'name': 'Getin Bank'}, {
          'languageValue': 'UK',
          'name': 'Getin Bank'
        }, {'languageValue': 'ES', 'name': 'Getin Bank'}, {
          'languageValue': 'HR',
          'name': 'Getin Bank'
        }, {'languageValue': 'TR', 'name': 'Getin Bank'}],
        'redirectToCards': false
      },
      {
        'id': 90,
        'name': 'Citi Bank PBL',
        'type': 'PBL',
        'nameOnList': 'Płacę z Citi Handlowy',
        'order': 42,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/90.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Platím s Citi Handlowy'}, {
          'languageValue': 'SK',
          'name': 'Platím cez Citi Handlowy'
        }, {'languageValue': 'FR', 'name': 'Je paye depuis Citi Handlowy'}, {
          'languageValue': 'DE',
          'name': 'Ich bezahle mit Citi Handlowy'
        }, {'languageValue': 'IT', 'name': 'Pago con Citi Handlowy'}, {
          'languageValue': 'EN',
          'name': 'I pay with Citi Handlowy'
        }, {'languageValue': 'RO', 'name': 'Plătesc cu Citi Handlowy'}, {
          'languageValue': 'SL',
          'name': 'Plačam z Citi Handlowy'
        }, {'languageValue': 'EL', 'name': 'Πληρώνω με Citi Handlowy'}, {
          'languageValue': 'PL',
          'name': 'Płacę z Citi Handlowy'
        }, {'languageValue': 'HU', 'name': 'Fizetek Citi Kereskedelmi Bank-ból'}, {
          'languageValue': 'UK',
          'name': 'Сплачую з Citi Handlowy'
        }, {'languageValue': 'ES', 'name': 'Pago con Citi Handlowy'}, {
          'languageValue': 'HR',
          'name': 'Plaćam putem Citi Handlowy'
        }, {'languageValue': 'TR', 'name': 'Citi Handlowy ile ödüyorum'}],
        'redirectToCards': false
      },
      {
        'id': 108,
        'name': 'Pocztowy PBL',
        'type': 'PBL',
        'nameOnList': 'Pocztowy24',
        'order': 44,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/108.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Pocztowy24'}, {
          'languageValue': 'SK',
          'name': 'Pocztowy24'
        }, {'languageValue': 'FR', 'name': 'Pocztowy24'}, {
          'languageValue': 'DE',
          'name': 'Pocztowy24'
        }, {'languageValue': 'IT', 'name': 'Pocztowy24'}, {
          'languageValue': 'EN',
          'name': 'Pocztowy24'
        }, {'languageValue': 'RO', 'name': 'Pocztowy24'}, {
          'languageValue': 'SL',
          'name': 'Pocztowy24'
        }, {
          'languageValue': 'EL',
          'name': 'Pocztowy24 (αφήνουμε στα πολωνικά, γιατί είναι η ονομασία της εταιρίας- zostawiamy taj, bo to nazwa własna)'
        }, {'languageValue': 'PL', 'name': 'Pocztowy24'}, {
          'languageValue': 'HU',
          'name': 'Posta24'
        }, {'languageValue': 'UK', 'name': 'Pocztowy24'}, {
          'languageValue': 'ES',
          'name': 'Pocztowy24'
        }, {'languageValue': 'HR', 'name': 'Pocztowy24'}, {'languageValue': 'TR', 'name': 'Pocztowy24'}],
        'redirectToCards': false
      },
      {
        'id': 517,
        'name': 'Nest Bank PBL',
        'type': 'PBL',
        'nameOnList': 'NestPrzelew',
        'order': 45,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/517.png',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'NestPrzelew'}, {
          'languageValue': 'SK',
          'name': 'NestPrzelew'
        }, {'languageValue': 'FR', 'name': 'NestPrzelew'}, {
          'languageValue': 'DE',
          'name': 'NestPrzelew'
        }, {'languageValue': 'IT', 'name': 'NestPrzelew'}, {
          'languageValue': 'EN',
          'name': 'NestPrzelew'
        }, {'languageValue': 'RO', 'name': 'Transfer Nest'}, {
          'languageValue': 'SL',
          'name': 'NestPrzelew'
        }, {'languageValue': 'EL', 'name': 'Nest Transfer'}, {
          'languageValue': 'PL',
          'name': 'NestPrzelew'
        }, {'languageValue': 'HU', 'name': 'NestÁtutalás'}, {
          'languageValue': 'UK',
          'name': 'NestPrzelew'
        }, {'languageValue': 'ES', 'name': 'NestPrzelew'}, {
          'languageValue': 'HR',
          'name': 'NestPijenos'
        }, {'languageValue': 'TR', 'name': 'NestPrzelew'}],
        'redirectToCards': false
      },
      {
        'id': 131,
        'name': 'Plus Bank PBL',
        'type': 'PBL',
        'nameOnList': 'Płacę z PLUS BANK',
        'order': 46,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/131.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Platím s PLUS BANK'}, {
          'languageValue': 'SK',
          'name': 'Platím cez PLUS BANK'
        }, {'languageValue': 'FR', 'name': 'Je paye depuis PLUS BANK'}, {
          'languageValue': 'DE',
          'name': 'Ich bezahle mit PLUS BANK'
        }, {'languageValue': 'IT', 'name': 'Pago con PLUS BANK'}, {
          'languageValue': 'EN',
          'name': 'I pay with PLUS BANK'
        }, {'languageValue': 'RO', 'name': 'Plătesc cu PLUS BANK'}, {
          'languageValue': 'SL',
          'name': 'Plačam s PLUS BANK'
        }, {'languageValue': 'EL', 'name': 'Πληρώνω με την PLUS BANK'}, {
          'languageValue': 'PL',
          'name': 'Płacę z PLUS BANK'
        }, {'languageValue': 'HU', 'name': 'Fizetek PLUS BANK-ból'}, {
          'languageValue': 'UK',
          'name': 'Сплачую з PLUS BANK'
        }, {'languageValue': 'ES', 'name': 'Pago con PLUS BANK'}, {
          'languageValue': 'HR',
          'name': 'Plaćam putem PLUS BANK'
        }, {'languageValue': 'TR', 'name': 'PLUS BANK ile ödüyorum'}],
        'redirectToCards': false
      },
      {
        'id': 86,
        'name': 'BOS PBL',
        'type': 'PBL',
        'nameOnList': 'Płać z BOŚ Bank',
        'order': 47,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/86.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Plaťte z BOŚ Bank'}, {
          'languageValue': 'SK',
          'name': 'Platba cez BOŚ Bank'
        }, {'languageValue': 'FR', 'name': 'Payez depuis BOŚ Bank'}, {
          'languageValue': 'DE',
          'name': 'Mit BOŚ Bank bezahlen'
        }, {'languageValue': 'IT', 'name': 'Paga con BOŚ Bank'}, {
          'languageValue': 'EN',
          'name': 'Pay with BOŚ Bank'
        }, {'languageValue': 'RO', 'name': 'Plătiți cu BOŚ Bank'}, {
          'languageValue': 'SL',
          'name': 'Plačajte z BOŚ Bank'
        }, {'languageValue': 'EL', 'name': 'Πληρώστε με BOŚ Bank'}, {
          'languageValue': 'PL',
          'name': 'Płać z BOŚ Bank'
        }, {'languageValue': 'HU', 'name': 'Fizessen BOŚ Bank-ból'}, {
          'languageValue': 'UK',
          'name': 'Сплачую з BOŚ Bank'
        }, {'languageValue': 'ES', 'name': 'Paga con BOŚ Bank'}, {
          'languageValue': 'HR',
          'name': 'Platite putem BOŚ Bank'
        }, {'languageValue': 'TR', 'name': 'BOŚ Bank ile öde'}],
        'redirectToCards': false
      },
      {
        'id': 98,
        'name': 'PBS PBL',
        'type': 'PBL',
        'nameOnList': 'PBS Bank - przelew 24',
        'order': 48,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/98.gif',
        'recurring': false,
        'active': false,
        'languageData': [{'languageValue': 'CS', 'name': 'PBS Bank - převody 24'}, {
          'languageValue': 'SK',
          'name': 'PBS Bank - prevod 24'
        }, {'languageValue': 'FR', 'name': 'PBS Bank - virement 24'}, {
          'languageValue': 'DE',
          'name': 'PBS Bank - Überweisung 24h '
        }, {'languageValue': 'IT', 'name': 'PBS Bank - bonifico 24'}, {
          'languageValue': 'EN',
          'name': 'PBS Bank – 24 transfer'
        }, {'languageValue': 'RO', 'name': 'PBS Bank - transfer bancar 24'}, {
          'languageValue': 'SL',
          'name': 'PBS Bank - nakazilo 24'
        }, {'languageValue': 'EL', 'name': 'PBS Bank - μεταφορά 24'}, {
          'languageValue': 'PL',
          'name': 'PBS Bank - przelew 24'
        }, {'languageValue': 'HU', 'name': 'PBS Bank - átutalás 24'}, {
          'languageValue': 'UK',
          'name': 'PBS Bank – переказ 24'
        }, {'languageValue': 'ES', 'name': 'PBS Bank - transferencias 24'}, {
          'languageValue': 'HR',
          'name': 'PBS Bank - Przelew 24'
        }, {'languageValue': 'TR', 'name': 'PBS Bank - przelew 24'}],
        'redirectToCards': false
      },
      {
        'id': 117,
        'name': 'Toyota PBL',
        'type': 'PBL',
        'nameOnList': 'Toyota Bank Pay Way',
        'order': 49,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/117.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Toyota Bank Pay Way'}, {
          'languageValue': 'SK',
          'name': 'Toyota Bank Pay Way'
        }, {'languageValue': 'FR', 'name': 'Toyota Bank Pay Way'}, {
          'languageValue': 'DE',
          'name': 'Toyota Bank Pay Way'
        }, {'languageValue': 'IT', 'name': 'Toyota Bank Pay Way'}, {
          'languageValue': 'EN',
          'name': 'Toyota Bank Pay Way'
        }, {'languageValue': 'RO', 'name': 'Toyota Bank Pay Way'}, {
          'languageValue': 'SL',
          'name': 'Toyota Bank Pay Way'
        }, {'languageValue': 'EL', 'name': 'Τρόπος πληρωμής της Toyota Bank'}, {
          'languageValue': 'PL',
          'name': 'Toyota Bank Pay Way'
        }, {'languageValue': 'HU', 'name': 'Toyota Bank Pay Way'}, {
          'languageValue': 'UK',
          'name': 'Toyota Bank Pay Way'
        }, {'languageValue': 'ES', 'name': 'Toyota Bank Pay Way'}, {
          'languageValue': 'HR',
          'name': 'Toyota Bank Pay Way'
        }, {'languageValue': 'TR', 'name': 'Toyota Bank Pay Way'}],
        'redirectToCards': false
      },
      {
        'id': 514,
        'name': 'Noble Bank',
        'type': 'PBL',
        'nameOnList': 'Noble Bank',
        'order': 51,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/514.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Noble Bank'}, {
          'languageValue': 'SK',
          'name': 'Noble Bank'
        }, {'languageValue': 'FR', 'name': 'Noble Bank'}, {
          'languageValue': 'DE',
          'name': 'Noble Bank'
        }, {'languageValue': 'IT', 'name': 'Noble Bank'}, {
          'languageValue': 'EN',
          'name': 'Noble Bank'
        }, {'languageValue': 'RO', 'name': 'Noble Bank'}, {
          'languageValue': 'SL',
          'name': 'Noble Bank'
        }, {'languageValue': 'EL', 'name': 'Ευγενής Τράπεζα'}, {
          'languageValue': 'PL',
          'name': 'Noble Bank'
        }, {'languageValue': 'HU', 'name': 'Noble Bank'}, {
          'languageValue': 'UK',
          'name': 'Noble Bank'
        }, {'languageValue': 'ES', 'name': 'Noble Bank'}, {
          'languageValue': 'HR',
          'name': 'Noble Bank'
        }, {'languageValue': 'TR', 'name': 'Noble Bank'}],
        'redirectToCards': false
      },
      {
        'id': 1507,
        'name': 'Bank Spółdzielczy w Sztumie PBL',
        'type': 'PBL',
        'nameOnList': 'Bank Spółdzielczy w Sztumie PBL',
        'order': 53,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/1507.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Bank Spółdzielczy w Sztumie PBL'}, {
          'languageValue': 'SK',
          'name': 'Družstevná banka v Sztumie PBL'
        }, {'languageValue': 'FR', 'name': 'Bank Spółdzielczy w Sztumie PBL'}, {
          'languageValue': 'DE',
          'name': 'Bank Spółdzielczy w Sztumie PBL'
        }, {'languageValue': 'IT', 'name': 'Bank Spółdzielczy w Sztumie PBL'}, {
          'languageValue': 'EN',
          'name': 'Bank Spółdzielczy w Sztumie PBL'
        }, {'languageValue': 'RO', 'name': 'Banca Cooperativă din Sztum PBL'}, {
          'languageValue': 'SL',
          'name': 'Zadružna banka v Sztumu PBL '
        }, {'languageValue': 'EL', 'name': 'Συνεταιριστική Τράπεζα στο Sztum PBL'}, {
          'languageValue': 'PL',
          'name': 'Bank Spółdzielczy w Sztumie PBL'
        }, {'languageValue': 'HU', 'name': 'Szövetkezeti Bank Sztumie PBL'}, {
          'languageValue': 'UK',
          'name': 'Кооперативний банк у Штумі PBL'
        }, {'languageValue': 'ES', 'name': 'Bank Spółdzielczy w Sztumie PBL'}, {
          'languageValue': 'HR',
          'name': 'Bank Spółdzielczy w Sztumie PBL'
        }, {'languageValue': 'TR', 'name': 'Sztum Kooperatif Bankası PBL'}],
        'redirectToCards': false
      },
      {
        'id': 1510,
        'name': 'Bank Spółdzielczy Lututów PBL',
        'type': 'PBL',
        'nameOnList': 'Bank Spółdzielczy Lututów PBL',
        'order': 54,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/1510.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Bank Spółdzielczy Lututów PBL'}, {
          'languageValue': 'SK',
          'name': 'Družstevná banka Lututów PBL'
        }, {'languageValue': 'FR', 'name': 'Bank Spółdzielczy Lututów PBL'}, {
          'languageValue': 'DE',
          'name': 'Bank Spółdzielczy Lututów PBL'
        }, {'languageValue': 'IT', 'name': 'Bank Spółdzielczy Lututów PBL'}, {
          'languageValue': 'EN',
          'name': 'Bank Spółdzielczy Lututów PBL'
        }, {'languageValue': 'RO', 'name': 'Banca Cooperativă Lututów PBL'}, {
          'languageValue': 'SL',
          'name': 'Zadružna banka Lututów PBL'
        }, {'languageValue': 'EL', 'name': 'Bank Spółdzielczy Lututów PBL'}, {
          'languageValue': 'PL',
          'name': 'Bank Spółdzielczy Lututów PBL'
        }, {'languageValue': 'HU', 'name': 'Szövetkezeti Bank Lututów PBL'}, {
          'languageValue': 'UK',
          'name': 'Кооперативний банк Lututów PBL'
        }, {'languageValue': 'ES', 'name': 'Bank Spółdzielczy Lututów PBL'}, {
          'languageValue': 'HR',
          'name': 'Bank Spółdzielczy Lututów PBL'
        }, {'languageValue': 'TR', 'name': 'Lututów Kooperatif Bankası PBL'}],
        'redirectToCards': false
      },
      {
        'id': 1515,
        'name': 'Bank Spółdzielczy w Toruniu PBL',
        'type': 'PBL',
        'nameOnList': 'Bank Spółdzielczy w Toruniu PBL',
        'order': 55,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/1515.png',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Bank Spółdzielczy w Toruniu PBL'}, {
          'languageValue': 'SK',
          'name': 'Družstevná banka v Toruni PBL'
        }, {'languageValue': 'FR', 'name': 'Bank Spółdzielczy w Toruniu PBL'}, {
          'languageValue': 'DE',
          'name': 'Bank Spółdzielczy w Toruniu PBL'
        }, {'languageValue': 'IT', 'name': 'Bank Spółdzielczy w Toruniu PBL'}, {
          'languageValue': 'EN',
          'name': 'Bank Spółdzielczy w Toruniu PBL'
        }, {'languageValue': 'RO', 'name': 'Banca Cooperativă din Toruń PBL'}, {
          'languageValue': 'SL',
          'name': 'Zadružna banka v Torunu PBL'
        }, {'languageValue': 'EL', 'name': 'Συνεταιριστική Τράπεζα στο Toruń PBL'}, {
          'languageValue': 'PL',
          'name': 'Bank Spółdzielczy w Toruniu PBL'
        }, {'languageValue': 'HU', 'name': 'Szövetkezeti Bank Toruniu PBL'}, {
          'languageValue': 'UK',
          'name': 'Кооперативний банк у Торіні PBL'
        }, {'languageValue': 'ES', 'name': 'Bank Spółdzielczy w Toruniu PBL'}, {
          'languageValue': 'HR',
          'name': 'Bank Spółdzielczy u Toruniu PBL'
        }, {'languageValue': 'TR', 'name': 'Torun Koopearatif Bankası PBL'}],
        'redirectToCards': false
      },
      {
        'id': 1520,
        'name': 'Bank Spółdzielczy Biała Rawska PBL',
        'type': 'PBL',
        'nameOnList': 'Bank Spółdzielczy Biała Rawska',
        'order': 58,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/1520.png',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Bank Spółdzielczy Biała Rawska'}, {
          'languageValue': 'SK',
          'name': 'Družstevná banka Biała Rawska'
        }, {'languageValue': 'FR', 'name': 'Bank Spółdzielczy Biała Rawska'}, {
          'languageValue': 'DE',
          'name': 'Bank Spółdzielczy Biała Rawska'
        }, {'languageValue': 'IT', 'name': 'Bank Spółdzielczy Biała Rawska'}, {
          'languageValue': 'EN',
          'name': 'Bank Spółdzielczy Biała Rawska'
        }, {'languageValue': 'RO', 'name': 'Banca Cooperativă Biała Rawska'}, {
          'languageValue': 'SL',
          'name': 'Zadružna banka Biała Rawska'
        }, {'languageValue': 'EL', 'name': 'Συνεταιριστική Τράπεζα Biała Rawska'}, {
          'languageValue': 'PL',
          'name': 'Bank Spółdzielczy Biała Rawska'
        }, {'languageValue': 'HU', 'name': 'Biała Rawska Szövetkezeti Bank'}, {
          'languageValue': 'UK',
          'name': 'Кооперативний банк Biała Rawska'
        }, {'languageValue': 'ES', 'name': 'Bank Spółdzielczy Biała Rawska'}, {
          'languageValue': 'HR',
          'name': 'Bank Spółdzielczy Biała Rawska'
        }, {'languageValue': 'TR', 'name': 'Biała Rawska Kooperatif Bankası'}],
        'redirectToCards': false
      },
      {
        'id': 9,
        'name': 'transfer',
        'type': 'Szybki Przelew',
        'nameOnList': 'Mam konto w innym banku',
        'order': 93,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/9.gif',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Mám účet  jiné bance'}, {
          'languageValue': 'SK',
          'name': 'Mám účet v inej banke'
        }, {'languageValue': 'FR', 'name': "J'ai un compte dans une autre banque"}, {
          'languageValue': 'DE',
          'name': 'Ich habe ein Konto bei einer anderen Bank'
        }, {'languageValue': 'IT', 'name': "Ho un conto bancario presso un'altra banca"}, {
          'languageValue': 'EN',
          'name': 'I have an account in another bank'
        }, {'languageValue': 'RO', 'name': 'Am un cont la o altă bancă'}, {
          'languageValue': 'SL',
          'name': 'Imam račun pri drugi banki '
        }, {'languageValue': 'EL', 'name': 'Έχω λογαριασμό σε άλλη τράπεζα'}, {
          'languageValue': 'PL',
          'name': 'Mam konto w innym banku'
        }, {'languageValue': 'HU', 'name': 'Van számlám másik bankban'}, {
          'languageValue': 'UK',
          'name': 'У мене є рахунок в іншому банку'
        }, {'languageValue': 'ES', 'name': 'Tengo cuenta en otro banco'}, {
          'languageValue': 'HR',
          'name': 'Imam račun u drugoj banci'
        }, {'languageValue': 'TR', 'name': 'Başka bankada hesabım var'}],
        'redirectToCards': false
      },
      {
        'id': 1506,
        'name': 'Alior Raty PBI',
        'type': 'Raty online',
        'nameOnList': 'Alior Raty',
        'order': 96,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/1506.jpg',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Alior Raty'}, {
          'languageValue': 'SK',
          'name': 'Splátky Alior'
        }, {'languageValue': 'FR', 'name': 'Alior Raty'}, {
          'languageValue': 'DE',
          'name': 'Alior Raty'
        }, {'languageValue': 'IT', 'name': 'Alior Raty'}, {
          'languageValue': 'EN',
          'name': 'Alior Raty'
        }, {'languageValue': 'RO', 'name': 'Rate Alior'}, {
          'languageValue': 'SL',
          'name': 'Alior Obroke'
        }, {'languageValue': 'EL', 'name': 'Άλιορ Ράτι'}, {
          'languageValue': 'PL',
          'name': 'Alior Raty'
        }, {'languageValue': 'HU', 'name': 'Alior Raty'}, {
          'languageValue': 'UK',
          'name': 'Alior Raty'
        }, {'languageValue': 'ES', 'name': 'Alior Raty'}, {
          'languageValue': 'HR',
          'name': 'Alior Rate'
        }, {'languageValue': 'TR', 'name': 'Alior Taksitlendirme'}],
        'redirectToCards': false
      },
      {
        'id': 1519,
        'name': 'Krakowski Bank Spółdzielczy PBL',
        'type': 'PBL',
        'nameOnList': 'Krakowski Bank Spółdzielczy PBL',
        'order': 100,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/1519.png',
        'recurring': false,
        'active': true,
        'languageData': [{'languageValue': 'CS', 'name': 'Krakowski Bank Spółdzielczy PBL'}, {
          'languageValue': 'SK',
          'name': 'Krakovská družstevná banka PBL'
        }, {'languageValue': 'FR', 'name': 'Krakowski Bank Spółdzielczy PBL'}, {
          'languageValue': 'DE',
          'name': 'Krakowski Bank Spółdzielczy PBL'
        }, {'languageValue': 'IT', 'name': 'Krakowski Bank Spółdzielczy PBL'}, {
          'languageValue': 'EN',
          'name': 'Krakowski Bank Spółdzielczy PBL'
        }, {'languageValue': 'RO', 'name': 'Banca Cooperativă Krakowski PBL'}, {
          'languageValue': 'SL',
          'name': 'Krakowski Bank Spółdzielczy PBL'
        }, {'languageValue': 'EL', 'name': 'Krakowski Bank Spółdzielczy PBL'}, {
          'languageValue': 'PL',
          'name': 'Krakowski Bank Spółdzielczy PBL'
        }, {'languageValue': 'HU', 'name': 'Krakkói Szövetkezeti Bank PBL'}, {
          'languageValue': 'UK',
          'name': 'Krakowski Bank Spółdzielczy PBL'
        }, {'languageValue': 'ES', 'name': 'Krakowski Bank Spółdzielczy PBL'}, {
          'languageValue': 'HR',
          'name': 'Krakowski Bank Spółdzielczy PBL'
        }, {'languageValue': 'TR', 'name': 'Krakow Kooperatif Bankası PBL'}],
        'redirectToCards': false
      },
      {
        'id': 1522,
        'name': 'Bank Spółdzielczy Wieliczka PBL',
        'type': 'PBL',
        'nameOnList': 'Bank Spółdzielczy Wieliczka PBL',
        'order': 103,
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/1522.png',
        'recurring': false,
        'active': true,
        'languageData': [],
        'redirectToCards': false
      },
      {
        'id': 705,
        'name': 'PayPo',
        'type': 'PBL',
        'nameOnList': 'PayPo',
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/705.png',
        'recurring': false,
        'active': true,
        'languageData': [],
        'redirectToCards': false
      },
      {
        'id': 706,
        'name': 'Faktoria F-pay',
        'type': 'PG',
        'nameOnList': 'F-pay',
        'iconUrl': 'https://images.autopay.eu/pomoc/grafika/703.png',
        'recurring': false,
        'active': true,
        'languageData': [],
        'redirectToCards': false
      },
      {
        "id": 523,
        "name": "BLIK Płacę Później",
        "type": "PBL",
        "nameOnList": "BLIK Płacę Później",
        "order": 8,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/523.gif",
        "recurring": false,
        "active": true,
        "languageData": [],
        "redirectToCards": false
      }],
    'containsPisPayway': false,
    'transRemoteID': 'AF7S4EY8DF',
    'startAmount': 500.00,
    'startAmountCurrency': 'PLN',
    'payAmount': 500.00,
    'payAmountCurrency': 'PLN',
    'customerFee': 0.00,
    'transactionValidityTime': '2022-10-07T08:44:57',
    'additionalData': {'orderId': '20221004084457', 'customerEmail': 'devnull@bm.pl'},
    'acceptorID': 100004,
    'serviceID': 100004,
    'serviceType': 'ECOMMERCE',
    'shortName': 'Testowy sklep ecommerce',
    'receiverNameForFront': 'Testowy sklep ecommerce',
    'serviceUrl': 'https://test.ecommerce.blue.pl',
    'returnUrl': 'http://test.ecommerce.blue.pl/returnUrl?ServiceID=100004&OrderID=20221004084457&Hash=b0f82a1ca8c50a3a547e175e2002ef5328b3c9edb66cdb5f741994e46857f55d',
    'regulations': [{
      'regulationId': 30480,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=609fac6440b1d338de24ac34cd482292&Type=DEFAULT&Language=FR&Hash=1995a54fed9729344911fff2739b6434156992331d5c3aa9430773309638c568',
      'type': 'DEFAULT',
      'language': 'FR',
      'checkedAndHidden': false
    }, {
      'regulationId': 31516,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=91b0233f1b965c9c43da158cc0eb3eee&Type=DEFAULT&Language=DE&Hash=c46bb543025a62b40ebeaf916cc045248861beac81a75f56c6e4b017c822c93c',
      'type': 'DEFAULT',
      'language': 'DE',
      'checkedAndHidden': false
    }, {
      'regulationId': 32544,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=798763727613a814e6c21d6c253c577e&Type=DEFAULT&Language=CS&Hash=932e83e3503ee574f727bbb46ddfee77ac9e5f88c5646145dd6bb93ac96a2bdd',
      'type': 'DEFAULT',
      'language': 'CS',
      'checkedAndHidden': false
    }, {
      'regulationId': 33572,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=b1f846e0a8370b87cf435ddad682ea50&Type=DEFAULT&Language=IT&Hash=449636919526a6b33eb386ebd86b22a9fa0010e1c33f692f00eef49411211410',
      'type': 'DEFAULT',
      'language': 'IT',
      'checkedAndHidden': false
    }, {
      'regulationId': 36991,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=fe88a492f088ac1ccafd67aa8d5c5382&Type=DEFAULT&Language=PL&Hash=46b2d374a486c0c3c1818e56a4b32885d06b20fdd7b6205f23701be8a3510df5',
      'type': 'DEFAULT',
      'language': 'PL',
      'checkedAndHidden': false
    }, {
      'regulationId': 36991,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=fe88a492f088ac1ccafd67aa8d5c5382&Type=DEFAULT&Language=PL&Hash=46b2d374a486c0c3c1818e56a4b32885d06b20fdd7b6205f23701be8a3510df5',
      'type': 'DEFAULT',
      'language': 'SK',
      'checkedAndHidden': false
    }, {
      'regulationId': 36991,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=fe88a492f088ac1ccafd67aa8d5c5382&Type=DEFAULT&Language=PL&Hash=46b2d374a486c0c3c1818e56a4b32885d06b20fdd7b6205f23701be8a3510df5',
      'type': 'DEFAULT',
      'language': 'EN',
      'checkedAndHidden': false
    }, {
      'regulationId': 36991,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=fe88a492f088ac1ccafd67aa8d5c5382&Type=DEFAULT&Language=PL&Hash=46b2d374a486c0c3c1818e56a4b32885d06b20fdd7b6205f23701be8a3510df5',
      'type': 'DEFAULT',
      'language': 'RO',
      'checkedAndHidden': false
    }, {
      'regulationId': 36991,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=fe88a492f088ac1ccafd67aa8d5c5382&Type=DEFAULT&Language=PL&Hash=46b2d374a486c0c3c1818e56a4b32885d06b20fdd7b6205f23701be8a3510df5',
      'type': 'DEFAULT',
      'language': 'SL',
      'checkedAndHidden': false
    }, {
      'regulationId': 36991,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=fe88a492f088ac1ccafd67aa8d5c5382&Type=DEFAULT&Language=PL&Hash=46b2d374a486c0c3c1818e56a4b32885d06b20fdd7b6205f23701be8a3510df5',
      'type': 'DEFAULT',
      'language': 'EL',
      'checkedAndHidden': false
    }, {
      'regulationId': 36991,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=fe88a492f088ac1ccafd67aa8d5c5382&Type=DEFAULT&Language=PL&Hash=46b2d374a486c0c3c1818e56a4b32885d06b20fdd7b6205f23701be8a3510df5',
      'type': 'DEFAULT',
      'language': 'HU',
      'checkedAndHidden': false
    }, {
      'regulationId': 36991,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=fe88a492f088ac1ccafd67aa8d5c5382&Type=DEFAULT&Language=PL&Hash=46b2d374a486c0c3c1818e56a4b32885d06b20fdd7b6205f23701be8a3510df5',
      'type': 'DEFAULT',
      'language': 'UK',
      'checkedAndHidden': false
    }, {
      'regulationId': 36991,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=fe88a492f088ac1ccafd67aa8d5c5382&Type=DEFAULT&Language=PL&Hash=46b2d374a486c0c3c1818e56a4b32885d06b20fdd7b6205f23701be8a3510df5',
      'type': 'DEFAULT',
      'language': 'ES',
      'checkedAndHidden': false
    }, {
      'regulationId': 36991,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=fe88a492f088ac1ccafd67aa8d5c5382&Type=DEFAULT&Language=PL&Hash=46b2d374a486c0c3c1818e56a4b32885d06b20fdd7b6205f23701be8a3510df5',
      'type': 'DEFAULT',
      'language': 'HR',
      'checkedAndHidden': false
    }, {
      'regulationId': 36991,
      'url': 'https://pay.bm.pl/webapi/regulation?ServiceID=100004&MessageID=fe88a492f088ac1ccafd67aa8d5c5382&Type=DEFAULT&Language=PL&Hash=46b2d374a486c0c3c1818e56a4b32885d06b20fdd7b6205f23701be8a3510df5',
      'type': 'DEFAULT',
      'language': 'TR',
      'checkedAndHidden': false
    }],
    'clientSource': 'payways',
    'languageData': {
      'languagesAvailable': ['CS', 'SK', 'FR', 'DE', 'IT', 'EN', 'RO', 'SL', 'EL', 'PL', 'HU', 'UK', 'ES', 'HR', 'TR'],
      'languageChosen': 'PL'
    },
    'visibilityOptions': {
      'showFrontCountry': false,
      'showFrontEmail': true,
      'showFrontFees': false,
      'showFrontPartnerMarketing': 'OPTIONAL',
      'showFrontRegulations': false,
      'showFrontTradeMarketing': 'OPTIONAL',
      'showReturnToPaywaysButton': true,
      'showReturnToShopButton': true,
      'showFrontBasket': false
    },
    'sessionRemainingTime': 287,
    'isCardRecurringRegulationToShow': true
  }
}
export const PAYMENT_UPDATE = {
  status: 'SUCCESS',
  data: {
    containsPisPayway: false,
    transRemoteID: 'A9SNV6FBYT',
    startAmount: 1.00,
    startAmountCurrency: 'PLN',
    payAmount: 2.50,
    payAmountCurrency: 'PLN',
    customerFee: 1.50,
    transactionValidityTime: '2022-04-08T09:10:58',
    additionalData: {},
    acceptorID: 100067,
    serviceID: 100079,
    serviceType: 'MWF',
    shortName: 'TOYA',
    receiverNameForFront: 'Toya Sp z o.o.',
    serviceUrl: 'http://eboa.toya.net.pl/',
    returnUrl: 'https://toya.net.pl/platnosci/transactionPage?ServiceID=100079&OrderID=20220405091058&Hash=47df099223178c31b0ae785cdca1988dfd4a8acb450bcb6203d47e424f7777ed',
    regulations: [
      {
        regulationId: 79525,
        url: 'https://pay.bm.pl/webapi/regulation?ServiceID=100079&MessageID=43aeb9560246e64041c5001ada2e5f16&Type=DEFAULT&Language=PL&Hash=f5931d74209c99791976efab6939837a453ef542617fa1701ae9a710d26a996d',
        type: 'DEFAULT',
        language: 'PL',
        inputLabel: '<span><ul><li>Zapoznałem się i akceptuję <a href="https://pay.bm.pl/webapi/regulation?ServiceID=100079&MessageID=43aeb9560246e64041c5001ada2e5f16&Type=DEFAULT&Language=PL&Hash=f5931d74209c99791976efab6939837a453ef542617fa1701ae9a710d26a996d" target="_blank">Regulamin świadczenia usług płatniczych</a> oraz <a href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>',
        checkedAndHidden: false
      }],
    clientSource: 'psd2Data',
    psd2Data: {
      wtnSocketUrl: 'wss://wtn.bm.pl/wtn/api/socket/A9SNV6FBYT/1649142856680/58e72fd2-adcc-436e-b8dc-6abbaf96c8db/7cd75f11142617a9f30a05a0a8f7a8211546b022559a468ba98a379acae344a7',
      wtnRestUrl: 'https://wtn.bm.pl/wtn/api/rest/fallback/A9SNV6FBYT/1649142856680/58e72fd2-adcc-436e-b8dc-6abbaf96c8db/7cd75f11142617a9f30a05a0a8f7a8211546b022559a468ba98a379acae344a7',
      returnUrl: 'https://toya.net.pl/platnosci/transactionPage?ServiceID=100079&OrderID=20220405091058&Hash=47df099223178c31b0ae785cdca1988dfd4a8acb450bcb6203d47e424f7777ed',
      continueUrl: 'https://pay.bm.pl/web/toya/continue/A9SNV6FBYT/1A2I3RC2V',
      languageChosen: 'PL',
      timeout: 30,
      idtransremote: 'A9SNV6FBYT',
      hash: '530f16a833b224cf3ebf54dba68fda71fe257480b40aaef10aea14a981d25f09'
    },
    languageData: {languagesAvailable: ['PL'], languageChosen: 'PL'},
    visibilityOptions: {
      showFrontCountry: false,
      showFrontEmail: true,
      showFrontFees: true,
      showFrontPartnerMarketing: 'NONE',
      showFrontRegulations: true,
      showFrontTradeMarketing: 'NONE',
      showReturnToPaywaysButton: true,
      showReturnToShopButton: true
    },
    chosenPaywayId: 1807
  }
}
export const PAYMENT_UPDATE_VISA_MOBILE = {
  "status": "SUCCESS",
  "data": {
    "containsPisPayway": false,
    "transRemoteID": "AFULFGB7I5",
    "startAmount": 1.00,
    "startAmountCurrency": "PLN",
    "payAmount": 1.00,
    "payAmountCurrency": "PLN",
    "customerFee": 0.00,
    "transactionValidityTime": "2023-10-07T11:28:31",
    "additionalData": {
      "orderId": "20231004112831"
    },
    "acceptorID": 101940,
    "serviceID": 102837,
    "serviceType": "ECOMMERCE",
    "shortName": "Test nowych frontów",
    "receiverNameForFront": "testbp",
    "serviceUrl": "http://devnull.bm.pl",
    "returnUrl": "https://devnull.bm.pl?ServiceID=102837&OrderID=20231004112831&Hash=4a7f1004410db83e59a4a88ab11480e721878d9b7782a185130d3e4d9df46b7f",
    "regulations": [],
    "clientSource": "visaMobileData",
    "visaMobileData": {
      "orderId": "20231004112831",
      "serviceId": 102837,
      "hash": "4a7f1004410db83e59a4a88ab11480e721878d9b7782a185130d3e4d9df46b7f"
    },
    "languageData": {
      "languagesAvailable": [
        "SK",
        "ES",
        "PL",
        "EN",
        "DE",
        "CS",
        "HU",
        "FR",
        "UK",
        "IT",
        "RO",
        "TR"
      ],
      "languageChosen": "PL"
    },
    "visibilityOptions": {
      "showFrontCountry": false,
      "showFrontEmail": true,
      "showFrontFees": true,
      "showFrontPartnerMarketing": "OPTIONAL",
      "showFrontRegulations": false,
      "showFrontTradeMarketing": "OPTIONAL",
      "showReturnToPaywaysButton": false,
      "showReturnToShopButton": true,
      "showFrontBasket": false
    },
    "chosenPaywayId": 1523,
    "isCardRecurringRegulationToShow": false
  }
}
export const PAYMENT_UPDATE_FR = {
  "status": "SUCCESS", "data": {
    "containsPisPayway": false,
    "transRemoteID": "A1L58YPC8Q",
    "startAmount": 1.00,
    "startAmountCurrency": "PLN",
    "payAmount": 1.00,
    "payAmountCurrency": "PLN",
    "customerFee": 0.00,
    "transactionValidityTime": "2022-11-24T11:04:03",
    "additionalData": {
      "pdfHash": "8bbb9d52f5ce4cd9945e58eb69909f49",
      "orderId": "20221121110403",
      "idTransRemote": "A1L58YPC8Q",
      "continueHash": "U5QG1V1J"
    },
    "acceptorID": 101940,
    "serviceID": 102837,
    "serviceType": "ECOMMERCE",
    "shortName": "Test nowych frontów",
    "receiverNameForFront": "Test nowych frontów",
    "serviceUrl": "http://devnull.bm.pl",
    "returnUrl": "https://devnull.bm.pl?ServiceID=102837&OrderID=20221121110403&Hash=4117fa96995fcbf2a26916b371b7fbe955734b4189e8c80331bc05a2941cf758",
    "regulations": [{
      "regulationId": 10355,
      "url": "https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980",
      "type": "DEFAULT",
      "language": "PL",
      "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
      "checkedAndHidden": false
    }, {
      "regulationId": 10355,
      "url": "https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980",
      "type": "DEFAULT",
      "language": "SK",
      "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
      "checkedAndHidden": false
    }, {
      "regulationId": 10355,
      "url": "https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980",
      "type": "DEFAULT",
      "language": "UK",
      "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
      "checkedAndHidden": false
    }, {
      "regulationId": 10355,
      "url": "https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980",
      "type": "DEFAULT",
      "language": "IT",
      "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
      "checkedAndHidden": false
    }, {
      "regulationId": 10355,
      "url": "https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980",
      "type": "DEFAULT",
      "language": "FR",
      "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
      "checkedAndHidden": false
    }, {
      "regulationId": 10355,
      "url": "https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980",
      "type": "DEFAULT",
      "language": "RO",
      "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
      "checkedAndHidden": false
    }, {
      "regulationId": 10355,
      "url": "https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980",
      "type": "DEFAULT",
      "language": "ES",
      "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
      "checkedAndHidden": false
    }, {
      "regulationId": 10355,
      "url": "https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980",
      "type": "DEFAULT",
      "language": "CS",
      "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
      "checkedAndHidden": false
    }, {
      "regulationId": 10355,
      "url": "https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980",
      "type": "DEFAULT",
      "language": "EN",
      "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
      "checkedAndHidden": false
    }, {
      "regulationId": 10355,
      "url": "https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980",
      "type": "DEFAULT",
      "language": "DE",
      "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
      "checkedAndHidden": false
    }, {
      "regulationId": 10355,
      "url": "https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980",
      "type": "DEFAULT",
      "language": "HU",
      "inputLabel": "<span><ul><li>Zapoznałem się i akceptuję <a href=\"https://pay-accept.bm.pl/webapi/regulation?ServiceID=102837&MessageID=4d8b2d56b47b41623ef9b66ff9e19b3d&Type=DEFAULT&Language=PL&Hash=f983287434a1bdb091d3f9e85662f09067434e5aad046fa7438567d37ffee980\" target=\"_blank\">Regulamin świadczenia usług płatniczych</a> oraz <a href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li><li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul></span>",
      "checkedAndHidden": false
    }],
    "clientSource": "frData",
    "frData": {
      "url": {"name": "bankHref", "value": "https://www.centrum24.pl/centrum24-web/login"},
      "nrb": {"name": "receiverNRB", "value": "53109010980000000107285859"},
      "name": {"name": "receiverName", "value": "Autopay S.A."},
      "address": {"name": "receiverAddress", "value": "Haffnera 6, 81-717 Sopot"},
      "amount": {"name": "amount", "value": "1.00"},
      "currency": {"name": "currency", "value": "PLN"},
      "title": {"name": "title", "value": "A1L58YPC8Q test bramki"},
      "idTransRemote": {"name": "remoteID", "value": "A1L58YPC8Q"},
      "orderID": {"name": "orderID", "value": "20221121110403"},
      "bic": {"name": "BIC", "value": ""},
      "hash": {"name": "hash", "value": "96313045b4edc949108644b2dbb623662dc67162bcf1932114d8ea34d27d6152"},
      "nrbCurrency": {"name": "nrbCurrency", "value": "PLN"},
      "showReturnUrl": true,
      "showBankUrlOnFront": false
    },
    "languageData": {
      "languagesAvailable": ["SK", "UK", "IT", "FR", "RO", "ES", "CS", "EN", "DE", "HU", "PL"],
      "languageChosen": "PL"
    },
    "visibilityOptions": {
      "showFrontCountry": false,
      "showFrontEmail": true,
      "showFrontFees": true,
      "showFrontPartnerMarketing": "OPTIONAL",
      "showFrontRegulations": true,
      "showFrontTradeMarketing": "OPTIONAL",
      "showReturnToPaywaysButton": true,
      "showReturnToShopButton": true,
      "showFrontBasket": false
    },
    "chosenPaywayId": 9,
    "isCardRecurringRegulationToShow": true
  }
}
export const THANK_YOU_START = {
  transRemoteID: 'A73TSAQIBE',
  customerFee: 1.50,
  orderId: '20220225081301',
  payAmount: {
    value: 1.00,
    currency: 'PLN'
  },
  receiverNameForFront: 'Toya Sp z o.o.',
  serviceID: 100079,
  languageData: {languagesAvailable: ['PL'], languageChosen: 'PL'},
  customerNumber: '',
  invoiceNumber: '',
  accountNumber: ''
}
export const SESSION_GET_REMAINING_TIME = {sessionRemainingTime: 30000}
export const REGULATIONS_APPROVAL_START = {
  status: 'SUCCESS',
  startResponseData: {
    containsPisPayway: true,
    chosenPayway: {
      "id": 1897,
      "type": "PBL",
      "nameOnList": "PG płatność testowa PIS",
      "iconUrl": "https://testimages.autopay.eu/pomoc/grafika/1897.png",
      "recurring": false,
      "active": true,
      "buttonTitle": "I accept and begin payment",
      "requiredParams": [],
      "redirectToCards": false,
      "regulationIds": [
        832,
        833,
        1234,
        823,
        824,
        825,
        826,
        827,
        828,
        829,
        830,
        831
      ]
    },
    transRemoteID: 'A88L3MP3M5',
    startAmount: 1.00,
    startAmountCurrency: 'PLN',
    payAmount: 1.00,
    payAmountCurrency: 'PLN',
    customerFee: 0.00,
    transactionValidityTime: '2022-03-07T12:19:15',
    additionalData: {
      orderId: '20220304121915',
      customerEmail: 'devnull@bm.pl'
    },
    acceptorID: 101940,
    serviceID: 102837,
    serviceType: 'ECOMMERCE',
    shortName: 'Test nowych frontów',
    receiverNameForFront: 'Test nowych frontów ECOMMERCE',
    serviceUrl: 'http://devnull.bm.pl',
    returnUrl: 'https://devnull.bm.pl',
    regulations: [
      {
        "regulationId": 826,
        "type": "DEFAULT",
        "language": "FR",
        "inputLabel": "En cliquant sur « Commencez le paiement », vous acceptez que la transaction soit initiée auprès de votre banque et que ses détails soient transmis au destinataire des fonds par Autopay S.A. - une société supervisée par la Commission de contrôle financier [KNF] (voir les coordonnées de KNF) et inscrite au registre des établissements de paiement nationaux sous le numéro IP17/2013. La société Autopay Spółka Akcyjna a son siège à Sopot, ul. Powstańców Warszawy 6, 81-718. Contact : pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 828,
        "type": "DEFAULT",
        "language": "IT",
        "inputLabel": "Cliccando sul pulsante “Avvia il pagamento” accetti di avviare la transazione con la tua banca e di fornire i dettagli al destinatario dei fondi da parte di Autopay S.A. -società sorvegliata dalla Commissione per la vigilanza finanziaria (cfr. i recapiti della Commissione) e iscritta al registro degli istituti di pagamento nazionali al numero IP17/2013. Società Autopay S.A. con sede a Sopot (81-718) in ul. Powstańców Warszawy 6. Contatti: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 832,
        "type": "DEFAULT",
        "language": "HU",
        "inputLabel": "A „Fizetés indítása” gombra kattintva beleegyezel, hogy bankodban elindítsad a tranzakciót, és a Autopay S.A. átadja annak részleteit a pénzösszeg címzettjének. - a Pénzügyi Felügyelet által felügyelt társaság (lásd a Pénzügyi Felügyelet elérhetőségeit), és az IP17/2013 számon bejegyzett a hazai pénzforgalmi intézmények nyilvántartásába. Autopay S.A. Társaság székhelye: Sopot (81-718) ul. Powstańców Warszawy 6. Kapcsolat: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 1234,
        "type": "DEFAULT",
        "language": "BG",
        "inputLabel": "Избирайки бутона „започни плащане“, се съгласявате да се инициира транзакцията във вашата банка и да се предоставят нейните данни/подробности на получателя на средствата. Доставчик на услугата е Autopay S.A. със седалище в Сопот, Полша (ul. Powstańców Warszawy 6, 81-718 Sopot/ул. Повстанцув Варшави №6, пощ. код 81-718, Сопот, имейл: pomoc@bm.pl), контролирано от Полския орган за финансов надзор (<a href=\"https://www.knf.gov.pl/o_nas/urzad_komisji/dane_teleadresowe\" target=\"_blank\">https://www.knf.gov.pl/o_nas/urzad_komisji/dane_teleadresowe</a>) и вписано в регистъра на националните платежни институции под номер IP17/2013 .",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 825,
        "type": "DEFAULT",
        "language": "DE",
        "inputLabel": "Indem Sie auf „Zahlungsvorgang starten“ klicken, erklären Sie sich damit einverstanden, dass die Transaktion bei Ihrer Bank eingeleitet wird und die Daten von Autopay S.A. (eine von der Finanzaufsichtsbehörde beaufsichtigte Gesellschaft (siehe Kontaktdaten Finanzaufsichtsbehörde), die im Register der nationalen Zahlungsinstitute unter der Nummer IP17/2013 eingetragen ist) an den Empfänger des Geldbetrags übermittelt werden. Der Hauptsitz von Autopay S.A. befindet sich in Sopot (81-718), ul. Powstańców Warszawy 6. Kontakt: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 829,
        "type": "DEFAULT",
        "language": "ES",
        "inputLabel": "Al hacer clic en \"Iniciar pago\", aceptas el inicio de la operación por tu banco y el envío del detalle de la misma al destinatario por Autopay S.A., sociedad supervisada por la Comisión de Supervisión Financiera (ver datos de contacto de la Comisión) e inscrita en el registro de entidades de pago nacionales con el no. IP17/2013. El domicilio de Autopay S.A. es: Sopot (81-718), ul. Powstańców Warszawy 6. Contacto: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 830,
        "type": "DEFAULT",
        "language": "SK",
        "inputLabel": "Kliknutím na tlačidlo \"Zaplatiť\", súhlasíte so začatím transakcie vo Vašej banke a poskytnutím detailov príjemcovi finančných prostriedkov prostredníctvom Autopay S.A. – spoločnosti kontrolovanej poľským Úradom pre finančný trh (pozri kontaktné údaje KNF) a zapísanej v registri poskytovateľov platobných služieb pod číslom IP17/2013. Spoločnosť Autopay S.A. sídli v Sopote (81-718) ul. Powstańców Warszawy 6. Kontakt: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 824,
        "type": "DEFAULT",
        "language": "EN",
        "inputLabel": "By clicking \"Start payment\", you agree that your bank will initiate the transaction and provide its details to the recipient of the funds by Autopay S.A. - a company supervised by the Polish Financial Supervision Authority (see PFSA contact details) and entered in the register of national payment institutions under No. IP17/2013. Autopay S.A. is headquartered in Sopot (81-718) at ul. Powstańców Warszawy 6. Contact: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 823,
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "Wybierając przycisk „rozpocznij płatność”, zgadzasz się na rozpoczęcie transakcji w Twoim banku i dostarczenie jej szczegółów do odbiorcy środków. Dostawcą usługi jest Autopay S.A. z siedzibą w Sopocie (ul. Powstańców Warszawy 6, 81-718 Sopot, e-mail: pomoc@autopay.pl), nadzorowana przez Komisję Nadzoru Finansowego (<a href=\"https://www.knf.gov.pl/o_nas/urzad_komisji/dane_teleadresowe\" target=\"_blank\">https://www.knf.gov.pl/o_nas/urzad_komisji/dane_teleadresowe</a>) i wpisana do rejestru krajowych instytucji płatniczych pod numerem IP17/2013.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 827,
        "type": "DEFAULT",
        "language": "CS",
        "inputLabel": "Kliknutím na \"Zahájit platbu\" vyjadřujete souhlas se zahájením transakce u Vaší banky a poskytnutím její podrobností příjemci prostředků subjektem Autopay S.A. - společností pod dozorem Komise pro finanční dozor (zobrazit kontaktní údaje Komise pro finanční dozor) zapsanou v registru polských platebních institucí pod číslem IP17/2013. Společnost Autopay S.A. má sídlo v Sopotech (81-718) v ulici Powstańców Warszawy 6. Kontakt: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 833,
        "type": "DEFAULT",
        "language": "UK",
        "inputLabel": "Натискаючи на кнопку \"Почати платіж\", Ви надаєте згоду на те, щоб розпочати транзакцію у Вашому банку і передати її детальні відомості отримувачу коштів через Autopay S.A. - акціонерне товариство, контроль за діяльністю якого здійснює Комісія фінансового нагляду (див. контактні дані КФН), і яке зареєстроване в реєстрі платіжних установ під номером IP17/2013. Товариство Autopay S.A. має наступну юридичну адресу: м.Сопот (81-718), вул. Повстаньцув Варшави 6. Контакт: pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      },
      {
        "regulationId": 831,
        "type": "DEFAULT",
        "language": "RO",
        "inputLabel": "Făcând clic pe „Începe plata”, ești de acord să începi tranzacția în banca Ta și să furnizezi detaliile acesteia destinatarului fondurilor prin intermediul Autopay S.A. - companie supravegheată de Autoritatea de  upraveghere Financiară din Polonia (vezi datele de contact KNF) și înregistrată în registrul instituțiilor naționale de plată sub numărul IP17/2013. Autopay S.A. are sediul social în Sopot (81-718), ul. Powstańców Warszawy 6. Contact: Pomoc@autopay.pl.",
        "gatewayId": 1897,
        "checkedAndHidden": true
      }
    ],
    languageData: {
      languagesAvailable: [
        'PL',
        'EN',
        'DE'
      ],
      languageChosen: 'PL'
    },
    visibilityOptions: {
      showFrontCountry: false,
      showFrontEmail: true,
      showFrontFees: true,
      showFrontPartnerMarketing: 'OPTIONAL',
      showFrontRegulations: true,
      showFrontTradeMarketing: 'OPTIONAL',
      showReturnToPaywaysButton: false,
      showReturnToShopButton: true
    },
    sessionRemainingTime: 186
  }
}
export const CONFIRMATION = {
  "status": "SUCCESS",
  "data": {
    "state": "POSITIVE",
    "languageData": {
      "languagesAvailable": [
        "RO",
        "DE",
        "UK",
        "BG",
        "FR",
        "TR",
        "EN",
        "SK",
        "EL",
        "IT",
        "ES",
        "HU",
        "PL",
        "CS"
      ],
      "languageChosen": "PL"
    },
    "transaction": {
      "payAmount": 1.00,
      "payAmountCurrency": "PLN",
      "customerFee": 0.00,
      "orderId": "20250331105221",
      "transRemoteID": "AUNK3E43MJ",
      "paywayId": 106,
      "receiverNameForFront": "test",
      "profiling": {
        "profilingAgree": true,
        "profilingHash": "6318c4730bfe47fc805f037b8faebc7fd37bcfcd076dd2350d821026f51743f7"
      }
    },
    "serviceType": "ECOMMERCE",
    "returnUrl": "https://devnull.bm.pl?ServiceID=102837&OrderID=20250331105221&Hash=ac68f541068e2e7602e28e11d10c425f2ae8f05189627f608d1b565ff9a7df8c",
    "visibilityOptions": {
      "showFrontFees": true,
      "showReturnToShopButton": true
    }
  }
}
export const PAYMENT_CONTINUE = {
  'status': 'SUCCESS',
  'data': {
    'containsPisPayway': false,
    'transRemoteID': 'AQ1RHGDJXG',
    'startAmount': 1.00,
    'startAmountCurrency': 'PLN',
    'payAmount': 2.00,
    'payAmountCurrency': 'PLN',
    'customerFee': 1.00,
    'transactionValidityTime': '2022-09-09T10:02:56',
    'additionalData': {'orderId': '20220906100256', 'customerEmail': 'devnull@bm.pl', 'taxCountry': ''},
    'continueTransactionData': {
      'amount': '1.00',
      'currency': 'PLN',
      'merchantId': '903835',
      'customerEmail': 'devnull@bm.pl',
      'taxCountry': '',
      'orderId': '20220906100256',
      'accountNumber': '',
      'customerPhone': '',
      'customerNumber': '',
      'invoiceNumber': '',
      'gatewayId': 1808
    },
    'acceptorID': 902555,
    'serviceID': 903835,
    'serviceType': 'MWF',
    'shortName': 'Test podmiany kanałów',
    'receiverNameForFront': 'Test podmiany kanałów',
    'serviceUrl': 'https://devnull.bm.pl',
    'returnUrl': 'https://devnull.bm.pl?ServiceID=903835&OrderID=20220906100256&Hash=0c599a56306d67b70d36810a49f94cf8882598349d43948c79c751b13da9a1b9',
    'regulations': [{
      'regulationId': 13404,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=e0d5d6ecc2225544d32a99a12f242a77&Type=DEFAULT&Language=PL&GatewayID=1809&Hash=f414fc2cc97f92c51d3555fa92d24289eb7dece7e84f80685791bb00b7b94677',
      'type': 'DEFAULT',
      'language': 'PL',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=e0d5d6ecc2225544d32a99a12f242a77&Type=DEFAULT&Language=PL&GatewayID=1809&Hash=f414fc2cc97f92c51d3555fa92d24289eb7dece7e84f80685791bb00b7b94677>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1809,
      'checkedAndHidden': false
    }, {
      'regulationId': 13403,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=1719f4ae077d7aba39937d275b6fc121&Type=DEFAULT&Language=PL&GatewayID=1800&Hash=5ba81529539178f93048c03d00663d558a341b024075a699661135f20c502360',
      'type': 'DEFAULT',
      'language': 'PL',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=1719f4ae077d7aba39937d275b6fc121&Type=DEFAULT&Language=PL&GatewayID=1800&Hash=5ba81529539178f93048c03d00663d558a341b024075a699661135f20c502360>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1800,
      'checkedAndHidden': false
    }, {
      'regulationId': 13400,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=5427c1e6c8325f4e61bc66254c2a8adc&Type=DEFAULT&Language=PL&GatewayID=1801&Hash=84caafe33d3cd4b49778d098d0d608b7162c33a054815034181c4d8e91b3cb92',
      'type': 'DEFAULT',
      'language': 'PL',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=5427c1e6c8325f4e61bc66254c2a8adc&Type=DEFAULT&Language=PL&GatewayID=1801&Hash=84caafe33d3cd4b49778d098d0d608b7162c33a054815034181c4d8e91b3cb92>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1801,
      'checkedAndHidden': false
    }, {
      'regulationId': 13399,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=336c554d0c698cd6456874f12ddebd97&Type=DEFAULT&Language=PL&GatewayID=1802&Hash=89df7432f5dc9789f44fcb1f87d4901d47b0652cb6b2ab0c915fde766fc7145b',
      'type': 'DEFAULT',
      'language': 'PL',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=336c554d0c698cd6456874f12ddebd97&Type=DEFAULT&Language=PL&GatewayID=1802&Hash=89df7432f5dc9789f44fcb1f87d4901d47b0652cb6b2ab0c915fde766fc7145b>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1802,
      'checkedAndHidden': false
    }, {
      'regulationId': 13402,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=256579f3773a48704b7ac32ba2711927&Type=DEFAULT&Language=PL&GatewayID=1804&Hash=72587d00e2d5f6dfd34a9f543ad2f61a9ce38ac8d3c8ec830b407e9f23027533',
      'type': 'DEFAULT',
      'language': 'PL',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=256579f3773a48704b7ac32ba2711927&Type=DEFAULT&Language=PL&GatewayID=1804&Hash=72587d00e2d5f6dfd34a9f543ad2f61a9ce38ac8d3c8ec830b407e9f23027533>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1804,
      'checkedAndHidden': false
    }, {
      'regulationId': 13401,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=71b13e71d61112d602fb05ac34a3b32e&Type=DEFAULT&Language=PL&GatewayID=1803&Hash=6a270eba231b6a1880f2670058bfa9de9fb1744150ce3cf7802235406aaf3c46',
      'type': 'DEFAULT',
      'language': 'PL',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=71b13e71d61112d602fb05ac34a3b32e&Type=DEFAULT&Language=PL&GatewayID=1803&Hash=6a270eba231b6a1880f2670058bfa9de9fb1744150ce3cf7802235406aaf3c46>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1803,
      'checkedAndHidden': false
    }, {
      'regulationId': 13408,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=4d00cecfafcfcab5f0eef4e3e407e74b&Type=DEFAULT&Language=PL&GatewayID=1805&Hash=254eaad83e244e55c99da5243527fd4bf697dcb5dc4bc9e79f696d08d28cdbde',
      'type': 'DEFAULT',
      'language': 'PL',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=4d00cecfafcfcab5f0eef4e3e407e74b&Type=DEFAULT&Language=PL&GatewayID=1805&Hash=254eaad83e244e55c99da5243527fd4bf697dcb5dc4bc9e79f696d08d28cdbde>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\r\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\r\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1805,
      'checkedAndHidden': false
    }, {
      'regulationId': 13407,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=b74951fe247a537849967b007b29a081&Type=DEFAULT&Language=PL&GatewayID=1806&Hash=f53ac27c429baa12f6e72f26e8cf10ce8033815b1b30d096c0259d04350e5aec',
      'type': 'DEFAULT',
      'language': 'PL',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=b74951fe247a537849967b007b29a081&Type=DEFAULT&Language=PL&GatewayID=1806&Hash=f53ac27c429baa12f6e72f26e8cf10ce8033815b1b30d096c0259d04350e5aec>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1806,
      'checkedAndHidden': false
    }, {
      'regulationId': 13406,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=977f0d14855c90b54cb6be4cdb28eeec&Type=DEFAULT&Language=PL&GatewayID=1807&Hash=9b677d42fcfb343ac9e27517ca90128788d3bd88f0a0fdb80d4432706c502249',
      'type': 'DEFAULT',
      'language': 'PL',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=977f0d14855c90b54cb6be4cdb28eeec&Type=DEFAULT&Language=PL&GatewayID=1807&Hash=9b677d42fcfb343ac9e27517ca90128788d3bd88f0a0fdb80d4432706c502249>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1807,
      'checkedAndHidden': false
    }, {
      'regulationId': 13829,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=dd487a7d58294c3eefda2bd0050bf2dc&Type=DEFAULT&Language=PL&GatewayID=1899&Hash=d93b82be4b9f35fc1d26e393a77baedba96831a85b5e74c161ce404be858b7ee',
      'type': 'DEFAULT',
      'language': 'PL',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=dd487a7d58294c3eefda2bd0050bf2dc&Type=DEFAULT&Language=PL&GatewayID=1899&Hash=d93b82be4b9f35fc1d26e393a77baedba96831a85b5e74c161ce404be858b7ee>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1899,
      'checkedAndHidden': false
    }, {
      'regulationId': 13828,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=3319279526457fea77b38fc65003268b&Type=DEFAULT&Language=PL&GatewayID=1898&Hash=bf30a9c6da425a44636b566e6a224f7ff029ca15082c4e8be04b9bec91da0a01',
      'type': 'DEFAULT',
      'language': 'PL',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=3319279526457fea77b38fc65003268b&Type=DEFAULT&Language=PL&GatewayID=1898&Hash=bf30a9c6da425a44636b566e6a224f7ff029ca15082c4e8be04b9bec91da0a01>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1898,
      'checkedAndHidden': false
    }, {
      'regulationId': 13405,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=2d5a8aadd57a8e0750b2f033affca7db&Type=DEFAULT&Language=PL&GatewayID=1808&Hash=948f3a0a773bd08fb9a307497170465e59d2b708707f3824205ba5e0ff946d2e',
      'type': 'DEFAULT',
      'language': 'PL',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=2d5a8aadd57a8e0750b2f033affca7db&Type=DEFAULT&Language=PL&GatewayID=1808&Hash=948f3a0a773bd08fb9a307497170465e59d2b708707f3824205ba5e0ff946d2e>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1808,
      'checkedAndHidden': false
    }, {
      'regulationId': 13388,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=ebcdf0048cbc643d63a36802aacf2928&Type=DEFAULT&Language=EN&Hash=fadf83d1565f0682c3bf33002672208ea52cfd3420f1dd311658ecfbb6e15852',
      'type': 'DEFAULT',
      'language': 'EN',
      'inputLabel': '<ul><li>\r\n                I have read and acknowledged <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=ebcdf0048cbc643d63a36802aacf2928&Type=DEFAULT&Language=EN&Hash=fadf83d1565f0682c3bf33002672208ea52cfd3420f1dd311658ecfbb6e15852>the Terms and Conditions of Payment Services</a> and <a class="privacy-policy" href="https://autopay.eu/storage/app/media/Autopay_pl/Dokumenty/privacy_policy.pdf" target="_blank">the Privacy Policy</a></li><li>\r\n                I wish the service to be performed immediately and I am aware that in the event of the withdrawal from the contract I will not obtain the reimbursement of the costs incurred for the services which will have been performed upon my request by the time of the withdrawal.\r\n            </li></ul>',
      'checkedAndHidden': false
    }, {
      'regulationId': 13387,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=9bccfe24a7adb747e52fffc1fdd8e771&Type=DEFAULT&Language=PL&Hash=98ef249160d0fca958af74f1374bdd5d8ef4f094bb7536accee28bbc04e2ee91',
      'type': 'DEFAULT',
      'language': 'PL',
      'inputLabel': '<ul><li>\r\n                Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=9bccfe24a7adb747e52fffc1fdd8e771&Type=DEFAULT&Language=PL&Hash=98ef249160d0fca958af74f1374bdd5d8ef4f094bb7536accee28bbc04e2ee91>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li><li>\r\n                Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.\r\n            </li></ul>',
      'checkedAndHidden': false
    }, {
      'regulationId': 13405,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=2d5a8aadd57a8e0750b2f033affca7db&Type=DEFAULT&Language=PL&GatewayID=1808&Hash=948f3a0a773bd08fb9a307497170465e59d2b708707f3824205ba5e0ff946d2e',
      'type': 'DEFAULT',
      'language': 'EN',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=2d5a8aadd57a8e0750b2f033affca7db&Type=DEFAULT&Language=PL&GatewayID=1808&Hash=948f3a0a773bd08fb9a307497170465e59d2b708707f3824205ba5e0ff946d2e>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1808,
      'checkedAndHidden': false
    }, {
      'regulationId': 13404,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=e0d5d6ecc2225544d32a99a12f242a77&Type=DEFAULT&Language=PL&GatewayID=1809&Hash=f414fc2cc97f92c51d3555fa92d24289eb7dece7e84f80685791bb00b7b94677',
      'type': 'DEFAULT',
      'language': 'EN',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=e0d5d6ecc2225544d32a99a12f242a77&Type=DEFAULT&Language=PL&GatewayID=1809&Hash=f414fc2cc97f92c51d3555fa92d24289eb7dece7e84f80685791bb00b7b94677>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1809,
      'checkedAndHidden': false
    }, {
      'regulationId': 13402,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=256579f3773a48704b7ac32ba2711927&Type=DEFAULT&Language=PL&GatewayID=1804&Hash=72587d00e2d5f6dfd34a9f543ad2f61a9ce38ac8d3c8ec830b407e9f23027533',
      'type': 'DEFAULT',
      'language': 'EN',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=256579f3773a48704b7ac32ba2711927&Type=DEFAULT&Language=PL&GatewayID=1804&Hash=72587d00e2d5f6dfd34a9f543ad2f61a9ce38ac8d3c8ec830b407e9f23027533>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1804,
      'checkedAndHidden': false
    }, {
      'regulationId': 13408,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=4d00cecfafcfcab5f0eef4e3e407e74b&Type=DEFAULT&Language=PL&GatewayID=1805&Hash=254eaad83e244e55c99da5243527fd4bf697dcb5dc4bc9e79f696d08d28cdbde',
      'type': 'DEFAULT',
      'language': 'EN',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=4d00cecfafcfcab5f0eef4e3e407e74b&Type=DEFAULT&Language=PL&GatewayID=1805&Hash=254eaad83e244e55c99da5243527fd4bf697dcb5dc4bc9e79f696d08d28cdbde>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\r\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\r\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1805,
      'checkedAndHidden': false
    }, {
      'regulationId': 13407,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=b74951fe247a537849967b007b29a081&Type=DEFAULT&Language=PL&GatewayID=1806&Hash=f53ac27c429baa12f6e72f26e8cf10ce8033815b1b30d096c0259d04350e5aec',
      'type': 'DEFAULT',
      'language': 'EN',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=b74951fe247a537849967b007b29a081&Type=DEFAULT&Language=PL&GatewayID=1806&Hash=f53ac27c429baa12f6e72f26e8cf10ce8033815b1b30d096c0259d04350e5aec>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1806,
      'checkedAndHidden': false
    }, {
      'regulationId': 13406,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=977f0d14855c90b54cb6be4cdb28eeec&Type=DEFAULT&Language=PL&GatewayID=1807&Hash=9b677d42fcfb343ac9e27517ca90128788d3bd88f0a0fdb80d4432706c502249',
      'type': 'DEFAULT',
      'language': 'EN',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=977f0d14855c90b54cb6be4cdb28eeec&Type=DEFAULT&Language=PL&GatewayID=1807&Hash=9b677d42fcfb343ac9e27517ca90128788d3bd88f0a0fdb80d4432706c502249>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1807,
      'checkedAndHidden': false
    }, {
      'regulationId': 13403,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=1719f4ae077d7aba39937d275b6fc121&Type=DEFAULT&Language=PL&GatewayID=1800&Hash=5ba81529539178f93048c03d00663d558a341b024075a699661135f20c502360',
      'type': 'DEFAULT',
      'language': 'EN',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=1719f4ae077d7aba39937d275b6fc121&Type=DEFAULT&Language=PL&GatewayID=1800&Hash=5ba81529539178f93048c03d00663d558a341b024075a699661135f20c502360>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1800,
      'checkedAndHidden': false
    }, {
      'regulationId': 13400,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=5427c1e6c8325f4e61bc66254c2a8adc&Type=DEFAULT&Language=PL&GatewayID=1801&Hash=84caafe33d3cd4b49778d098d0d608b7162c33a054815034181c4d8e91b3cb92',
      'type': 'DEFAULT',
      'language': 'EN',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=5427c1e6c8325f4e61bc66254c2a8adc&Type=DEFAULT&Language=PL&GatewayID=1801&Hash=84caafe33d3cd4b49778d098d0d608b7162c33a054815034181c4d8e91b3cb92>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1801,
      'checkedAndHidden': false
    }, {
      'regulationId': 13828,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=3319279526457fea77b38fc65003268b&Type=DEFAULT&Language=PL&GatewayID=1898&Hash=bf30a9c6da425a44636b566e6a224f7ff029ca15082c4e8be04b9bec91da0a01',
      'type': 'DEFAULT',
      'language': 'EN',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=3319279526457fea77b38fc65003268b&Type=DEFAULT&Language=PL&GatewayID=1898&Hash=bf30a9c6da425a44636b566e6a224f7ff029ca15082c4e8be04b9bec91da0a01>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1898,
      'checkedAndHidden': false
    }, {
      'regulationId': 13399,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=336c554d0c698cd6456874f12ddebd97&Type=DEFAULT&Language=PL&GatewayID=1802&Hash=89df7432f5dc9789f44fcb1f87d4901d47b0652cb6b2ab0c915fde766fc7145b',
      'type': 'DEFAULT',
      'language': 'EN',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=336c554d0c698cd6456874f12ddebd97&Type=DEFAULT&Language=PL&GatewayID=1802&Hash=89df7432f5dc9789f44fcb1f87d4901d47b0652cb6b2ab0c915fde766fc7145b>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1802,
      'checkedAndHidden': false
    }, {
      'regulationId': 13829,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=dd487a7d58294c3eefda2bd0050bf2dc&Type=DEFAULT&Language=PL&GatewayID=1899&Hash=d93b82be4b9f35fc1d26e393a77baedba96831a85b5e74c161ce404be858b7ee',
      'type': 'DEFAULT',
      'language': 'EN',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=dd487a7d58294c3eefda2bd0050bf2dc&Type=DEFAULT&Language=PL&GatewayID=1899&Hash=d93b82be4b9f35fc1d26e393a77baedba96831a85b5e74c161ce404be858b7ee>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1899,
      'checkedAndHidden': false
    }, {
      'regulationId': 13401,
      'url': 'https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=71b13e71d61112d602fb05ac34a3b32e&Type=DEFAULT&Language=PL&GatewayID=1803&Hash=6a270eba231b6a1880f2670058bfa9de9fb1744150ce3cf7802235406aaf3c46',
      'type': 'DEFAULT',
      'language': 'EN',
      'inputLabel': '<ul><li>Zapoznałem się i akceptuję <a id="regulations_pdf" target="_blank" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903835&MessageID=71b13e71d61112d602fb05ac34a3b32e&Type=DEFAULT&Language=PL&GatewayID=1803&Hash=6a270eba231b6a1880f2670058bfa9de9fb1744150ce3cf7802235406aaf3c46>Regulamin świadczenia usług płatniczych</a> oraz <a class="privacy-policy" href="https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf" target="_blank">Politykę prywatności</a></li>\n<li>Zgadzam się na rozpoczęcie transakcji w moim banku za pośrednictwem Autopay S.A. oraz dostarczenie szczegółów tej transakcji do odbiorcy</li>\n<li>Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.</li></ul>',
      'gatewayId': 1803,
      'checkedAndHidden': false
    }],
    'clientSource': 'psd2Data',
    'psd2Data': {
      'wtnSocketUrl': 'wss://wtn-accept.bm.pl/wtn/api/socket/AQ1RHGDJXG/1662451427540/9a0e33d1-94bd-4991-a96d-09b3b4def4ea/9f49fe3c897827df7df233e7e6c804160e98e27a75f71fe3df57d7a93ea1b31e',
      'wtnRestUrl': 'https://wtn-accept.bm.pl/wtn/api/rest/fallback/AQ1RHGDJXG/1662451427540/9a0e33d1-94bd-4991-a96d-09b3b4def4ea/9f49fe3c897827df7df233e7e6c804160e98e27a75f71fe3df57d7a93ea1b31e',
      'returnUrl': 'https://devnull.bm.pl?ServiceID=903835&OrderID=20220906100256&Hash=0c599a56306d67b70d36810a49f94cf8882598349d43948c79c751b13da9a1b9',
      'continueUrl': 'https://pay-accept.bm.pl/web/payment/continue/AQ1RHGDJXG/NIQQBANS',
      'languageChosen': 'PL',
      'timeout': 30,
      'idtransremote': 'AQ1RHGDJXG',
      'hash': 'be402c4f802e98baafa42bd8ec73d709d134da6aa336533337d4a05878ec17b8'
    },
    'languageData': {'languagesAvailable': ['EN', 'PL'], 'languageChosen': 'PL'},
    'visibilityOptions': {
      'showFrontCountry': false,
      'showFrontEmail': true,
      'showFrontFees': true,
      'showFrontPartnerMarketing': 'OPTIONAL',
      'showFrontRegulations': false,
      'showFrontTradeMarketing': 'OPTIONAL',
      'showReturnToPaywaysButton': true,
      'showReturnToShopButton': true,
      'showFrontBasket': false
    },
    'sessionRemainingTime': 248,
    'apiToken': '683c0fbc33a8468d8947c3cf34c1fb4d',
    'chosenPaywayId': 1808,
    'isCardRecurringRegulationToShow': true
  }
}
export const PAYMENT_CONTINUE_PIS = {
  "status": "SUCCESS",
  "data": {
    "containsPisPayway": false,
    "transRemoteID": "AD44X5V61U",
    "startAmount": 1.00,
    "startAmountCurrency": "PLN",
    "payAmount": 2.00,
    "payAmountCurrency": "PLN",
    "customerFee": 1.00,
    "transactionValidityTime": "2023-10-09T11:30:15",
    "additionalData": {
      "orderId": "20231006113015",
      "customerEmail": "devnull@bm.pl",
      "taxCountry": ""
    },
    "continueTransactionData": {
      "amount": "1.00",
      "currency": "PLN",
      "merchantId": "903838",
      "customerEmail": "devnull@bm.pl",
      "taxCountry": "",
      "orderId": "20231006113015",
      "accountNumber": "",
      "customerPhone": "000000000",
      "customerNumber": "",
      "invoiceNumber": "",
      "gatewayId": 1898
    },
    "acceptorID": 902558,
    "serviceID": 903838,
    "serviceType": "MWF",
    "shortName": "Test podmiany kanałów",
    "receiverNameForFront": "Test podmiany kanałów",
    "serviceUrl": "https://devnull.bm.pl",
    "returnUrl": "https://devnull.bm.pl?ServiceID=903838&OrderID=20231006113015&Hash=51b9b06f85379a19a48dcc1184af856a701f96b4ec38115302c74e31953c8458",
    "regulations": [
      {
        "regulationId": 153668,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=903838&MessageID=d7644566d547bc20e412fac230c74654&Type=DEFAULT&Language=PL&Hash=2c505d474ee8029341e5e8b3930080c3717a7f03b78208a24034157cc6ffcf7b",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://testpay.autopay.eu/webapi/regulation?ServiceID=903838&MessageID=d7644566d547bc20e412fac230c74654&Type=DEFAULT&Language=PL&Hash=2c505d474ee8029341e5e8b3930080c3717a7f03b78208a24034157cc6ffcf7b>regulamin</a> i <a class=\"privacy-policy\" href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności Autopay S.A.</a> oraz żądanie natychmiastowej realizacji usługi płatniczej.</li><li><span class=\"___more ___hidden\">Chcę, aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "checkedAndHidden": true
      },
      {
        "regulationId": 153669,
        "url": "https://testpay.autopay.eu/webapi/regulation?ServiceID=903838&MessageID=68f19ba88b728dd8dc04e4568a7e4e9f&Type=DEFAULT&Language=EN&Hash=3c1ca54e8bcefaaafb5575123e2e37e733ab41d245100fa7bcdcf560457579da",
        "type": "DEFAULT",
        "language": "EN",
        "inputLabel": "<ul><li>If you want to make the payment, accept the Autopay S.A. <a id=\"regulations_pdf\" target=\"_blank\" href=https://testpay.autopay.eu/webapi/regulation?ServiceID=903838&MessageID=68f19ba88b728dd8dc04e4568a7e4e9f&Type=DEFAULT&Language=EN&Hash=3c1ca54e8bcefaaafb5575123e2e37e733ab41d245100fa7bcdcf560457579da>Regulations</a>, the <a class=\"privacy-policy\" href=\"https://autopay.eu/storage/app/media/Autopay_pl/Dokumenty/privacy_policy.pdf\" target=\"_blank\">Privacy Policy</a> and consent to the immediate provision of the payment service. </li><li><span class=\"___more ___hidden\">I consent for the service to be provided immediately and I acknowledge that I will not be reimbursed for any costs incurred in the event of withdrawal from the contract.</span></li></ul>",
        "checkedAndHidden": true
      }
    ],
    "clientSource": "psd2Data",
    "psd2Data": {
      "wtnSocketUrl": "wss://wtn-accept.bm.pl/wtn/api/socket/AD44X5V61U/1696584653078/4d540d06-ea05-487d-a799-fbb6a1f8a568/329ea9a823221fa3399122fb642e8c6a30289b18471fdd52c375dbbb6a8ee42a",
      "wtnRestUrl": "https://wtn-accept.bm.pl/wtn/api/rest/fallback/AD44X5V61U/1696584653078/4d540d06-ea05-487d-a799-fbb6a1f8a568/329ea9a823221fa3399122fb642e8c6a30289b18471fdd52c375dbbb6a8ee42a",
      "returnUrl": "https://devnull.bm.pl?ServiceID=903838&OrderID=20231006113015&Hash=51b9b06f85379a19a48dcc1184af856a701f96b4ec38115302c74e31953c8458",
      "languageChosen": "PL",
      "timeout": 30,
      "idtransremote": "AD44X5V61U",
      "hash": "144a5ed995aa39a25d07a1355136325643d97b1de822d6253e06dcb90e6d5b38"
    },
    "languageData": {
      "languagesAvailable": [
        "PL",
        "EN"
      ],
      "languageChosen": "PL"
    },
    "visibilityOptions": {
      "showFrontCountry": false,
      "showFrontEmail": true,
      "showFrontFees": true,
      "showFrontPartnerMarketing": "OPTIONAL",
      "showFrontRegulations": false,
      "showFrontTradeMarketing": "OPTIONAL",
      "showReturnToPaywaysButton": false,
      "showReturnToShopButton": true,
      "showFrontBasket": false
    },
    "sessionRemainingTime": 261,
    "apiToken": "429ca6bf8bf043548ea5f61df82110e0",
    "chosenPaywayId": 1898,
    "isCardRecurringRegulationToShow": false
  }
}
export const PAYMENT_WAIT = {
  'status': 'SUCCESS',
  'data': {
    'clientSource': 'psd2Data',
    'psd2Data': {
      'wtnSocketUrl': 'wss://wtn-accept.bm.pl/wtn/api/socket/AXCDD4DIQA/1662465611337/4e87f0d2-7b69-462e-8b0a-ba55733a3663/3a4547ac00b9ededcc390d53c08ec314ca6d427d5f4cfbd2311b05e9955337d4',
      'wtnRestUrl': 'https://wtn-accept.bm.pl/wtn/api/rest/fallback/AXCDD4DIQA/1662465611337/4e87f0d2-7b69-462e-8b0a-ba55733a3663/3a4547ac00b9ededcc390d53c08ec314ca6d427d5f4cfbd2311b05e9955337d4',
      'returnUrl': 'https://devnull.bm.pl?ServiceID=903835&OrderID=20220906135958&Hash=90a4578c3d59b0fc448f3cb26fcdaf071255b59faf7011deb057b7a523fc83a0',
      'languageChosen': 'PL',
      'timeout': 30,
      'idtransremote': 'AXCDD4DIQA',
      'hash': 'ffe426a181a447496ffffafa1cbbe62f00b313f69dd3f2b0441b75c08e6ec1e9'
    }
  }
}
export const CANCEL_VISAMOBILE_TRANSACTION = {
  'status': 'SUCCESS',
  'data': {}
}
export const PAYMENT_CONTINUE_RECURRING_1503 = {
  "status": "SUCCESS", "data": {
    "paywayGroups": [{
      "type": "Płatność automatyczna",
      "order": 1,
      "languageData": [{"languageValue": "UK", "name": "Автоматичний платіж"}, {
        "languageValue": "HU",
        "name": "Automatikus fizetés"
      }, {"languageValue": "SK", "name": "Automatická platba"}, {
        "languageValue": "RO",
        "name": "Plata automată"
      }, {"languageValue": "ES", "name": "Pago automático"}, {
        "languageValue": "FR",
        "name": "Paiement automatique"
      }, {"languageValue": "IT", "name": "Pagamento automatico"}, {
        "languageValue": "CS",
        "name": "Automatická platba"
      }, {"languageValue": "DE", "name": "Automatische Zahlung"}, {
        "languageValue": "EN",
        "name": "Automatic payment"
      }, {"languageValue": "PL", "name": "Płatność automatyczna"}, {
        "languageValue": "EL",
        "name": "Αυτόματη πληρωμή"
      }, {"languageValue": "HR", "name": "Automatsko plaćanje"}, {
        "languageValue": "SL",
        "name": "Sanidejno plačilo "
      }, {"languageValue": "TR", "name": "Otomatik ödeme"}]
    }, {
      "type": "Karta płatnicza",
      "order": 2,
      "languageData": [{"languageValue": "UK", "name": "Платіжна картка"}, {
        "languageValue": "HU",
        "name": "Fizetési kártya"
      }, {"languageValue": "SK", "name": "Platobná karta"}, {
        "languageValue": "RO",
        "name": "Card de credit"
      }, {"languageValue": "ES", "name": "Tarjeta de pago"}, {
        "languageValue": "FR",
        "name": "Carte de paiement"
      }, {"languageValue": "IT", "name": "Carta di pagamento"}, {
        "languageValue": "CS",
        "name": "Platební karta"
      }, {"languageValue": "DE", "name": "Zahlungskarte"}, {
        "languageValue": "EN",
        "name": "Payment card"
      }, {"languageValue": "PL", "name": "Karta płatnicza"}, {
        "languageValue": "EL",
        "name": "Πιστωτική κάρτα"
      }, {"languageValue": "HR", "name": "Kartica za plaćanje"}, {
        "languageValue": "SL",
        "name": "Plačilna kartica "
      }, {"languageValue": "TR", "name": "Ödeme kartı"}]
    }, {
      "type": "BLIK",
      "order": 3,
      "languageData": [{"languageValue": "UK", "name": "BLIK"}, {
        "languageValue": "HU",
        "name": "BLIK"
      }, {"languageValue": "SK", "name": "BLIK"}, {"languageValue": "RO", "name": "BLIK"}, {
        "languageValue": "ES",
        "name": "BLIK"
      }, {"languageValue": "FR", "name": "BLIK"}, {"languageValue": "IT", "name": "BLIK"}, {
        "languageValue": "CS",
        "name": "BLIK"
      }, {"languageValue": "DE", "name": "BLIK"}, {"languageValue": "EN", "name": "BLIK"}, {
        "languageValue": "PL",
        "name": "BLIK"
      }, {"languageValue": "EL", "name": "BLIK"}, {"languageValue": "HR", "name": "BLIK"}, {
        "languageValue": "SL",
        "name": "BLIK "
      }, {"languageValue": "TR", "name": "BLIK"}]
    }, {
      "type": "PBL",
      "order": 4,
      "languageData": [{"languageValue": "UK", "name": "Платіж з банку"}, {
        "languageValue": "HU",
        "name": "Fizetés a banktól"
      }, {"languageValue": "SK", "name": "Platba z banky"}, {
        "languageValue": "RO",
        "name": "Plata de la bancă"
      }, {"languageValue": "ES", "name": "Pago de un banco"}, {
        "languageValue": "FR",
        "name": "Paiement depuis la banque."
      }, {"languageValue": "IT", "name": "Pagamento dalla banca"}, {
        "languageValue": "CS",
        "name": "Platba z banky"
      }, {"languageValue": "DE", "name": "Zahlung von der Bank"}, {
        "languageValue": "EN",
        "name": "Payment from the bank"
      }, {"languageValue": "PL", "name": "Płatność z banku"}, {
        "languageValue": "EL",
        "name": "Πληρωμή από την τράπεζα"
      }, {"languageValue": "EL", "name": "Πληρωμή από την τράπεζα"}, {
        "languageValue": "HR",
        "name": "Plaćanje putem banke"
      }, {"languageValue": "HR", "name": "Plaćanje putem banke"}, {
        "languageValue": "SL",
        "name": "Plačilo iz banke "
      }, {"languageValue": "SL", "name": "Plačilo iz banke "}, {
        "languageValue": "TR",
        "name": "Banka ödemesi"
      }, {"languageValue": "TR", "name": "Banka ödemesi"}]
    }, {
      "type": "Szybki Przelew",
      "order": 5,
      "languageData": [{"languageValue": "HU", "name": "Önálló átvitel"}, {
        "languageValue": "UK",
        "name": "Незалежна передача - Nezalezhna peredacha"
      }, {"languageValue": "RO", "name": "Transfer autonom"}, {
        "languageValue": "SK",
        "name": "Samostatný prevod"
      }, {"languageValue": "ES", "name": "Transferencia autónoma"}, {
        "languageValue": "IT",
        "name": "Trasferimento autonomo"
      }, {"languageValue": "FR", "name": "Transfert autonome"}, {
        "languageValue": "CS",
        "name": "Samostatný převod"
      }, {"languageValue": "DE", "name": "Eigenständige Übertragung"}, {
        "languageValue": "EN",
        "name": "Standalone transfer"
      }, {"languageValue": "PL", "name": "Samodzielny Przelew"}, {
        "languageValue": "EL",
        "name": "Ανεξάρτητη Μεταγραφή"
      }, {"languageValue": "HR", "name": "Neovisni prijenos"}, {
        "languageValue": "SL",
        "name": "Neodvisno nakazilo "
      }, {"languageValue": "TR", "name": "Bağımsız transfer"}]
    }, {
      "type": "Raty online",
      "order": 6,
      "languageData": [{"languageValue": "UK", "name": "Онлайн-розстрочка"}, {
        "languageValue": "HU",
        "name": "Online részletfizetések"
      }, {"languageValue": "SK", "name": "Splátky online"}, {
        "languageValue": "RO",
        "name": "Rate online"
      }, {"languageValue": "ES", "name": "Cuotas en línea"}, {
        "languageValue": "FR",
        "name": "Tranches en ligne"
      }, {"languageValue": "IT", "name": "Rate online"}, {
        "languageValue": "CS",
        "name": "On-line splátky"
      }, {"languageValue": "DE", "name": "Online-Ratenzahlung"}, {
        "languageValue": "EN",
        "name": "Installments online"
      }, {"languageValue": "PL", "name": "Raty online"}, {
        "languageValue": "EL",
        "name": "Ηλεκτρονικές δόσεις"
      }, {"languageValue": "HR", "name": "Online rate"}, {
        "languageValue": "SL",
        "name": "Spletni obroki "
      }, {"languageValue": "TR", "name": "Çevrimiçi taksit"}]
    }],
    "promotedPayways": [],
    "payways": [{
      "id": 1503,
      "name": "Kartowa płatność automatyczna PBC",
      "type": "Płatność automatyczna",
      "nameOnList": "Kartowa płatność automatyczna",
      "order": 1,
      "iconUrl": "https://images.autopay.eu/storage/app/media/grafika/1503.png",
      "recurring": true,
      "active": true,
      "languageData": [{"languageValue": "PL", "name": "Kartowa płatność automatyczna"}],
      "redirectToCards": false
    }],
    "containsPisPayway": false,
    "transRemoteID": "A9WGM39YS6",
    "startAmount": 1.00,
    "startAmountCurrency": "PLN",
    "payAmount": 1.00,
    "payAmountCurrency": "PLN",
    "customerFee": 0.00,
    "transactionValidityTime": "2023-02-28T11:05:57.336449",
    "additionalData": {"orderId": "32652", "customerEmail": "sebastianpodlasek@gmail.com", "taxCountry": ""},
    "continueTransactionData": {
      "amount": "1.00",
      "currency": "PLN",
      "merchantId": "103184",
      "customerEmail": "sebastianpodlasek@gmail.com",
      "taxCountry": "",
      "orderId": "32652",
      "accountNumber": "",
      "customerPhone": "",
      "customerNumber": "",
      "invoiceNumber": "",
      "gatewayId": 1503
    },
    "acceptorID": 102012,
    "serviceID": 103184,
    "serviceType": "MWF",
    "shortName": "Polskie Aplikacje Transakcyjne",
    "receiverNameForFront": "",
    "serviceUrl": "https://platnosci.rachuneo.pl/",
    "returnUrl": "https://platnosci.rachuneo.pl/platnosci/potwierdzenie?ServiceID=103184&OrderID=32652&Hash=8ca2077364558f94c1c52c2128b40e430d63ecf8b07ba28f350130894763263a",
    "regulations": [{
      "regulationId": 69299,
      "url": "https://pay.bm.pl/webapi/regulation?ServiceID=103184&MessageID=ef93468d766996e3d65d90bb6fde7a1a&Type=DEFAULT&Language=PL&Hash=265cde69349be31112727cbf9fd1d587a350f5bd38b559c79d913de97afe0c7b",
      "type": "DEFAULT",
      "language": "PL",
      "inputLabel": "<ul><li>\r\n                Zapoznałem się i akceptuję <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=103184&MessageID=ef93468d766996e3d65d90bb6fde7a1a&Type=DEFAULT&Language=PL&Hash=265cde69349be31112727cbf9fd1d587a350f5bd38b559c79d913de97afe0c7b>Regulamin świadczenia usług płatniczych</a> oraz <a class=\"privacy-policy\" href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>\r\n                Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.\r\n            </li></ul>",
      "checkedAndHidden": false
    }, {
      "regulationId": 70596,
      "url": "https://pay.bm.pl/webapi/regulation?ServiceID=103184&MessageID=9f993a54e348c850f9ec90aae2f1e1cb&Type=RECURRING&Language=PL&Hash=451d1338285ce21229def55b3a56a48a05350362ed3d320229cc6482725a358f",
      "type": "RECURRING",
      "language": "PL",
      "checkedAndHidden": false
    }],
    "clientSource": "payways",
    "languageData": {"languagesAvailable": ["PL"], "languageChosen": "PL"},
    "visibilityOptions": {
      "showFrontCountry": false,
      "showFrontEmail": true,
      "showFrontFees": false,
      "showFrontPartnerMarketing": "OPTIONAL",
      "showFrontRegulations": true,
      "showFrontTradeMarketing": "OPTIONAL",
      "showReturnToPaywaysButton": false,
      "showReturnToShopButton": true,
      "showFrontBasket": false
    },
    "sessionRemainingTime": 3599,
    "apiToken": "79617126bc0246b3b92bc0564d6ad3ce",
    "chosenPaywayId": 1503,
    "recurringAction": "INIT_WITH_REFUND",
    "isCardRecurringRegulationToShow": true
  }
}
export const PAYMENT_WAIT_NO_DATA = {"status": "SUCCESS", "data": {"clientSource": "psd2Data"}}
export const REGULATIONS_APPROVAL_START_NO_DATA = {
  "status": "SUCCESS", "startResponseData": {
    "containsPisPayway": true,
    "chosenPayway": {
      "id": 1898,
      "name": "PG płatność testowa PIS (AUTO)",
      "type": "PBL",
      "nameOnList": "PG płatność testowa PIS",
      "iconUrl": "https://testimages.autopay.eu/pomoc/grafika/1898.png",
      "recurring": false,
      "active": true,
      "typeForPsd2": "PIS",
      "languageData": [],
      "redirectToCards": false
    },
    "transRemoteID": "AB8Q76659R",
    "startAmount": 1.00,
    "startAmountCurrency": "PLN",
    "payAmount": 2.00,
    "payAmountCurrency": "PLN",
    "customerFee": 1.00,
    "transactionValidityTime": "2023-03-05T15:58:47",
    "additionalData": {"orderId": "20230302155847", "customerEmail": "devnull@bm.pl"},
    "acceptorID": 902463,
    "serviceID": 903704,
    "serviceType": "MWF",
    "shortName": "Test podmiany kanałów",
    "receiverNameForFront": "Test podmiany kanałów",
    "serviceUrl": "https://devnull.bm.pl",
    "returnUrl": "https://devnull.bm.pl",
    "regulations": [{
      "regulationId": 12832,
      "url": "https://pay-accept.bm.pl/webapi/regulation?ServiceID=903704&MessageID=860719d1fe43f508ed04921d319ad798&Type=DEFAULT&Language=PL&Hash=e9c15599246de0d89dd6816864d5160f0e268cf467c758e04ace66ab91565c0c",
      "type": "DEFAULT",
      "language": "PL",
      "inputLabel": "<ul><li>\r\n                Zapoznałem się i akceptuję <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903704&MessageID=860719d1fe43f508ed04921d319ad798&Type=DEFAULT&Language=PL&Hash=e9c15599246de0d89dd6816864d5160f0e268cf467c758e04ace66ab91565c0c>Regulamin świadczenia usług płatniczych</a> oraz <a class=\"privacy-policy\" href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>\r\n                Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.\r\n            </li></ul>",
      "checkedAndHidden": false
    }, {
      "regulationId": 223,
      "url": "https://pay-accept.bm.pl/webapi/regulation?ServiceID=903704&MessageID=8c41b0077688933f3478f1bd1427b54a&Type=DEFAULT&Language=PL&GatewayID=1898&Hash=9a995d041a8e672b64949c96d92f2b4eceabb3728c07b5732d0e7035a281d962",
      "type": "DEFAULT",
      "language": "PL",
      "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay-accept.bm.pl/webapi/regulation?ServiceID=903704&MessageID=8c41b0077688933f3478f1bd1427b54a&Type=DEFAULT&Language=PL&GatewayID=1898&Hash=9a995d041a8e672b64949c96d92f2b4eceabb3728c07b5732d0e7035a281d962>Regulamin</a> i <a class=\"privacy-policy\" href=\"https://autopay.pl/storage/app/media/Autopay_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności</a> Autopay S.A., zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Autopay S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz zgodę na natychmiastową realizację usługi płatniczej.</li><li>\n<span class=\"___more ___hidden\">Zgadzam się aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
      "gatewayId": 1898,
      "checkedAndHidden": true
    }],
    "languageData": {"languagesAvailable": ["PL"], "languageChosen": "PL"},
    "visibilityOptions": {
      "showFrontCountry": false,
      "showFrontEmail": true,
      "showFrontFees": true,
      "showFrontPartnerMarketing": "OPTIONAL",
      "showFrontRegulations": true,
      "showFrontTradeMarketing": "OPTIONAL",
      "showReturnToPaywaysButton": false,
      "showReturnToShopButton": true
    },
    "sessionRemainingTime": 0
  }
}
export const PAYMENT_CONTIUNE_ORANGEDOP = {
  "status": "SUCCESS",
  "data": {
    "paywayGroups": [
      {
        "type": "BLIK",
        "order": 1,
        "languageData": [
          {
            "languageValue": "CS",
            "name": "BLIK"
          },
          {
            "languageValue": "RO",
            "name": "BLIK"
          },
          {
            "languageValue": "DE",
            "name": "BLIK"
          },
          {
            "languageValue": "UK",
            "name": "BLIK"
          },
          {
            "languageValue": "EL",
            "name": "BLIK"
          },
          {
            "languageValue": "PL",
            "name": "BLIK"
          },
          {
            "languageValue": "IT",
            "name": "BLIK"
          },
          {
            "languageValue": "HR",
            "name": "BLIK"
          },
          {
            "languageValue": "SK",
            "name": "BLIK"
          },
          {
            "languageValue": "FR",
            "name": "BLIK"
          },
          {
            "languageValue": "EN",
            "name": "BLIK"
          },
          {
            "languageValue": "HU",
            "name": "BLIK"
          },
          {
            "languageValue": "TR",
            "name": "BLIK"
          },
          {
            "languageValue": "ES",
            "name": "BLIK"
          },
          {
            "languageValue": "SL",
            "name": "BLIK "
          }
        ]
      },
      {
        "type": "Płatność automatyczna",
        "order": 2,
        "languageData": [
          {
            "languageValue": "HU",
            "name": "Automatikus fizetés"
          },
          {
            "languageValue": "CS",
            "name": "Automatická platba"
          },
          {
            "languageValue": "FR",
            "name": "Paiement automatique"
          },
          {
            "languageValue": "EL",
            "name": "Αυτόματη πληρωμή"
          },
          {
            "languageValue": "IT",
            "name": "Pagamento automatico"
          },
          {
            "languageValue": "SK",
            "name": "Automatická platba"
          },
          {
            "languageValue": "TR",
            "name": "Otomatik ödeme"
          },
          {
            "languageValue": "UK",
            "name": "Автоматичний платіж"
          },
          {
            "languageValue": "PL",
            "name": "Płatność automatyczna"
          },
          {
            "languageValue": "ES",
            "name": "Pago automático"
          },
          {
            "languageValue": "SL",
            "name": "Sanidejno plačilo "
          },
          {
            "languageValue": "EN",
            "name": "Automatic payment"
          },
          {
            "languageValue": "DE",
            "name": "Automatische Zahlung"
          },
          {
            "languageValue": "RO",
            "name": "Plata automată"
          },
          {
            "languageValue": "HR",
            "name": "Automatsko plaćanje"
          }
        ]
      },
      {
        "type": "Karta płatnicza",
        "order": 3,
        "languageData": [
          {
            "languageValue": "FR",
            "name": "Carte de paiement"
          },
          {
            "languageValue": "IT",
            "name": "Carta di pagamento"
          },
          {
            "languageValue": "CS",
            "name": "Platební karta"
          },
          {
            "languageValue": "EN",
            "name": "Payment card"
          },
          {
            "languageValue": "DE",
            "name": "Zahlungskarte"
          },
          {
            "languageValue": "RO",
            "name": "Card de credit"
          },
          {
            "languageValue": "HR",
            "name": "Kartica za plaćanje"
          },
          {
            "languageValue": "HU",
            "name": "Hitelkártya"
          },
          {
            "languageValue": "TR",
            "name": "Ödeme kartı"
          },
          {
            "languageValue": "PL",
            "name": "Karta płatnicza"
          },
          {
            "languageValue": "SK",
            "name": "Platobná karta"
          },
          {
            "languageValue": "EL",
            "name": "Πιστωτική κάρτα"
          },
          {
            "languageValue": "UK",
            "name": "Платіжна картка"
          },
          {
            "languageValue": "ES",
            "name": "Tarjeta de pago"
          },
          {
            "languageValue": "SL",
            "name": "Plačilna kartica "
          }
        ]
      },
      {
        "type": "PBL",
        "order": 4,
        "languageData": [
          {
            "languageValue": "ES",
            "name": "Pago de un banco"
          },
          {
            "languageValue": "TR",
            "name": "Banka ödemesi"
          },
          {
            "languageValue": "HR",
            "name": "Plaćanje putem banke"
          },
          {
            "languageValue": "CS",
            "name": "Platba z banky"
          },
          {
            "languageValue": "EN",
            "name": "Payment from the bank"
          },
          {
            "languageValue": "UK",
            "name": "Платіж з банку"
          },
          {
            "languageValue": "FR",
            "name": "Paiement depuis la banque."
          },
          {
            "languageValue": "HR",
            "name": "Plaćanje putem banke"
          },
          {
            "languageValue": "SK",
            "name": "Platba z banky"
          },
          {
            "languageValue": "EL",
            "name": "Πληρωμή από την τράπεζα"
          },
          {
            "languageValue": "TR",
            "name": "Banka ödemesi"
          },
          {
            "languageValue": "IT",
            "name": "Pagamento dalla banca"
          },
          {
            "languageValue": "DE",
            "name": "Zahlung von der Bank"
          },
          {
            "languageValue": "SL",
            "name": "Plačilo iz banke "
          },
          {
            "languageValue": "RO",
            "name": "Plata de la bancă"
          },
          {
            "languageValue": "HU",
            "name": "Fizetés a banktól"
          },
          {
            "languageValue": "PL",
            "name": "Płatność z banku"
          },
          {
            "languageValue": "SL",
            "name": "Plačilo iz banke "
          },
          {
            "languageValue": "EL",
            "name": "Πληρωμή από την τράπεζα"
          }
        ]
      },
      {
        "type": "Szybki Przelew",
        "order": 5,
        "languageData": [
          {
            "languageValue": "EL",
            "name": "Ανεξάρτητη Μεταγραφή"
          },
          {
            "languageValue": "DE",
            "name": "Eigenständige Übertragung"
          },
          {
            "languageValue": "SK",
            "name": "Samostatný prevod"
          },
          {
            "languageValue": "CS",
            "name": "Samostatný převod"
          },
          {
            "languageValue": "EN",
            "name": "Standalone transfer"
          },
          {
            "languageValue": "IT",
            "name": "Trasferimento autonomo"
          },
          {
            "languageValue": "ES",
            "name": "Transferencia autónoma"
          },
          {
            "languageValue": "UK",
            "name": "Незалежна передача - Nezalezhna peredacha"
          },
          {
            "languageValue": "TR",
            "name": "Bağımsız transfer"
          },
          {
            "languageValue": "PL",
            "name": "Samodzielny Przelew"
          },
          {
            "languageValue": "RO",
            "name": "Transfer autonom"
          },
          {
            "languageValue": "HU",
            "name": "Gyors Utalás"
          },
          {
            "languageValue": "FR",
            "name": "Transfert autonome"
          },
          {
            "languageValue": "SL",
            "name": "Neodvisno nakazilo "
          },
          {
            "languageValue": "HR",
            "name": "Neovisni prijenos"
          }
        ]
      },
      {
        "type": "Raty online",
        "order": 6,
        "languageData": [
          {
            "languageValue": "SK",
            "name": "Splátky online"
          },
          {
            "languageValue": "HU",
            "name": "Online részletfizetések"
          },
          {
            "languageValue": "EN",
            "name": "Installments online"
          },
          {
            "languageValue": "FR",
            "name": "Tranches en ligne"
          },
          {
            "languageValue": "DE",
            "name": "Online-Ratenzahlung"
          },
          {
            "languageValue": "SL",
            "name": "Spletni obroki "
          },
          {
            "languageValue": "RO",
            "name": "Rate online"
          },
          {
            "languageValue": "PL",
            "name": "Raty online"
          },
          {
            "languageValue": "EL",
            "name": "Ηλεκτρονικές δόσεις"
          },
          {
            "languageValue": "CS",
            "name": "On-line splátky"
          },
          {
            "languageValue": "UK",
            "name": "Онлайн-розстрочка"
          },
          {
            "languageValue": "ES",
            "name": "Cuotas en línea"
          },
          {
            "languageValue": "IT",
            "name": "Rate online"
          },
          {
            "languageValue": "TR",
            "name": "Çevrimiçi taksit"
          },
          {
            "languageValue": "HR",
            "name": "Online rate"
          }
        ]
      }
    ],
    "promotedPayways": [],
    "payways": [
      {
        "id": 1503,
        "name": "Kartowa płatność automatyczna PBC",
        "type": "Płatność automatyczna",
        "nameOnList": "Kartowa płatność automatyczna",
        "order": 1,
        "iconUrl": "https://platnosci.bm.pl/storage/app/media/grafika/1503.png",
        "recurring": true,
        "active": true,
        "languageData": [
          {
            "languageValue": "EN",
            "name": "Automatic card payment"
          },
          {
            "languageValue": "PL",
            "name": "Kartowa płatność automatyczna"
          },
          {
            "languageValue": "UK",
            "name": "Автоматичний платіж карткою"
          }
        ],
        "redirectToCards": false
      }
    ],
    "containsPisPayway": false,
    "transRemoteID": "AS7DDEKLZ4",
    "startAmount": 1.00,
    "startAmountCurrency": "PLN",
    "payAmount": 1.00,
    "payAmountCurrency": "PLN",
    "customerFee": 0.00,
    "transactionValidityTime": "2023-09-10T15:22:59.58304",
    "additionalData": {
      "orderId": "b26692ed6f98438989c13e31d59fd274",
      "taxCountry": ""
    },
    "continueTransactionData": {
      "amount": "1.00",
      "currency": "PLN",
      "merchantId": "101490",
      "taxCountry": "",
      "orderId": "b26692ed6f98438989c13e31d59fd274",
      "accountNumber": "",
      "customerPhone": "",
      "customerNumber": "",
      "invoiceNumber": ""
    },
    "acceptorID": 101021,
    "serviceID": 101490,
    "serviceType": "ECOMMERCE",
    "shortName": "Orange - DOP",
    "receiverNameForFront": "Orange - DOP",
    "serviceUrl": "https://www.orange.flex.pl/",
    "returnUrl": "https://www.orange.flex.pl/?ServiceID=101490&OrderID=b26692ed6f98438989c13e31d59fd274&Hash=14dc2f4dd0abf4b78e6d6e839e5239a5c0b5bca5dc87af3bbd1275e5266ec8fe",
    "regulations": [],
    "clientSource": "payways",
    "languageData": {
      "languagesAvailable": [
        "EN",
        "PL",
        "UK"
      ],
      "languageChosen": "PL"
    },
    "visibilityOptions": {
      "showFrontCountry": false,
      "showFrontEmail": false,
      "showFrontFees": false,
      "showFrontPartnerMarketing": "NONE",
      "showFrontRegulations": false,
      "showFrontTradeMarketing": "NONE",
      "showReturnToPaywaysButton": false,
      "showReturnToShopButton": false,
      "showFrontBasket": false
    },
    "sessionRemainingTime": 3599,
    "apiToken": "b2ffdc112f4b48eeae98907cf51db6a0",
    "recurringAction": "INIT_WITH_REFUND",
    "isCardRecurringRegulationToShow": false
  }
}
export const PAYMENT_REJECT = {}
export const PAYMENT_START_OTP_PAYKA = {
  "status": "SUCCESS",
  "data": {
    "productBasket": {
      "expandProductBasket": true,
      "productBasketItems": [
        {
          "amount": 89.99,
          "amountCurrency": "PLN",
          "productBasketItemParams": [
            {
              "title": "Numer dokumentu",
              "value": "F0091577320/007/23"
            },
            {
              "title": "Numer klienta",
              "value": "00009157732046"
            },
            {
              "title": "Kwota",
              "value": "89.99"
            }
          ]
        }
      ]
    },
    "paywayGroups": [
      {
        "type": "BLIK",
        "order": 1,
        "languageData": [
          {
            "languageValue": "HU",
            "name": "BLIK"
          },
          {
            "languageValue": "EN",
            "name": "BLIK"
          },
          {
            "languageValue": "PL",
            "name": "BLIK"
          },
          {
            "languageValue": "IT",
            "name": "BLIK"
          },
          {
            "languageValue": "CS",
            "name": "BLIK"
          },
          {
            "languageValue": "ES",
            "name": "BLIK"
          },
          {
            "languageValue": "FR",
            "name": "BLIK"
          },
          {
            "languageValue": "TR",
            "name": "BLIK"
          },
          {
            "languageValue": "RO",
            "name": "BLIK"
          },
          {
            "languageValue": "UK",
            "name": "BLIK"
          },
          {
            "languageValue": "HR",
            "name": "BLIK"
          },
          {
            "languageValue": "EL",
            "name": "BLIK"
          },
          {
            "languageValue": "SK",
            "name": "BLIK"
          },
          {
            "languageValue": "DE",
            "name": "BLIK"
          },
          {
            "languageValue": "SL",
            "name": "BLIK "
          }
        ]
      },
      {
        "type": "Płatność automatyczna",
        "order": 2,
        "languageData": [
          {
            "languageValue": "EL",
            "name": "Αυτόματη πληρωμή"
          },
          {
            "languageValue": "RO",
            "name": "Plata automată"
          },
          {
            "languageValue": "HR",
            "name": "Automatsko plaćanje"
          },
          {
            "languageValue": "DE",
            "name": "Automatische Zahlung"
          },
          {
            "languageValue": "HU",
            "name": "Automatikus fizetés"
          },
          {
            "languageValue": "TR",
            "name": "Otomatik ödeme"
          },
          {
            "languageValue": "SK",
            "name": "Automatická platba"
          },
          {
            "languageValue": "UK",
            "name": "Автоматичний платіж"
          },
          {
            "languageValue": "SL",
            "name": "Sanidejno plačilo "
          },
          {
            "languageValue": "CS",
            "name": "Automatická platba"
          },
          {
            "languageValue": "EN",
            "name": "Automatic payment"
          },
          {
            "languageValue": "ES",
            "name": "Pago automático"
          },
          {
            "languageValue": "FR",
            "name": "Paiement automatique"
          },
          {
            "languageValue": "IT",
            "name": "Pagamento automatico"
          },
          {
            "languageValue": "PL",
            "name": "Płatność automatyczna"
          }
        ]
      },
      {
        "type": "Karta płatnicza",
        "order": 3,
        "languageData": [
          {
            "languageValue": "ES",
            "name": "Tarjeta de pago"
          },
          {
            "languageValue": "SL",
            "name": "Plačilna kartica "
          },
          {
            "languageValue": "EL",
            "name": "Πιστωτική κάρτα"
          },
          {
            "languageValue": "IT",
            "name": "Carta di pagamento"
          },
          {
            "languageValue": "PL",
            "name": "Karta płatnicza"
          },
          {
            "languageValue": "CS",
            "name": "Platební karta"
          },
          {
            "languageValue": "HR",
            "name": "Kartica za plaćanje"
          },
          {
            "languageValue": "EN",
            "name": "Payment card"
          },
          {
            "languageValue": "SK",
            "name": "Platobná karta"
          },
          {
            "languageValue": "FR",
            "name": "Carte de paiement"
          },
          {
            "languageValue": "UK",
            "name": "Платіжна картка"
          },
          {
            "languageValue": "RO",
            "name": "Card de credit"
          },
          {
            "languageValue": "TR",
            "name": "Ödeme kartı"
          },
          {
            "languageValue": "HU",
            "name": "Hitelkártya"
          },
          {
            "languageValue": "DE",
            "name": "Zahlungskarte"
          }
        ]
      },
      {
        "type": "PBL",
        "order": 4,
        "languageData": [
          {
            "languageValue": "EN",
            "name": "Payment from the bank"
          },
          {
            "languageValue": "SL",
            "name": "Plačilo iz banke "
          },
          {
            "languageValue": "FR",
            "name": "Paiement depuis la banque."
          },
          {
            "languageValue": "DE",
            "name": "Zahlung von der Bank"
          },
          {
            "languageValue": "TR",
            "name": "Banka ödemesi"
          },
          {
            "languageValue": "IT",
            "name": "Pagamento dalla banca"
          },
          {
            "languageValue": "EL",
            "name": "Πληρωμή από την τράπεζα"
          },
          {
            "languageValue": "EL",
            "name": "Πληρωμή από την τράπεζα"
          },
          {
            "languageValue": "PL",
            "name": "Płatność z banku"
          },
          {
            "languageValue": "ES",
            "name": "Pago de un banco"
          },
          {
            "languageValue": "HR",
            "name": "Plaćanje putem banke"
          },
          {
            "languageValue": "SK",
            "name": "Platba z banky"
          },
          {
            "languageValue": "CS",
            "name": "Platba z banky"
          },
          {
            "languageValue": "RO",
            "name": "Plata de la bancă"
          },
          {
            "languageValue": "HU",
            "name": "Fizetés a banktól"
          },
          {
            "languageValue": "SL",
            "name": "Plačilo iz banke "
          },
          {
            "languageValue": "TR",
            "name": "Banka ödemesi"
          },
          {
            "languageValue": "UK",
            "name": "Платіж з банку"
          },
          {
            "languageValue": "HR",
            "name": "Plaćanje putem banke"
          }
        ],
        "footerShopModel": "PAYER"
      },
      {
        "type": "Szybki Przelew",
        "order": 5,
        "languageData": [
          {
            "languageValue": "HU",
            "name": "Gyors Utalás"
          },
          {
            "languageValue": "TR",
            "name": "Bağımsız transfer"
          },
          {
            "languageValue": "EL",
            "name": "Ανεξάρτητη Μεταγραφή"
          },
          {
            "languageValue": "RO",
            "name": "Transfer autonom"
          },
          {
            "languageValue": "DE",
            "name": "Eigenständige Übertragung"
          },
          {
            "languageValue": "CS",
            "name": "Samostatný převod"
          },
          {
            "languageValue": "PL",
            "name": "Samodzielny Przelew"
          },
          {
            "languageValue": "IT",
            "name": "Trasferimento autonomo"
          },
          {
            "languageValue": "SK",
            "name": "Samostatný prevod"
          },
          {
            "languageValue": "EN",
            "name": "Standalone transfer"
          },
          {
            "languageValue": "ES",
            "name": "Transferencia autónoma"
          },
          {
            "languageValue": "HR",
            "name": "Neovisni prijenos"
          },
          {
            "languageValue": "UK",
            "name": "Незалежна передача - Nezalezhna peredacha"
          },
          {
            "languageValue": "SL",
            "name": "Neodvisno nakazilo "
          },
          {
            "languageValue": "FR",
            "name": "Transfert autonome"
          }
        ]
      },
      {
        "type": "Raty online",
        "order": 6,
        "languageData": [
          {
            "languageValue": "HU",
            "name": "Online részletfizetések"
          },
          {
            "languageValue": "RO",
            "name": "Rate online"
          },
          {
            "languageValue": "EL",
            "name": "Ηλεκτρονικές δόσεις"
          },
          {
            "languageValue": "HR",
            "name": "Online rate"
          },
          {
            "languageValue": "FR",
            "name": "Tranches en ligne"
          },
          {
            "languageValue": "UK",
            "name": "Онлайн-розстрочка"
          },
          {
            "languageValue": "EN",
            "name": "Installments online"
          },
          {
            "languageValue": "TR",
            "name": "Çevrimiçi taksit"
          },
          {
            "languageValue": "SK",
            "name": "Splátky online"
          },
          {
            "languageValue": "CS",
            "name": "On-line splátky"
          },
          {
            "languageValue": "SL",
            "name": "Spletni obroki "
          },
          {
            "languageValue": "PL",
            "name": "Raty online"
          },
          {
            "languageValue": "IT",
            "name": "Rate online"
          },
          {
            "languageValue": "DE",
            "name": "Online-Ratenzahlung"
          },
          {
            "languageValue": "ES",
            "name": "Cuotas en línea"
          }
        ]
      }
    ],
    "promotedPayways": [],
    "lastPayway": {
      "id": 509,
      "name": "BLIK",
      "type": "BLIK",
      "nameOnList": "BLIK",
      "order": 8,
      "iconUrl": "https://images.autopay.eu/pomoc/grafika/509.png",
      "recurring": false,
      "active": true,
      "languageData": [
        {
          "languageValue": "PL",
          "name": "BLIK"
        }
      ],
      "redirectToCards": false
    },
    "payways": [
      {
        "id": 1500,
        "name": "Karty PBC",
        "type": "Karta płatnicza",
        "nameOnList": "Płatność kartą",
        "order": 3,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1500.gif",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płatność kartą"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1513,
        "name": "Apple Pay",
        "type": "Karta płatnicza",
        "nameOnList": "Apple Pay",
        "order": 4,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1513.png",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Apple Pay"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1512,
        "name": "Google Pay",
        "type": "Karta płatnicza",
        "nameOnList": "Google Pay",
        "order": 5,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1512.png",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Google Pay"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1511,
        "name": "Visa",
        "type": "Karta płatnicza",
        "nameOnList": "Visa",
        "order": 6,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1511.png",
        "recurring": false,
        "active": true,
        "additionalData": {
          "api_key": "A92M10WGYXL33W8YRN2E21fGMPqgYFqeU0t1kMgY45yX-BGw0",
          "acceptor_id": "100286"
        },
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Visa"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 509,
        "name": "BLIK",
        "type": "BLIK",
        "nameOnList": "BLIK",
        "order": 8,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/509.png",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "BLIK"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1808,
        "name": "ING PSD2 PIS",
        "type": "PBL",
        "nameOnList": "Płatność z ING",
        "order": 10,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1808.png",
        "recurring": false,
        "active": true,
        "typeForPsd2": "PIS",
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płatność z ING"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1800,
        "name": "mBank PSD2 PIS",
        "type": "PBL",
        "nameOnList": "Płatność z mBank",
        "order": 13,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1800.png",
        "recurring": false,
        "active": true,
        "typeForPsd2": "PIS",
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płatność z mBank"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1803,
        "name": "PKOBP PSD2 PIS",
        "type": "PBL",
        "nameOnList": "Płatność z PKOBP",
        "order": 16,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1803.png",
        "recurring": false,
        "active": true,
        "typeForPsd2": "PIS",
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płatność z PKOBP"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1806,
        "name": "Santander PSD2 PIS",
        "type": "PBL",
        "nameOnList": "Płatność z Santander",
        "order": 19,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1806.png",
        "recurring": false,
        "active": true,
        "typeForPsd2": "PIS",
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płatność z Santander"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1805,
        "name": "PekaoSA PSD2 PIS",
        "type": "PBL",
        "nameOnList": "Płatność z PekaoSA",
        "order": 22,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1805.png",
        "recurring": false,
        "active": true,
        "typeForPsd2": "PIS",
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płatność z PekaoSA"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1807,
        "name": "Millenium PSD2 PIS",
        "type": "PBL",
        "nameOnList": "Płatność z Millenium",
        "order": 25,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1807.png",
        "recurring": false,
        "active": true,
        "typeForPsd2": "PIS",
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płatność z Millenium"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1802,
        "name": "Alior PSD2 PIS",
        "type": "PBL",
        "nameOnList": "Płatność z Alior",
        "order": 28,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1802.png",
        "recurring": false,
        "active": true,
        "typeForPsd2": "PIS",
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płatność z Alior"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1809,
        "name": "Credit Agricole PSD2 PIS",
        "type": "PBL",
        "nameOnList": "Płatność z Credit Agricole",
        "order": 31,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1809.png",
        "recurring": false,
        "active": true,
        "typeForPsd2": "PIS",
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płatność z Credit Agricole"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1064,
        "name": "IGO PBL",
        "type": "PBL",
        "nameOnList": "Płacę z Inteligo",
        "order": 34,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1064.gif",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płacę z Inteligo"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1804,
        "name": "BNP Paribas PSD2 PIS",
        "type": "PBL",
        "nameOnList": "Płatność z BNP Paribas",
        "order": 37,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1804.png",
        "recurring": false,
        "active": true,
        "typeForPsd2": "PIS",
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płatność z BNP Paribas"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1801,
        "name": "Getin PSD2 PIS",
        "type": "PBL",
        "nameOnList": "Płatność z Getin",
        "order": 39,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1801.png",
        "recurring": false,
        "active": true,
        "typeForPsd2": "PIS",
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płatność z Getin"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 90,
        "name": "Citi Bank PBL",
        "type": "PBL",
        "nameOnList": "Płacę z Citi Handlowy",
        "order": 42,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/90.gif",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płacę z Citi Handlowy"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 517,
        "name": "Nest Bank PBL",
        "type": "PBL",
        "nameOnList": "NestPrzelew",
        "order": 45,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/517.png",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "NestPrzelew"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 131,
        "name": "Plus Bank PBL",
        "type": "PBL",
        "nameOnList": "Płacę z PLUS BANK",
        "order": 46,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/131.gif",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płacę z PLUS BANK"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1813,
        "name": "BOŚ PSD2 PIS",
        "type": "PBL",
        "nameOnList": "Płać z BOŚ Bank",
        "order": 47,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1813.png",
        "recurring": false,
        "active": true,
        "typeForPsd2": "PIS",
        "languageData": [],
        "redirectToCards": false
      },
      {
        "id": 98,
        "name": "PBS PBL",
        "type": "PBL",
        "nameOnList": "PBS Bank - przelew 24",
        "order": 48,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/98.gif",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "PBS Bank - przelew 24"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 117,
        "name": "Toyota PBL",
        "type": "PBL",
        "nameOnList": "Toyota Bank Pay Way",
        "order": 49,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/117.gif",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Toyota Bank Pay Way"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1050,
        "name": "NeoBANK PBL",
        "type": "PBL",
        "nameOnList": "Płacę z neoBANK",
        "order": 50,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1050.gif",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Płacę z neoBANK"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1510,
        "name": "Bank Spółdzielczy Lututów PBL",
        "type": "PBL",
        "nameOnList": "Bank Spółdzielczy Lututów PBL",
        "order": 54,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1510.gif",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Bank Spółdzielczy Lututów PBL"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1515,
        "name": "Bank Spółdzielczy w Toruniu PBL",
        "type": "PBL",
        "nameOnList": "Bank Spółdzielczy w Toruniu PBL",
        "order": 55,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1515.png",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Bank Spółdzielczy w Toruniu PBL"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1520,
        "name": "Bank Spółdzielczy Biała Rawska PBL",
        "type": "PBL",
        "nameOnList": "Bank Spółdzielczy Biała Rawska",
        "order": 58,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1520.png",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Bank Spółdzielczy Biała Rawska"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 701,
        "name": "Zapłać później z Payka",
        "type": "Raty online",
        "nameOnList": "Zapłać później z Payka",
        "order": 97,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/701.png",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Zapłać później z Payka"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1519,
        "name": "Krakowski Bank Spółdzielczy PBL",
        "type": "PBL",
        "nameOnList": "Krakowski Bank Spółdzielczy PBL",
        "order": 100,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1519.png",
        "recurring": false,
        "active": true,
        "languageData": [
          {
            "languageValue": "PL",
            "name": "Krakowski Bank Spółdzielczy PBL"
          }
        ],
        "redirectToCards": false
      },
      {
        "id": 1522,
        "name": "Bank Spółdzielczy Wieliczka PBL",
        "type": "PBL",
        "nameOnList": "Bank Spółdzielczy Wieliczka PBL",
        "order": 103,
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1522.png",
        "recurring": false,
        "active": true,
        "languageData": [],
        "redirectToCards": false
      },
      {
        "id": 1523,
        "name": "Visa Mobile",
        "type": "Karta płatnicza",
        "nameOnList": "Visa Mobile",
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1523.png",
        "recurring": false,
        "active": true,
        "languageData": [],
        "redirectToCards": false
      },
      {
        "id": 1811,
        "name": "Pocztowy PSD2 PIS",
        "type": "PBL",
        "nameOnList": "Płatność z Pocztowy",
        "iconUrl": "https://images.autopay.eu/pomoc/grafika/1811.png",
        "recurring": false,
        "active": true,
        "typeForPsd2": "PIS",
        "languageData": [],
        "redirectToCards": false
      }
    ],
    "containsPisPayway": true,
    "transRemoteID": "AI41AVXZT7",
    "startAmount": 89.99,
    "startAmountCurrency": "PLN",
    "payAmount": 91.49,
    "payAmountCurrency": "PLN",
    "customerFee": 1.50,
    "transactionValidityTime": "2023-08-04T12:42:16",
    "additionalData": {
      "orderId": "20230801124216",
      "customerEmail": "devnull@bm.pl"
    },
    "acceptorID": 100286,
    "serviceID": 100668,
    "serviceType": "MWF",
    "shortName": "Orange",
    "receiverNameForFront": "Orange",
    "serviceUrl": "https://www.orange.pl/",
    "returnUrl": "https://www.orange.pl/moj-orange/uslugi-stacjonarne?ServiceID=100668&OrderID=20230801124216&Hash=8b0ec2158d86d84bd756ed0aa9b7e0ee5d376fd64fa0fa9a6f66c48fa3a16311",
    "regulations": [
      {
        "regulationId": 140812,
        "url": "https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=418c93d388e9bbee04a7e8a430ab5c9a&Type=RECURRING&Language=PL&Hash=95d2f6f56bf530aba9dd41d491eb77f87fdf4f3b3d5a0f5343550deb0b7cc97f",
        "type": "RECURRING",
        "language": "PL",
        "inputLabel": "<ul><li>Zapoznałem się i akceptuję <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=418c93d388e9bbee04a7e8a430ab5c9a&Type=RECURRING&Language=PL&Hash=95d2f6f56bf530aba9dd41d491eb77f87fdf4f3b3d5a0f5343550deb0b7cc97f>Regulamin świadczenia usług płatniczych</a> oraz <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">Politykę prywatności</a></li><li>\r\n\r\nChcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem, że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane na moje żądanie do chwili odstąpienia od umowy</li>",
        "checkedAndHidden": false
      },
      {
        "regulationId": 152375,
        "url": "https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=efab611985630cce4cafebe6ce0dd809&Type=DEFAULT&Language=PL&Hash=bcab6e6052723f8834365ce9a9d1996d891aea6b62cc960a3ef272160a35c987",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=efab611985630cce4cafebe6ce0dd809&Type=DEFAULT&Language=PL&Hash=bcab6e6052723f8834365ce9a9d1996d891aea6b62cc960a3ef272160a35c987>regulamin</a> i <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności</a> Blue Media S.A. oraz zgodę na natychmiastową realizację usługi płatniczej.</li><li>\r\n<span class=\"___more ___hidden\">Zgadzam się aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "checkedAndHidden": true
      },
      {
        "regulationId": 470,
        "url": "https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=f89ddaf26c9f93eec6eb4a7b1faa8cce&Type=DEFAULT&Language=PL&GatewayID=1800&Hash=c4dac602f9b4d2547105ae5670eb3ba544d7eb8eeccc5241109c78a758be37c1",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=f89ddaf26c9f93eec6eb4a7b1faa8cce&Type=DEFAULT&Language=PL&GatewayID=1800&Hash=c4dac602f9b4d2547105ae5670eb3ba544d7eb8eeccc5241109c78a758be37c1>Regulamin</a> i <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności</a> Blue Media S.A., zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Blue Media S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz zgodę na natychmiastową realizację usługi płatniczej.</li><li>\n<span class=\"___more ___hidden\">Zgadzam się aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "gatewayId": 1800,
        "checkedAndHidden": true
      },
      {
        "regulationId": 470,
        "url": "https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=c305b44d1aaa4bcd23e725ce9d28e186&Type=DEFAULT&Language=PL&GatewayID=1801&Hash=8eb5c03b40ea75f84b7610e1227960570ff39148d0b2990d28c6b5f9e1dd5c10",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=c305b44d1aaa4bcd23e725ce9d28e186&Type=DEFAULT&Language=PL&GatewayID=1801&Hash=8eb5c03b40ea75f84b7610e1227960570ff39148d0b2990d28c6b5f9e1dd5c10>Regulamin</a> i <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności</a> Blue Media S.A., zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Blue Media S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz zgodę na natychmiastową realizację usługi płatniczej.</li><li>\n<span class=\"___more ___hidden\">Zgadzam się aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "gatewayId": 1801,
        "checkedAndHidden": true
      },
      {
        "regulationId": 470,
        "url": "https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=98cc193c9d174e1fa1f69dc3c3e4bcf7&Type=DEFAULT&Language=PL&GatewayID=1802&Hash=b00336a1d4c3cf417b74ddb8d11d52c5d403074ea4455782e069117e695e80be",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=98cc193c9d174e1fa1f69dc3c3e4bcf7&Type=DEFAULT&Language=PL&GatewayID=1802&Hash=b00336a1d4c3cf417b74ddb8d11d52c5d403074ea4455782e069117e695e80be>Regulamin</a> i <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności</a> Blue Media S.A., zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Blue Media S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz zgodę na natychmiastową realizację usługi płatniczej.</li><li>\n<span class=\"___more ___hidden\">Zgadzam się aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "gatewayId": 1802,
        "checkedAndHidden": true
      },
      {
        "regulationId": 470,
        "url": "https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=47c78ffd64da2ad933d77df4cc1cbd67&Type=DEFAULT&Language=PL&GatewayID=1803&Hash=ff3cf41e7fdb1d428e19f4e98da7dded5942d3a4053996f6d1d36d46cdf0824a",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=47c78ffd64da2ad933d77df4cc1cbd67&Type=DEFAULT&Language=PL&GatewayID=1803&Hash=ff3cf41e7fdb1d428e19f4e98da7dded5942d3a4053996f6d1d36d46cdf0824a>Regulamin</a> i <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności</a> Blue Media S.A., zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Blue Media S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz zgodę na natychmiastową realizację usługi płatniczej.</li><li>\n<span class=\"___more ___hidden\">Zgadzam się aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "gatewayId": 1803,
        "checkedAndHidden": true
      },
      {
        "regulationId": 470,
        "url": "https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=0b9fcfeb7d53f11c42514445b58011e4&Type=DEFAULT&Language=PL&GatewayID=1804&Hash=08668cf718d56b9916c77b71883914ec4249a40aea67879625963332d2dc0277",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=0b9fcfeb7d53f11c42514445b58011e4&Type=DEFAULT&Language=PL&GatewayID=1804&Hash=08668cf718d56b9916c77b71883914ec4249a40aea67879625963332d2dc0277>Regulamin</a> i <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności</a> Blue Media S.A., zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Blue Media S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz zgodę na natychmiastową realizację usługi płatniczej.</li><li>\n<span class=\"___more ___hidden\">Zgadzam się aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "gatewayId": 1804,
        "checkedAndHidden": true
      },
      {
        "regulationId": 470,
        "url": "https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=e5fbd8e738d53c0a7b3cdf035d6adbd3&Type=DEFAULT&Language=PL&GatewayID=1805&Hash=db947a5b49c3f75c4738f654165e2788f63dacfc0f6b233c7be5ff449ae27094",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=e5fbd8e738d53c0a7b3cdf035d6adbd3&Type=DEFAULT&Language=PL&GatewayID=1805&Hash=db947a5b49c3f75c4738f654165e2788f63dacfc0f6b233c7be5ff449ae27094>Regulamin</a> i <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności</a> Blue Media S.A., zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Blue Media S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz zgodę na natychmiastową realizację usługi płatniczej.</li><li>\n<span class=\"___more ___hidden\">Zgadzam się aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "gatewayId": 1805,
        "checkedAndHidden": true
      },
      {
        "regulationId": 470,
        "url": "https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=0234cb3b2c3faa7d629774408d3b5db0&Type=DEFAULT&Language=PL&GatewayID=1806&Hash=5f905445e8fab82fc0c2b9995b805b6c056cb98eb57541947b58a7754379f8b9",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=0234cb3b2c3faa7d629774408d3b5db0&Type=DEFAULT&Language=PL&GatewayID=1806&Hash=5f905445e8fab82fc0c2b9995b805b6c056cb98eb57541947b58a7754379f8b9>Regulamin</a> i <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności</a> Blue Media S.A., zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Blue Media S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz zgodę na natychmiastową realizację usługi płatniczej.</li><li>\n<span class=\"___more ___hidden\">Zgadzam się aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "gatewayId": 1806,
        "checkedAndHidden": true
      },
      {
        "regulationId": 470,
        "url": "https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=e21a7404eda1b4316a6ab4793a7260b9&Type=DEFAULT&Language=PL&GatewayID=1807&Hash=a9926852c21b1988c07834a56b29de207a4d67282c8f2a9b99e2b4b8909b08f3",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=e21a7404eda1b4316a6ab4793a7260b9&Type=DEFAULT&Language=PL&GatewayID=1807&Hash=a9926852c21b1988c07834a56b29de207a4d67282c8f2a9b99e2b4b8909b08f3>Regulamin</a> i <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności</a> Blue Media S.A., zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Blue Media S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz zgodę na natychmiastową realizację usługi płatniczej.</li><li>\n<span class=\"___more ___hidden\">Zgadzam się aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "gatewayId": 1807,
        "checkedAndHidden": true
      },
      {
        "regulationId": 470,
        "url": "https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=a615bf1bfdd574dfd82ea3bdd8411fc2&Type=DEFAULT&Language=PL&GatewayID=1808&Hash=61b99e4bb0347b6975333a09b08b297a1a0c51ec64f754d4b23ca10cba3dc894",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=a615bf1bfdd574dfd82ea3bdd8411fc2&Type=DEFAULT&Language=PL&GatewayID=1808&Hash=61b99e4bb0347b6975333a09b08b297a1a0c51ec64f754d4b23ca10cba3dc894>Regulamin</a> i <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności</a> Blue Media S.A., zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Blue Media S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz zgodę na natychmiastową realizację usługi płatniczej.</li><li>\n<span class=\"___more ___hidden\">Zgadzam się aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "gatewayId": 1808,
        "checkedAndHidden": true
      },
      {
        "regulationId": 470,
        "url": "https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=4aa9170992843038a371b8a896f027a5&Type=DEFAULT&Language=PL&GatewayID=1809&Hash=0b189db753240a329e71e1c64b6a4cf0743c6375740190d9d23d67eca85d4395",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=4aa9170992843038a371b8a896f027a5&Type=DEFAULT&Language=PL&GatewayID=1809&Hash=0b189db753240a329e71e1c64b6a4cf0743c6375740190d9d23d67eca85d4395>Regulamin</a> i <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności</a> Blue Media S.A., zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Blue Media S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz zgodę na natychmiastową realizację usługi płatniczej.</li><li>\n<span class=\"___more ___hidden\">Zgadzam się aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "gatewayId": 1809,
        "checkedAndHidden": true
      },
      {
        "regulationId": 470,
        "url": "https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=5c4ebd4c60c5acb307fa0c8bbf8c7983&Type=DEFAULT&Language=PL&GatewayID=1811&Hash=0de69b4f4293ed50bbe64c40e0d1dbbdb86e6e928f250804a731b0b23eadd847",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=5c4ebd4c60c5acb307fa0c8bbf8c7983&Type=DEFAULT&Language=PL&GatewayID=1811&Hash=0de69b4f4293ed50bbe64c40e0d1dbbdb86e6e928f250804a731b0b23eadd847>Regulamin</a> i <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności</a> Blue Media S.A., zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Blue Media S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz zgodę na natychmiastową realizację usługi płatniczej.</li><li>\n<span class=\"___more ___hidden\">Zgadzam się aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "gatewayId": 1811,
        "checkedAndHidden": true
      },
      {
        "regulationId": 470,
        "url": "https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=08b6d6d6a170da8495b2ba606d44d940&Type=DEFAULT&Language=PL&GatewayID=1813&Hash=b9fdcbbb254b89f01247805723d3abf2c83aaef9083a9336b2c93b8c071c4e6c",
        "type": "DEFAULT",
        "language": "PL",
        "inputLabel": "<ul><li>Jeśli chcesz wykonać płatność, zaakceptuj <a id=\"regulations_pdf\" target=\"_blank\" href=https://pay.bm.pl/webapi/regulation?ServiceID=100668&MessageID=08b6d6d6a170da8495b2ba606d44d940&Type=DEFAULT&Language=PL&GatewayID=1813&Hash=b9fdcbbb254b89f01247805723d3abf2c83aaef9083a9336b2c93b8c071c4e6c>Regulamin</a> i <a class=\"privacy-policy\" href=\"https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenty/polityka-prywatnosci.pdf\" target=\"_blank\">politykę prywatności</a> Blue Media S.A., zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Blue Media S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz zgodę na natychmiastową realizację usługi płatniczej.</li><li>\n<span class=\"___more ___hidden\">Zgadzam się aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy.</span></li></ul>",
        "gatewayId": 1813,
        "checkedAndHidden": true
      }
    ],
    "clientSource": "payways",
    "languageData": {
      "languagesAvailable": [
        "PL"
      ],
      "languageChosen": "PL"
    },
    "visibilityOptions": {
      "showFrontCountry": false,
      "showFrontEmail": true,
      "showFrontFees": true,
      "showFrontPartnerMarketing": "OPTIONAL",
      "showFrontRegulations": true,
      "showFrontTradeMarketing": "OPTIONAL",
      "showReturnToPaywaysButton": false,
      "showReturnToShopButton": true,
      "showFrontBasket": true
    },
    "sessionRemainingTime": 265,
    "isCardRecurringRegulationToShow": true
  }
}
